/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */
/**
 * Actions of Authentication
 * @type {string}
 */
export const LOGIN_USER_PENDING = 'LOGIN_USER_PENDING';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';

export const FORGOT_PASSWORD_PENDING = 'FORGOT_PASSWORD_PENDING';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';

export const RESET_PASSWORD_PENDING = 'RESET_PASSWORD_PENDING';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';

export const SIGN_UP_USER_PENDING = 'SIGN_UP_USER_PENDING';
export const SIGN_UP_USER_SUCCESS = 'SIGN_UP_USER_SUCCESS';
export const SIGN_UP_USER_ERROR = 'SIGN_UP_USER_ERROR';

export const LOGIN_FORM_RESET = 'LOGIN_FORM_RESET';
export const SIGNUP_FORM_RESET = 'SIGNUP_FORM_RESET';
export const FORGOT_PASSWORD_FORM_RESET = 'FORGOT_PASSWORD_FORM_RESET';
export const RESET_PASSWORD_FORM_RESET = 'RESET_PASSWORD_FORM_RESET';

export const HOST_REQUEST_PENDING = "HOST_REQUEST_PENDING";
export const HOST_REQUEST_SUCCESS = "HOST_REQUEST_SUCCESS";
export const HOST_REQUEST_FAILED = "HOST_REQUEST_FAILED";

export const TOKEN_REFRESH_PENDING = "TOKEN_REFRESH_PENDING";
export const TOKEN_REFRESH_SUCCESS = "TOKEN_REFRESH_SUCCESS";
export const TOKEN_REFRESH_FAILED = "TOKEN_REFRESH_FAILED";

export const LOGOUT_USER_PENDING = 'LOGOUT_USER_PENDING';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
export const LOGOUT_USER_FAILED = 'LOGOUT_USER_FAILED';

export const SET_ACCESS_EXPIRY = 'SET_ACCESS_EXPIRY'

export const INITIALIZE_FORM = 'INITIALIZE_FORM';

export const AUTH_REDUCER_RESET = 'AUTH_REDUCER_RESET';

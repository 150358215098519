/**
 * Created by jyothi on 22/8/17.
 */
import {
    APPS_PENDING, APPS, APPS_FAILED,
    RESET_APPS
} from './actionTypes';
import { getApps } from './api';

/**
 *
 * @returns {function(*, *)}
 */
export function loadApps() {
    return (dispatch, getState) => {
        return dispatch({
            types: [
                APPS_PENDING,
                APPS,
                APPS_FAILED
            ],
            payload: {
                promise: getApps(getState().auth)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {

            }
        });
    };
}

/**
 *
 * @returns {function(*, *)}
 */
export function resetApps() {
    return (dispatch, getState) => {
        return dispatch({
            type: RESET_APPS,
            payload: null
        });
    };
}
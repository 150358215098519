/**
 * Created by jyothi on 1/8/17.
 */
import {
    REPORTS_FAILED,
    REPORTS_SUCCESS,
    REPORTS_PENDING,
    ADD_REPORT_FAILED,
    ADD_REPORT_SUCCESS,
    ADD_REPORT_PENDING,
    PREPROCESS_LIST,
    PREPROCESS,
    UPDATE_PREPROCESS_CONFIG,
    PREPROCESS_REPORT_DELETE_PENDING,
    PREPROCESS_REPORT_DELETE_FAILED,
    PREPROCESS_REPORT_DELETE,
    PREPROCESS_PENDING,
    PREPROCESS_FAILED
} from './actionTypes';
import {RESET_ALL} from '../../../../../../../constants/actionTypes';
import {formattedProcessedData} from "../../../../../../../utils";

const INITIAL_STATE ={
    preprocess_list: [],
    preprocess: {},
    preprocess_config: {},
    reports: [],
    ...REPORTS_INITIAL_FLAG_STATE,
    ...ADD_REPORT_INITIAL_FLAG_STATE
};

const REPORTS_INITIAL_FLAG_STATE = {
    reports_pending: false,
    reports_failed: false
};

const ADD_REPORT_INITIAL_FLAG_STATE = {
    add_report_pending: false,
    add_report_failed: false,
    add_report_success: false
};

function reportsReducer(state = INITIAL_STATE, action = {}) {
    switch (action.type) {

        case REPORTS_FAILED:
            return{
                ...state,
                ...REPORTS_INITIAL_FLAG_STATE,
                reports_failed: true
            };

        case REPORTS_SUCCESS:
            return{
                ...state,
                ...REPORTS_INITIAL_FLAG_STATE,
                reports: action.payload
            };

        case REPORTS_PENDING:
            return{
                ...state,
                ...REPORTS_INITIAL_FLAG_STATE,
                reports_pending: true,
                preprocess_success: false
            };

        case ADD_REPORT_PENDING:
            return{
                ...state,
                ...ADD_REPORT_INITIAL_FLAG_STATE,
                add_report_pending: true,
                preprocess_success: false
            };

        case ADD_REPORT_SUCCESS:
            return{
                ...state,
                ...ADD_REPORT_INITIAL_FLAG_STATE,
                add_report_success: true,
                preprocess_success: false
            };

        case ADD_REPORT_FAILED:
            return{
                ...state,
                ...ADD_REPORT_INITIAL_FLAG_STATE,
                add_report_failed: true,
                preprocess_success: false
            };

        case PREPROCESS_LIST:
            const preprocess_list = Array.isArray(action.payload) ? action.payload.reverse() : [];
            return {
                ...state,
                preprocess_list,
                preprocess_success: false
            };

        case PREPROCESS:
            return {
                ...state,
                preprocess: formattedProcessedData(action.payload),
                preprocess_success: true
            };

        case PREPROCESS_PENDING:
        case PREPROCESS_FAILED:
            return {
                ...state,
                preprocess_success: false,
            };

        case UPDATE_PREPROCESS_CONFIG:
            return {
                ...state,
                preprocess_config: action.payload,
                preprocess_success: false
            };

        case RESET_ALL:
            return INITIAL_STATE;

        default:
            return state;
    }
}

export default reportsReducer;
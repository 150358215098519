/**
 * Created by jyothi on 22/8/17.
 */
import { Component } from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import { AUTH_ERROR_ENUM } from '../../../../constants/endPoints';
import PropTypes from 'prop-types';

function mapStateToProps(state) {
    return {
        session: state.auth,
        router: state.routing,
        state: state //for listening all state changes
    };
}

class RequireAuth extends Component {

    componentWillMount() {
        const { session : { host, token , user={}} } = this.props;
        const email = user.email; //fix for un authenticated access

        // If this page is restricted, go to loginPage first.
        // (But pass on this page's path in order to redirect back upon login)
        if (!token) {
            const path = this.props.location.pathname;
            this.context.router.push(`/login?redirect=${path}`);
        }else{
            ReactGA.set({ userId: email });
        }
    }

    componentWillReceiveProps(nextProps){
        const { session: {token, host_pending } } = nextProps;
        if(!token){ //checks authentication and backend server host every time
            const { router } = this.context;
            const path = this.props.location.pathname;
            const { MISSING_HOST, MISSING_TOKEN } = AUTH_ERROR_ENUM;
            const reason = !token ? MISSING_TOKEN.query : MISSING_HOST.query;
            router.push(`/login?redirect=${path}&reason=${reason}`); //reason 2 indicates host missing
        }
    }

    render() {
        const { session } = this.props;
        if (session.token) {
            return this.props.children;
        }
        return null;
    }
}

RequireAuth.propTypes = {
    children: PropTypes.object,
    location: PropTypes.object,
};

RequireAuth.contextTypes = {
    router: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(RequireAuth);
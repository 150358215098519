/**
 * Created by jyothi on 19/8/17.
 */
export default {
    "header": {
        "title": "FIRST TIME USER EXPERIENCE REPORT",
        "dates": "July 1st - July 15th",
        "app": "Demo App",
        "enableDateFilter": false,
        "enableGroupFilter": false,
        "enableVersionFilter": false,
        "isSingleDate": false,
        "validDates": [
            "minDate",
            "maxDate"
        ],
        "groupFilters": [
            {
                "key": "Day 0",
                "value": "61a6fe2b-fc32-429e-9622-b83f22ee2822//reportId"
            },
            {
                "key": "All",
                "value": "7ad58019-2846-4f41-a0a6-4c2a18eae1d9//reportId"
            }
        ],
        "versionFilters": [
            "1.0.0",
            "1.2.3",
            "1.2.4"
        ]
    },
    timeSpentInActivity: {
        name: "Time spent in activity",
        xKey: "slot",
        yKey: "users",
        xLabel: "Time Spent in Seconds",
        yLabel: "Users",
        data: {
            "Activity1": {
                count: 2345,
                data: [
                    { slot: 1, users: 1234 },
                    { slot: 2, users: 534 },
                    { slot: 3, users: 344 },
                    { slot: 4, users: 234 },
                    { slot: 5, users: 134 },
                ]
            },
            "Activity2": {
                count: 2345,
                data: [
                    { slot: 1, users: 1234 },
                    { slot: 2, users: 534 },
                    { slot: 3, users: 344 },
                    { slot: 4, users: 234 },
                    { slot: 5, users: 134 },
                ]
            }
        }
    },
    "eventsVsRetention":{
        "xKey": "event_count",
        "yKey": "retention",
        "zKey": "users",
        xLabel: "Atleast Event Count",
        yLabel: "Day 3 Retention",
        zLabel: "Users",
        scatterLabel: "Users",
        data: {
            Event1: [
                {event_count: 1, retention: 10, users: 1000},
                {event_count: 2, retention: 20, users: 2000},
                {event_count: 3, retention: 30, users: 3000},
                {event_count: 4, retention: 40, users: 4000},
                {event_count: 5, retention: 50, users: 5000}
            ],
            Event2: [
                {event_count: 1, retention: 10, users: 1000},
                {event_count: 2, retention: 20, users: 2000},
                {event_count: 3, retention: 30, users: 3000},
                {event_count: 4, retention: 40, users: 4000},
                {event_count: 5, retention: 50, users: 5000}
            ],
            Event3: [
                {event_count: 1, retention: 10, users: 1000},
                {event_count: 2, retention: 20, users: 2000},
                {event_count: 3, retention: 30, users: 3000},
                {event_count: 4, retention: 40, users: 4000},
                {event_count: 5, retention: 50, users: 5000}
            ]
        }
    },
    "attributesVsUninstalls": {
        "xKey": "value",
        "yKey": "uninstalls",
        "zKey": "users",
        "xLabel": "Attribute Value",
        "yLabel": "Uninstalls %",
        "zLabel": "Users",
        "scatterLabel": "Users",
        "data": {
            "Attribute1": [
                {
                    "value": 1,
                    "uninstalls": 10,
                    "users": 1000
                },
                {
                    "value": 2,
                    "uninstalls": 20,
                    "users": 2000
                },
                {
                    "value": 3,
                    "uninstalls": 30,
                    "users": 3000
                },
                {
                    "value": 4,
                    "uninstalls": 40,
                    "users": 4000
                },
                {
                    "value": 5,
                    "uninstalls": 50,
                    "users": 5000
                }
            ],
            "Attribute2": [
                {
                    "value": 1,
                    "uninstalls": 10,
                    "users": 1000
                },
                {
                    "value": 2,
                    "uninstalls": 20,
                    "users": 2000
                },
                {
                    "value": 3,
                    "uninstalls": 30,
                    "users": 3000
                },
                {
                    "value": 4,
                    "uninstalls": 40,
                    "users": 4000
                },
                {
                    "value": 5,
                    "uninstalls": 50,
                    "users": 5000
                }
            ],
            "Attribute3": [
                {
                    "value": 1,
                    "uninstalls": 10,
                    "users": 1000
                },
                {
                    "value": 2,
                    "uninstalls": 20,
                    "users": 2000
                },
                {
                    "value": 3,
                    "uninstalls": 30,
                    "users": 3000
                },
                {
                    "value": 4,
                    "uninstalls": 40,
                    "users": 4000
                },
                {
                    "value": 5,
                    "uninstalls": 50,
                    "users": 5000
                }
            ]
        }
    },
    "stats": {
        "data": [
            {
                "key": "users",
                "name": "Total Users",
                "value": 123303
            },
            {
                "key": "sessions",
                "name": "Sessions",
                "value": 420274
            },
            {
                "key": "uninstalls",
                "name": "Uninstalls",
                "value": 35153
            },
            {
                "key": "day0uninstalls",
                "name": "Day 0 Uninstalls",
                "value": 25153
            }
        ]
    },
    "distributions": {
        "isForUninstall": true,
        "data": [
            {
                "name": "Language",
                "data": [
                    {
                        "name": "Malayalam",
                        "newinstalls": 25580,
                        "day0uninstalls": 3610
                    },
                    {
                        "name": "Telugu",
                        "newinstalls": 24070,
                        "day0uninstalls": 4970
                    },
                    {
                        "name": "Tamil",
                        "newinstalls": 23860,
                        "day0uninstalls": 5345
                    },
                    {
                        "name": "English",
                        "newinstalls": 3710,
                        "day0uninstalls": 1115
                    },
                    {
                        "name": "Hindi",
                        "newinstalls": 2740,
                        "day0uninstalls": 710
                    },
                    {
                        "name": "Urdu",
                        "newinstalls": 925,
                        "day0uninstalls": 245
                    },
                    {
                        "name": "Kannada",
                        "newinstalls": 575,
                        "day0uninstalls": 140
                    },
                    {
                        "name": "Gujarathi",
                        "newinstalls": 285,
                        "day0uninstalls": 60
                    },
                    {
                        "name": "Bengali",
                        "newinstalls": 465,
                        "day0uninstalls": 100
                    },
                    {
                        "name": "Marati",
                        "newinstalls": 180,
                        "day0uninstalls": 45
                    },
                    {
                        "name": "Punjabi",
                        "newinstalls": 5,
                        "day0uninstalls": 0
                    }
                ]
            },
            {
                "name": "Location",
                "data": [
                    {
                        "name": "IN",
                        "newinstalls": 55615,
                        "day0uninstalls": 10800
                    },
                    {
                        "name": "ID",
                        "newinstalls": 24190,
                        "day0uninstalls": 4980
                    },
                    {
                        "name": "US",
                        "newinstalls": 1505,
                        "day0uninstalls": 455
                    },
                    {
                        "name": "UK",
                        "newinstalls": 975,
                        "day0uninstalls": 345
                    },
                    {
                        "name": "WORLD",
                        "newinstalls": 230,
                        "day0uninstalls": 55
                    },
                    {
                        "name": "AU",
                        "newinstalls": 15,
                        "day0uninstalls": 5
                    },
                    {
                        "name": "VN",
                        "newinstalls": 5,
                        "day0uninstalls": 5
                    },
                    {
                        "_id": "MY",
                        "newinstalls": 5,
                        "day0uninstalls": 5
                    },
                    {
                        "_id": "CA",
                        "newinstalls": 5,
                        "day0uninstalls": 5
                    },
                    {
                        "_id": "SG",
                        "newinstalls": 5,
                        "day0uninstalls": 0
                    },
                    {
                        "_id": "PK",
                        "newinstalls": 5,
                        "day0uninstalls": 5
                    },
                    {
                        "_id": "PH",
                        "newinstalls": 5,
                        "day0uninstalls": 5
                    }
                ]
            },
            {
                "name": "Acquisition",
                "data": [
                    {
                        "name": "Inorganic",
                        "newinstalls": 80315,
                        "day0uninstalls": 5270
                    },
                    {
                        "name": "Organic",
                        "newinstalls": 3620,
                        "day0uninstalls": 1075
                    }
                ]
            },
            {
                "name": "OS Version",
                "data": [
                    {
                        "name": "6.0",
                        "newinstalls": 20090,
                        "day0uninstalls": 6210
                    },
                    {
                        "name": "6.0.1",
                        "newinstalls": 16545,
                        "day0uninstalls": 5790
                    },
                    {
                        "name": "5.5",
                        "newinstalls": 13680,
                        "day0uninstalls": 4540
                    },
                    {
                        "name": "5.1.1",
                        "newinstalls": 12405,
                        "day0uninstalls": 4410
                    },
                    {
                        "name": "4.4.2",
                        "newinstalls": 8950,
                        "day0uninstalls": 2335
                    },
                    {
                        "name": "4.4.4",
                        "newinstalls": 3545,
                        "day0uninstalls": 1240
                    },
                    {
                        "name": "7.0",
                        "newinstalls": 2590,
                        "day0uninstalls": 810
                    },
                    {
                        "name": "5.0.2",
                        "newinstalls": 2415,
                        "day0uninstalls": 795
                    },
                    {
                        "name": "5.0",
                        "newinstalls": 1605,
                        "day0uninstalls": 530
                    },
                    {
                        "name": "4.2.2",
                        "newinstalls": 1050,
                        "day0uninstalls": 255
                    },
                    {
                        "name": "4.3",
                        "newinstalls": 460,
                        "day0uninstalls": 180
                    },
                    {
                        "name": "5.0.1",
                        "newinstalls": 375,
                        "day0uninstalls": 100
                    },
                    {
                        "name": "4.2.1",
                        "newinstalls": 75,
                        "day0uninstalls": 10
                    },
                    {
                        "name": "7.1.1",
                        "newinstalls": 75,
                        "day0uninstalls": 30
                    },
                    {
                        "name": "4.4.3",
                        "newinstalls": 60,
                        "day0uninstalls": 20
                    },
                    {
                        "name": "7.1.2",
                        "newinstalls": 20,
                        "day0uninstalls": 15
                    },
                    {
                        "name": "6.1",
                        "newinstalls": 5,
                        "day0uninstalls": 5
                    }
                ]
            },
            {
                "name": "Network Type",
                "data": [
                    {
                        "name": "4G",
                        "newinstalls": 36100,
                        "day0uninstalls": 7460
                    },
                    {
                        "name": "3G",
                        "newinstalls": 27050,
                        "day0uninstalls": 5125
                    },
                    {
                        "name": "WIFI",
                        "newinstalls": 17035,
                        "day0uninstalls": 3085
                    },
                    {
                        "name": "2G",
                        "newinstalls": 10110,
                        "day0uninstalls": 755
                    },
                    {
                        "name": "Unknown",
                        "newinstalls": 37210,
                        "day0uninstalls": 1980
                    }
                ]
            }
        ]
    },
    "day0metrics": {
        "data": [
            {
                "metric": "SHARE_ITEM",
                "total": 20219,
                "active": 77163,
                "activeTotal": 16509,
                "inactive": 15299,
                "inactiveTotal": 3710,
                "distribution": [
                    {
                        "_id": 0,
                        "total_user_count": 23712,
                        "day0_uninstall_count": 9034
                    },
                    {
                        "_id": 1,
                        "total_user_count": 6134,
                        "day0_uninstall_count": 1194
                    },
                    {
                        "_id": 2,
                        "total_user_count": 3613,
                        "day0_uninstall_count": 703
                    },
                    {
                        "_id": 3,
                        "total_user_count": 2398,
                        "day0_uninstall_count": 447
                    },
                    {
                        "_id": 4,
                        "total_user_count": 1744,
                        "day0_uninstall_count": 315
                    },
                    {
                        "_id": 5,
                        "total_user_count": 1239,
                        "day0_uninstall_count": 214
                    },
                    {
                        "_id": 6,
                        "total_user_count": 968,
                        "day0_uninstall_count": 175
                    },
                    {
                        "_id": 7,
                        "total_user_count": 772,
                        "day0_uninstall_count": 138
                    },
                    {
                        "_id": 8,
                        "total_user_count": 562,
                        "day0_uninstall_count": 85
                    },
                    {
                        "_id": 9,
                        "total_user_count": 494,
                        "day0_uninstall_count": 89
                    },
                    {
                        "_id": 10,
                        "total_user_count": 353,
                        "day0_uninstall_count": 66
                    },
                    {
                        "_id": 11,
                        "total_user_count": 303,
                        "day0_uninstall_count": 60
                    },
                    {
                        "_id": 12,
                        "total_user_count": 284,
                        "day0_uninstall_count": 31
                    },
                    {
                        "_id": 13,
                        "total_user_count": 192,
                        "day0_uninstall_count": 35
                    },
                    {
                        "_id": 14,
                        "total_user_count": 146,
                        "day0_uninstall_count": 16
                    },
                    {
                        "_id": 15,
                        "total_user_count": 133,
                        "day0_uninstall_count": 22
                    },
                    {
                        "_id": 16,
                        "total_user_count": 122,
                        "day0_uninstall_count": 18
                    },
                    {
                        "_id": 17,
                        "total_user_count": 95,
                        "day0_uninstall_count": 16
                    },
                    {
                        "_id": 18,
                        "total_user_count": 77,
                        "day0_uninstall_count": 12
                    },
                    {
                        "_id": 19,
                        "total_user_count": 55,
                        "day0_uninstall_count": 6
                    },
                    {
                        "_id": 20,
                        "total_user_count": 47,
                        "day0_uninstall_count": 6
                    },
                    {
                        "_id": 21,
                        "total_user_count": 60,
                        "day0_uninstall_count": 9
                    },
                    {
                        "_id": 22,
                        "total_user_count": 57,
                        "day0_uninstall_count": 9
                    },
                    {
                        "_id": 23,
                        "total_user_count": 45,
                        "day0_uninstall_count": 12
                    },
                    {
                        "_id": 24,
                        "total_user_count": 40,
                        "day0_uninstall_count": 2
                    },
                    {
                        "_id": 25,
                        "total_user_count": 23,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 26,
                        "total_user_count": 33,
                        "day0_uninstall_count": 6
                    },
                    {
                        "_id": 27,
                        "total_user_count": 20,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 28,
                        "total_user_count": 18,
                        "day0_uninstall_count": 2
                    },
                    {
                        "_id": 29,
                        "total_user_count": 23,
                        "day0_uninstall_count": 2
                    },
                    {
                        "_id": 30,
                        "total_user_count": 17,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 31,
                        "total_user_count": 15,
                        "day0_uninstall_count": 2
                    },
                    {
                        "_id": 32,
                        "total_user_count": 13,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 33,
                        "total_user_count": 10,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 34,
                        "total_user_count": 4,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 35,
                        "total_user_count": 6,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 36,
                        "total_user_count": 9,
                        "day0_uninstall_count": 2
                    },
                    {
                        "_id": 37,
                        "total_user_count": 7,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 38,
                        "total_user_count": 16,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 39,
                        "total_user_count": 5,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 40,
                        "total_user_count": 5,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 41,
                        "total_user_count": 5,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 42,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 43,
                        "total_user_count": 3,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 44,
                        "total_user_count": 3,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 45,
                        "total_user_count": 5,
                        "day0_uninstall_count": 2
                    },
                    {
                        "_id": 46,
                        "total_user_count": 2,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 47,
                        "total_user_count": 5,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 48,
                        "total_user_count": 3,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 49,
                        "total_user_count": 3,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 50,
                        "total_user_count": 2,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 52,
                        "total_user_count": 3,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 53,
                        "total_user_count": 2,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 54,
                        "total_user_count": 2,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 57,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 58,
                        "total_user_count": 3,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 59,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 60,
                        "total_user_count": 2,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 61,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 62,
                        "total_user_count": 2,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 63,
                        "total_user_count": 1,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 67,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 71,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 76,
                        "total_user_count": 2,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 78,
                        "total_user_count": 2,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 83,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 84,
                        "total_user_count": 2,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 87,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 88,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 89,
                        "total_user_count": 1,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 100,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    }
                ]
            },
            {
                "metric": "READ_ITEM",
                "total": 29747,
                "active": 1111800,
                "activeTotal": 23975,
                "inactive": 211277,
                "inactiveTotal": 5773,
                "distribution": [
                    {
                        "_id": 0,
                        "total_user_count": 14210,
                        "day0_uninstall_count": 6970
                    },
                    {
                        "_id": 1,
                        "total_user_count": 803,
                        "day0_uninstall_count": 206
                    },
                    {
                        "_id": 2,
                        "total_user_count": 740,
                        "day0_uninstall_count": 176
                    },
                    {
                        "_id": 3,
                        "total_user_count": 767,
                        "day0_uninstall_count": 189
                    },
                    {
                        "_id": 4,
                        "total_user_count": 716,
                        "day0_uninstall_count": 200
                    },
                    {
                        "_id": 5,
                        "total_user_count": 697,
                        "day0_uninstall_count": 175
                    },
                    {
                        "_id": 6,
                        "total_user_count": 697,
                        "day0_uninstall_count": 164
                    },
                    {
                        "_id": 7,
                        "total_user_count": 623,
                        "day0_uninstall_count": 149
                    },
                    {
                        "_id": 8,
                        "total_user_count": 665,
                        "day0_uninstall_count": 161
                    },
                    {
                        "_id": 9,
                        "total_user_count": 628,
                        "day0_uninstall_count": 135
                    },
                    {
                        "_id": 10,
                        "total_user_count": 593,
                        "day0_uninstall_count": 128
                    },
                    {
                        "_id": 11,
                        "total_user_count": 585,
                        "day0_uninstall_count": 129
                    },
                    {
                        "_id": 12,
                        "total_user_count": 547,
                        "day0_uninstall_count": 123
                    },
                    {
                        "_id": 13,
                        "total_user_count": 558,
                        "day0_uninstall_count": 124
                    },
                    {
                        "_id": 14,
                        "total_user_count": 496,
                        "day0_uninstall_count": 107
                    },
                    {
                        "_id": 15,
                        "total_user_count": 496,
                        "day0_uninstall_count": 118
                    },
                    {
                        "_id": 16,
                        "total_user_count": 489,
                        "day0_uninstall_count": 104
                    },
                    {
                        "_id": 17,
                        "total_user_count": 518,
                        "day0_uninstall_count": 104
                    },
                    {
                        "_id": 18,
                        "total_user_count": 463,
                        "day0_uninstall_count": 82
                    },
                    {
                        "_id": 19,
                        "total_user_count": 465,
                        "day0_uninstall_count": 97
                    },
                    {
                        "_id": 20,
                        "total_user_count": 467,
                        "day0_uninstall_count": 95
                    },
                    {
                        "_id": 21,
                        "total_user_count": 487,
                        "day0_uninstall_count": 98
                    },
                    {
                        "_id": 22,
                        "total_user_count": 413,
                        "day0_uninstall_count": 87
                    },
                    {
                        "_id": 23,
                        "total_user_count": 430,
                        "day0_uninstall_count": 83
                    },
                    {
                        "_id": 24,
                        "total_user_count": 421,
                        "day0_uninstall_count": 91
                    },
                    {
                        "_id": 25,
                        "total_user_count": 394,
                        "day0_uninstall_count": 75
                    },
                    {
                        "_id": 26,
                        "total_user_count": 392,
                        "day0_uninstall_count": 65
                    },
                    {
                        "_id": 27,
                        "total_user_count": 381,
                        "day0_uninstall_count": 71
                    },
                    {
                        "_id": 28,
                        "total_user_count": 378,
                        "day0_uninstall_count": 68
                    },
                    {
                        "_id": 29,
                        "total_user_count": 381,
                        "day0_uninstall_count": 83
                    },
                    {
                        "_id": 30,
                        "total_user_count": 336,
                        "day0_uninstall_count": 64
                    },
                    {
                        "_id": 31,
                        "total_user_count": 342,
                        "day0_uninstall_count": 68
                    },
                    {
                        "_id": 32,
                        "total_user_count": 300,
                        "day0_uninstall_count": 64
                    },
                    {
                        "_id": 33,
                        "total_user_count": 315,
                        "day0_uninstall_count": 60
                    },
                    {
                        "_id": 34,
                        "total_user_count": 300,
                        "day0_uninstall_count": 67
                    },
                    {
                        "_id": 35,
                        "total_user_count": 322,
                        "day0_uninstall_count": 65
                    },
                    {
                        "_id": 36,
                        "total_user_count": 311,
                        "day0_uninstall_count": 40
                    },
                    {
                        "_id": 37,
                        "total_user_count": 299,
                        "day0_uninstall_count": 54
                    },
                    {
                        "_id": 38,
                        "total_user_count": 269,
                        "day0_uninstall_count": 43
                    },
                    {
                        "_id": 39,
                        "total_user_count": 258,
                        "day0_uninstall_count": 51
                    },
                    {
                        "_id": 40,
                        "total_user_count": 252,
                        "day0_uninstall_count": 57
                    },
                    {
                        "_id": 41,
                        "total_user_count": 279,
                        "day0_uninstall_count": 35
                    },
                    {
                        "_id": 42,
                        "total_user_count": 219,
                        "day0_uninstall_count": 52
                    },
                    {
                        "_id": 43,
                        "total_user_count": 263,
                        "day0_uninstall_count": 36
                    },
                    {
                        "_id": 44,
                        "total_user_count": 246,
                        "day0_uninstall_count": 30
                    },
                    {
                        "_id": 45,
                        "total_user_count": 224,
                        "day0_uninstall_count": 41
                    },
                    {
                        "_id": 46,
                        "total_user_count": 217,
                        "day0_uninstall_count": 39
                    },
                    {
                        "_id": 47,
                        "total_user_count": 195,
                        "day0_uninstall_count": 38
                    },
                    {
                        "_id": 48,
                        "total_user_count": 209,
                        "day0_uninstall_count": 33
                    },
                    {
                        "_id": 49,
                        "total_user_count": 202,
                        "day0_uninstall_count": 31
                    },
                    {
                        "_id": 50,
                        "total_user_count": 222,
                        "day0_uninstall_count": 27
                    },
                    {
                        "_id": 51,
                        "total_user_count": 199,
                        "day0_uninstall_count": 36
                    },
                    {
                        "_id": 52,
                        "total_user_count": 162,
                        "day0_uninstall_count": 28
                    },
                    {
                        "_id": 53,
                        "total_user_count": 172,
                        "day0_uninstall_count": 27
                    },
                    {
                        "_id": 54,
                        "total_user_count": 175,
                        "day0_uninstall_count": 29
                    },
                    {
                        "_id": 55,
                        "total_user_count": 186,
                        "day0_uninstall_count": 20
                    },
                    {
                        "_id": 56,
                        "total_user_count": 167,
                        "day0_uninstall_count": 27
                    },
                    {
                        "_id": 57,
                        "total_user_count": 152,
                        "day0_uninstall_count": 22
                    },
                    {
                        "_id": 58,
                        "total_user_count": 138,
                        "day0_uninstall_count": 30
                    },
                    {
                        "_id": 59,
                        "total_user_count": 121,
                        "day0_uninstall_count": 26
                    },
                    {
                        "_id": 60,
                        "total_user_count": 119,
                        "day0_uninstall_count": 29
                    },
                    {
                        "_id": 61,
                        "total_user_count": 136,
                        "day0_uninstall_count": 22
                    },
                    {
                        "_id": 62,
                        "total_user_count": 128,
                        "day0_uninstall_count": 32
                    },
                    {
                        "_id": 63,
                        "total_user_count": 143,
                        "day0_uninstall_count": 32
                    },
                    {
                        "_id": 64,
                        "total_user_count": 121,
                        "day0_uninstall_count": 23
                    },
                    {
                        "_id": 65,
                        "total_user_count": 135,
                        "day0_uninstall_count": 27
                    },
                    {
                        "_id": 66,
                        "total_user_count": 136,
                        "day0_uninstall_count": 24
                    },
                    {
                        "_id": 67,
                        "total_user_count": 118,
                        "day0_uninstall_count": 17
                    },
                    {
                        "_id": 68,
                        "total_user_count": 112,
                        "day0_uninstall_count": 21
                    },
                    {
                        "_id": 69,
                        "total_user_count": 108,
                        "day0_uninstall_count": 15
                    },
                    {
                        "_id": 70,
                        "total_user_count": 123,
                        "day0_uninstall_count": 22
                    },
                    {
                        "_id": 71,
                        "total_user_count": 102,
                        "day0_uninstall_count": 12
                    },
                    {
                        "_id": 72,
                        "total_user_count": 120,
                        "day0_uninstall_count": 19
                    },
                    {
                        "_id": 73,
                        "total_user_count": 96,
                        "day0_uninstall_count": 12
                    },
                    {
                        "_id": 74,
                        "total_user_count": 109,
                        "day0_uninstall_count": 13
                    },
                    {
                        "_id": 75,
                        "total_user_count": 110,
                        "day0_uninstall_count": 16
                    },
                    {
                        "_id": 76,
                        "total_user_count": 103,
                        "day0_uninstall_count": 16
                    },
                    {
                        "_id": 77,
                        "total_user_count": 99,
                        "day0_uninstall_count": 13
                    },
                    {
                        "_id": 78,
                        "total_user_count": 100,
                        "day0_uninstall_count": 18
                    },
                    {
                        "_id": 79,
                        "total_user_count": 115,
                        "day0_uninstall_count": 12
                    },
                    {
                        "_id": 80,
                        "total_user_count": 81,
                        "day0_uninstall_count": 11
                    },
                    {
                        "_id": 81,
                        "total_user_count": 103,
                        "day0_uninstall_count": 17
                    },
                    {
                        "_id": 82,
                        "total_user_count": 82,
                        "day0_uninstall_count": 9
                    },
                    {
                        "_id": 83,
                        "total_user_count": 86,
                        "day0_uninstall_count": 10
                    },
                    {
                        "_id": 84,
                        "total_user_count": 87,
                        "day0_uninstall_count": 15
                    },
                    {
                        "_id": 85,
                        "total_user_count": 80,
                        "day0_uninstall_count": 10
                    },
                    {
                        "_id": 86,
                        "total_user_count": 69,
                        "day0_uninstall_count": 7
                    },
                    {
                        "_id": 87,
                        "total_user_count": 69,
                        "day0_uninstall_count": 13
                    },
                    {
                        "_id": 88,
                        "total_user_count": 88,
                        "day0_uninstall_count": 17
                    },
                    {
                        "_id": 89,
                        "total_user_count": 72,
                        "day0_uninstall_count": 8
                    },
                    {
                        "_id": 90,
                        "total_user_count": 72,
                        "day0_uninstall_count": 18
                    },
                    {
                        "_id": 91,
                        "total_user_count": 66,
                        "day0_uninstall_count": 9
                    },
                    {
                        "_id": 92,
                        "total_user_count": 78,
                        "day0_uninstall_count": 7
                    },
                    {
                        "_id": 93,
                        "total_user_count": 70,
                        "day0_uninstall_count": 9
                    },
                    {
                        "_id": 94,
                        "total_user_count": 70,
                        "day0_uninstall_count": 17
                    },
                    {
                        "_id": 95,
                        "total_user_count": 58,
                        "day0_uninstall_count": 14
                    },
                    {
                        "_id": 96,
                        "total_user_count": 56,
                        "day0_uninstall_count": 6
                    },
                    {
                        "_id": 97,
                        "total_user_count": 52,
                        "day0_uninstall_count": 9
                    },
                    {
                        "_id": 98,
                        "total_user_count": 65,
                        "day0_uninstall_count": 8
                    },
                    {
                        "_id": 99,
                        "total_user_count": 54,
                        "day0_uninstall_count": 12
                    },
                    {
                        "_id": 100,
                        "total_user_count": 62,
                        "day0_uninstall_count": 8
                    },
                    {
                        "_id": 101,
                        "total_user_count": 65,
                        "day0_uninstall_count": 11
                    },
                    {
                        "_id": 102,
                        "total_user_count": 56,
                        "day0_uninstall_count": 10
                    },
                    {
                        "_id": 103,
                        "total_user_count": 50,
                        "day0_uninstall_count": 8
                    },
                    {
                        "_id": 104,
                        "total_user_count": 43,
                        "day0_uninstall_count": 7
                    },
                    {
                        "_id": 105,
                        "total_user_count": 42,
                        "day0_uninstall_count": 7
                    },
                    {
                        "_id": 106,
                        "total_user_count": 63,
                        "day0_uninstall_count": 5
                    },
                    {
                        "_id": 107,
                        "total_user_count": 57,
                        "day0_uninstall_count": 8
                    },
                    {
                        "_id": 108,
                        "total_user_count": 40,
                        "day0_uninstall_count": 5
                    },
                    {
                        "_id": 109,
                        "total_user_count": 59,
                        "day0_uninstall_count": 8
                    },
                    {
                        "_id": 110,
                        "total_user_count": 56,
                        "day0_uninstall_count": 3
                    },
                    {
                        "_id": 111,
                        "total_user_count": 40,
                        "day0_uninstall_count": 9
                    },
                    {
                        "_id": 112,
                        "total_user_count": 49,
                        "day0_uninstall_count": 9
                    },
                    {
                        "_id": 113,
                        "total_user_count": 53,
                        "day0_uninstall_count": 9
                    },
                    {
                        "_id": 114,
                        "total_user_count": 49,
                        "day0_uninstall_count": 9
                    },
                    {
                        "_id": 115,
                        "total_user_count": 45,
                        "day0_uninstall_count": 5
                    },
                    {
                        "_id": 116,
                        "total_user_count": 42,
                        "day0_uninstall_count": 4
                    },
                    {
                        "_id": 117,
                        "total_user_count": 45,
                        "day0_uninstall_count": 3
                    },
                    {
                        "_id": 118,
                        "total_user_count": 32,
                        "day0_uninstall_count": 3
                    },
                    {
                        "_id": 119,
                        "total_user_count": 38,
                        "day0_uninstall_count": 4
                    },
                    {
                        "_id": 120,
                        "total_user_count": 32,
                        "day0_uninstall_count": 10
                    },
                    {
                        "_id": 121,
                        "total_user_count": 32,
                        "day0_uninstall_count": 7
                    },
                    {
                        "_id": 122,
                        "total_user_count": 35,
                        "day0_uninstall_count": 6
                    },
                    {
                        "_id": 123,
                        "total_user_count": 35,
                        "day0_uninstall_count": 6
                    },
                    {
                        "_id": 124,
                        "total_user_count": 30,
                        "day0_uninstall_count": 3
                    },
                    {
                        "_id": 125,
                        "total_user_count": 37,
                        "day0_uninstall_count": 2
                    },
                    {
                        "_id": 126,
                        "total_user_count": 23,
                        "day0_uninstall_count": 4
                    },
                    {
                        "_id": 127,
                        "total_user_count": 29,
                        "day0_uninstall_count": 5
                    },
                    {
                        "_id": 128,
                        "total_user_count": 29,
                        "day0_uninstall_count": 4
                    },
                    {
                        "_id": 129,
                        "total_user_count": 33,
                        "day0_uninstall_count": 9
                    },
                    {
                        "_id": 130,
                        "total_user_count": 29,
                        "day0_uninstall_count": 4
                    },
                    {
                        "_id": 131,
                        "total_user_count": 36,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 132,
                        "total_user_count": 36,
                        "day0_uninstall_count": 3
                    },
                    {
                        "_id": 133,
                        "total_user_count": 21,
                        "day0_uninstall_count": 2
                    },
                    {
                        "_id": 134,
                        "total_user_count": 30,
                        "day0_uninstall_count": 2
                    },
                    {
                        "_id": 135,
                        "total_user_count": 29,
                        "day0_uninstall_count": 3
                    },
                    {
                        "_id": 136,
                        "total_user_count": 33,
                        "day0_uninstall_count": 4
                    },
                    {
                        "_id": 137,
                        "total_user_count": 27,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 138,
                        "total_user_count": 27,
                        "day0_uninstall_count": 3
                    },
                    {
                        "_id": 139,
                        "total_user_count": 25,
                        "day0_uninstall_count": 2
                    },
                    {
                        "_id": 140,
                        "total_user_count": 26,
                        "day0_uninstall_count": 3
                    },
                    {
                        "_id": 141,
                        "total_user_count": 24,
                        "day0_uninstall_count": 5
                    },
                    {
                        "_id": 142,
                        "total_user_count": 23,
                        "day0_uninstall_count": 2
                    },
                    {
                        "_id": 143,
                        "total_user_count": 20,
                        "day0_uninstall_count": 4
                    },
                    {
                        "_id": 144,
                        "total_user_count": 27,
                        "day0_uninstall_count": 4
                    },
                    {
                        "_id": 145,
                        "total_user_count": 24,
                        "day0_uninstall_count": 2
                    },
                    {
                        "_id": 146,
                        "total_user_count": 19,
                        "day0_uninstall_count": 2
                    },
                    {
                        "_id": 147,
                        "total_user_count": 29,
                        "day0_uninstall_count": 4
                    },
                    {
                        "_id": 148,
                        "total_user_count": 15,
                        "day0_uninstall_count": 3
                    },
                    {
                        "_id": 149,
                        "total_user_count": 18,
                        "day0_uninstall_count": 3
                    },
                    {
                        "_id": 150,
                        "total_user_count": 25,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 151,
                        "total_user_count": 16,
                        "day0_uninstall_count": 2
                    },
                    {
                        "_id": 152,
                        "total_user_count": 19,
                        "day0_uninstall_count": 3
                    },
                    {
                        "_id": 153,
                        "total_user_count": 19,
                        "day0_uninstall_count": 5
                    },
                    {
                        "_id": 154,
                        "total_user_count": 28,
                        "day0_uninstall_count": 8
                    },
                    {
                        "_id": 155,
                        "total_user_count": 21,
                        "day0_uninstall_count": 3
                    },
                    {
                        "_id": 156,
                        "total_user_count": 21,
                        "day0_uninstall_count": 2
                    },
                    {
                        "_id": 157,
                        "total_user_count": 24,
                        "day0_uninstall_count": 5
                    },
                    {
                        "_id": 158,
                        "total_user_count": 20,
                        "day0_uninstall_count": 3
                    },
                    {
                        "_id": 159,
                        "total_user_count": 18,
                        "day0_uninstall_count": 2
                    },
                    {
                        "_id": 160,
                        "total_user_count": 14,
                        "day0_uninstall_count": 3
                    },
                    {
                        "_id": 161,
                        "total_user_count": 13,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 162,
                        "total_user_count": 21,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 163,
                        "total_user_count": 14,
                        "day0_uninstall_count": 4
                    },
                    {
                        "_id": 164,
                        "total_user_count": 16,
                        "day0_uninstall_count": 3
                    },
                    {
                        "_id": 165,
                        "total_user_count": 11,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 166,
                        "total_user_count": 13,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 167,
                        "total_user_count": 16,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 168,
                        "total_user_count": 13,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 169,
                        "total_user_count": 15,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 170,
                        "total_user_count": 17,
                        "day0_uninstall_count": 5
                    },
                    {
                        "_id": 171,
                        "total_user_count": 16,
                        "day0_uninstall_count": 2
                    },
                    {
                        "_id": 172,
                        "total_user_count": 16,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 173,
                        "total_user_count": 12,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 174,
                        "total_user_count": 15,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 175,
                        "total_user_count": 19,
                        "day0_uninstall_count": 3
                    },
                    {
                        "_id": 176,
                        "total_user_count": 12,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 177,
                        "total_user_count": 6,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 178,
                        "total_user_count": 13,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 179,
                        "total_user_count": 10,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 180,
                        "total_user_count": 9,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 181,
                        "total_user_count": 9,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 182,
                        "total_user_count": 14,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 183,
                        "total_user_count": 6,
                        "day0_uninstall_count": 2
                    },
                    {
                        "_id": 184,
                        "total_user_count": 11,
                        "day0_uninstall_count": 2
                    },
                    {
                        "_id": 185,
                        "total_user_count": 9,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 186,
                        "total_user_count": 5,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 187,
                        "total_user_count": 9,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 188,
                        "total_user_count": 8,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 189,
                        "total_user_count": 10,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 190,
                        "total_user_count": 14,
                        "day0_uninstall_count": 4
                    },
                    {
                        "_id": 191,
                        "total_user_count": 10,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 192,
                        "total_user_count": 12,
                        "day0_uninstall_count": 2
                    },
                    {
                        "_id": 193,
                        "total_user_count": 15,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 194,
                        "total_user_count": 8,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 195,
                        "total_user_count": 7,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 196,
                        "total_user_count": 8,
                        "day0_uninstall_count": 2
                    },
                    {
                        "_id": 197,
                        "total_user_count": 10,
                        "day0_uninstall_count": 2
                    },
                    {
                        "_id": 198,
                        "total_user_count": 14,
                        "day0_uninstall_count": 2
                    },
                    {
                        "_id": 199,
                        "total_user_count": 12,
                        "day0_uninstall_count": 3
                    },
                    {
                        "_id": 200,
                        "total_user_count": 10,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 201,
                        "total_user_count": 9,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 202,
                        "total_user_count": 11,
                        "day0_uninstall_count": 2
                    },
                    {
                        "_id": 203,
                        "total_user_count": 7,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 204,
                        "total_user_count": 13,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 205,
                        "total_user_count": 13,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 206,
                        "total_user_count": 8,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 207,
                        "total_user_count": 7,
                        "day0_uninstall_count": 2
                    },
                    {
                        "_id": 208,
                        "total_user_count": 8,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 209,
                        "total_user_count": 11,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 210,
                        "total_user_count": 9,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 211,
                        "total_user_count": 9,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 212,
                        "total_user_count": 5,
                        "day0_uninstall_count": 2
                    },
                    {
                        "_id": 213,
                        "total_user_count": 5,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 214,
                        "total_user_count": 4,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 215,
                        "total_user_count": 9,
                        "day0_uninstall_count": 2
                    },
                    {
                        "_id": 216,
                        "total_user_count": 8,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 217,
                        "total_user_count": 10,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 218,
                        "total_user_count": 4,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 219,
                        "total_user_count": 9,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 220,
                        "total_user_count": 13,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 221,
                        "total_user_count": 8,
                        "day0_uninstall_count": 2
                    },
                    {
                        "_id": 222,
                        "total_user_count": 3,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 223,
                        "total_user_count": 8,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 224,
                        "total_user_count": 3,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 225,
                        "total_user_count": 8,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 226,
                        "total_user_count": 4,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 227,
                        "total_user_count": 5,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 228,
                        "total_user_count": 6,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 229,
                        "total_user_count": 6,
                        "day0_uninstall_count": 2
                    },
                    {
                        "_id": 230,
                        "total_user_count": 3,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 231,
                        "total_user_count": 5,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 232,
                        "total_user_count": 4,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 233,
                        "total_user_count": 5,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 234,
                        "total_user_count": 4,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 235,
                        "total_user_count": 7,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 236,
                        "total_user_count": 6,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 237,
                        "total_user_count": 2,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 238,
                        "total_user_count": 5,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 239,
                        "total_user_count": 9,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 240,
                        "total_user_count": 3,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 241,
                        "total_user_count": 9,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 242,
                        "total_user_count": 4,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 243,
                        "total_user_count": 7,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 244,
                        "total_user_count": 9,
                        "day0_uninstall_count": 2
                    },
                    {
                        "_id": 245,
                        "total_user_count": 6,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 246,
                        "total_user_count": 7,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 247,
                        "total_user_count": 3,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 248,
                        "total_user_count": 2,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 249,
                        "total_user_count": 5,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 250,
                        "total_user_count": 6,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 251,
                        "total_user_count": 3,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 252,
                        "total_user_count": 8,
                        "day0_uninstall_count": 5
                    },
                    {
                        "_id": 253,
                        "total_user_count": 6,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 254,
                        "total_user_count": 3,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 255,
                        "total_user_count": 2,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 256,
                        "total_user_count": 2,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 257,
                        "total_user_count": 3,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 258,
                        "total_user_count": 8,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 259,
                        "total_user_count": 5,
                        "day0_uninstall_count": 2
                    },
                    {
                        "_id": 260,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 261,
                        "total_user_count": 5,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 262,
                        "total_user_count": 6,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 263,
                        "total_user_count": 4,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 265,
                        "total_user_count": 2,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 266,
                        "total_user_count": 4,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 267,
                        "total_user_count": 6,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 269,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 270,
                        "total_user_count": 3,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 271,
                        "total_user_count": 5,
                        "day0_uninstall_count": 2
                    },
                    {
                        "_id": 272,
                        "total_user_count": 4,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 273,
                        "total_user_count": 5,
                        "day0_uninstall_count": 2
                    },
                    {
                        "_id": 274,
                        "total_user_count": 3,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 276,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 277,
                        "total_user_count": 2,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 278,
                        "total_user_count": 4,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 279,
                        "total_user_count": 2,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 280,
                        "total_user_count": 6,
                        "day0_uninstall_count": 2
                    },
                    {
                        "_id": 281,
                        "total_user_count": 4,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 282,
                        "total_user_count": 6,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 283,
                        "total_user_count": 3,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 284,
                        "total_user_count": 6,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 285,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 286,
                        "total_user_count": 2,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 287,
                        "total_user_count": 3,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 288,
                        "total_user_count": 3,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 289,
                        "total_user_count": 2,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 290,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 292,
                        "total_user_count": 5,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 294,
                        "total_user_count": 4,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 295,
                        "total_user_count": 2,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 296,
                        "total_user_count": 3,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 297,
                        "total_user_count": 4,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 298,
                        "total_user_count": 3,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 299,
                        "total_user_count": 3,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 300,
                        "total_user_count": 5,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 301,
                        "total_user_count": 4,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 302,
                        "total_user_count": 1,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 303,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 304,
                        "total_user_count": 3,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 305,
                        "total_user_count": 3,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 306,
                        "total_user_count": 3,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 307,
                        "total_user_count": 3,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 308,
                        "total_user_count": 2,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 309,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 310,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 314,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 315,
                        "total_user_count": 4,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 316,
                        "total_user_count": 1,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 318,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 320,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 322,
                        "total_user_count": 2,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 323,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 324,
                        "total_user_count": 2,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 325,
                        "total_user_count": 3,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 326,
                        "total_user_count": 5,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 327,
                        "total_user_count": 2,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 328,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 329,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 330,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 331,
                        "total_user_count": 2,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 334,
                        "total_user_count": 2,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 335,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 339,
                        "total_user_count": 3,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 341,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 342,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 343,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 344,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 347,
                        "total_user_count": 2,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 348,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 349,
                        "total_user_count": 2,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 350,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 351,
                        "total_user_count": 3,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 352,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 354,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 355,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 356,
                        "total_user_count": 2,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 358,
                        "total_user_count": 1,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 359,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 361,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 362,
                        "total_user_count": 2,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 363,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 367,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 368,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 369,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 370,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 372,
                        "total_user_count": 2,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 374,
                        "total_user_count": 1,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 377,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 378,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 381,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 382,
                        "total_user_count": 3,
                        "day0_uninstall_count": 2
                    },
                    {
                        "_id": 383,
                        "total_user_count": 2,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 384,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 385,
                        "total_user_count": 2,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 386,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 390,
                        "total_user_count": 2,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 391,
                        "total_user_count": 2,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 392,
                        "total_user_count": 2,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 393,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 396,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 397,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 403,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 405,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 410,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 413,
                        "total_user_count": 2,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 418,
                        "total_user_count": 2,
                        "day0_uninstall_count": 2
                    },
                    {
                        "_id": 420,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 421,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 422,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 426,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 427,
                        "total_user_count": 1,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 429,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 430,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 435,
                        "total_user_count": 2,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 436,
                        "total_user_count": 2,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 438,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 440,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 442,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 443,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 453,
                        "total_user_count": 1,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 456,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 465,
                        "total_user_count": 1,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 471,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 475,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 477,
                        "total_user_count": 2,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 478,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 483,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 487,
                        "total_user_count": 1,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 488,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 493,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 495,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 497,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 499,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 500,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 502,
                        "total_user_count": 2,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 503,
                        "total_user_count": 1,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 504,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 506,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 508,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 515,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 519,
                        "total_user_count": 1,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 524,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 551,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 562,
                        "total_user_count": 1,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 565,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 571,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 584,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 601,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 634,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 683,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 706,
                        "total_user_count": 2,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 747,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 1023,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    }
                ]
            },
            {
                "metric": "VIDEO_VIEW_V2",
                "total": 31592,
                "active": 96219,
                "activeTotal": 25264,
                "inactive": 21117,
                "inactiveTotal": 6328,
                "distribution": [
                    {
                        "_id": 0,
                        "total_user_count": 12342,
                        "day0_uninstall_count": 6416
                    },
                    {
                        "_id": 1,
                        "total_user_count": 9315,
                        "day0_uninstall_count": 2227
                    },
                    {
                        "_id": 2,
                        "total_user_count": 6650,
                        "day0_uninstall_count": 1375
                    },
                    {
                        "_id": 3,
                        "total_user_count": 4528,
                        "day0_uninstall_count": 828
                    },
                    {
                        "_id": 4,
                        "total_user_count": 3095,
                        "day0_uninstall_count": 556
                    },
                    {
                        "_id": 5,
                        "total_user_count": 2142,
                        "day0_uninstall_count": 395
                    },
                    {
                        "_id": 6,
                        "total_user_count": 1459,
                        "day0_uninstall_count": 234
                    },
                    {
                        "_id": 7,
                        "total_user_count": 1039,
                        "day0_uninstall_count": 158
                    },
                    {
                        "_id": 8,
                        "total_user_count": 687,
                        "day0_uninstall_count": 116
                    },
                    {
                        "_id": 9,
                        "total_user_count": 543,
                        "day0_uninstall_count": 94
                    },
                    {
                        "_id": 10,
                        "total_user_count": 424,
                        "day0_uninstall_count": 61
                    },
                    {
                        "_id": 11,
                        "total_user_count": 359,
                        "day0_uninstall_count": 55
                    },
                    {
                        "_id": 12,
                        "total_user_count": 236,
                        "day0_uninstall_count": 37
                    },
                    {
                        "_id": 13,
                        "total_user_count": 198,
                        "day0_uninstall_count": 31
                    },
                    {
                        "_id": 14,
                        "total_user_count": 184,
                        "day0_uninstall_count": 39
                    },
                    {
                        "_id": 15,
                        "total_user_count": 112,
                        "day0_uninstall_count": 22
                    },
                    {
                        "_id": 16,
                        "total_user_count": 95,
                        "day0_uninstall_count": 14
                    },
                    {
                        "_id": 17,
                        "total_user_count": 97,
                        "day0_uninstall_count": 15
                    },
                    {
                        "_id": 18,
                        "total_user_count": 81,
                        "day0_uninstall_count": 9
                    },
                    {
                        "_id": 19,
                        "total_user_count": 50,
                        "day0_uninstall_count": 5
                    },
                    {
                        "_id": 20,
                        "total_user_count": 55,
                        "day0_uninstall_count": 12
                    },
                    {
                        "_id": 21,
                        "total_user_count": 44,
                        "day0_uninstall_count": 8
                    },
                    {
                        "_id": 22,
                        "total_user_count": 21,
                        "day0_uninstall_count": 6
                    },
                    {
                        "_id": 23,
                        "total_user_count": 17,
                        "day0_uninstall_count": 4
                    },
                    {
                        "_id": 24,
                        "total_user_count": 20,
                        "day0_uninstall_count": 6
                    },
                    {
                        "_id": 25,
                        "total_user_count": 17,
                        "day0_uninstall_count": 2
                    },
                    {
                        "_id": 26,
                        "total_user_count": 17,
                        "day0_uninstall_count": 2
                    },
                    {
                        "_id": 27,
                        "total_user_count": 13,
                        "day0_uninstall_count": 2
                    },
                    {
                        "_id": 28,
                        "total_user_count": 8,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 29,
                        "total_user_count": 8,
                        "day0_uninstall_count": 2
                    },
                    {
                        "_id": 30,
                        "total_user_count": 6,
                        "day0_uninstall_count": 2
                    },
                    {
                        "_id": 31,
                        "total_user_count": 6,
                        "day0_uninstall_count": 2
                    },
                    {
                        "_id": 32,
                        "total_user_count": 6,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 33,
                        "total_user_count": 3,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 34,
                        "total_user_count": 9,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 35,
                        "total_user_count": 3,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 36,
                        "total_user_count": 4,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 37,
                        "total_user_count": 2,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 38,
                        "total_user_count": 3,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 39,
                        "total_user_count": 3,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 40,
                        "total_user_count": 3,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 41,
                        "total_user_count": 2,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 42,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 43,
                        "total_user_count": 6,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 44,
                        "total_user_count": 2,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 45,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 47,
                        "total_user_count": 3,
                        "day0_uninstall_count": 2
                    },
                    {
                        "_id": 48,
                        "total_user_count": 2,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 49,
                        "total_user_count": 2,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 50,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 52,
                        "total_user_count": 1,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 53,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 54,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 56,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 60,
                        "total_user_count": 1,
                        "day0_uninstall_count": 1
                    },
                    {
                        "_id": 63,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 69,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 71,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 75,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    },
                    {
                        "_id": 85,
                        "total_user_count": 1,
                        "day0_uninstall_count": 0
                    }
                ]
            }
        ]
    },
    "kpis": {
        "data": [
            {
                "metric": "Sessions",
                xKey: "_id",
                yKeys: ["day0_uninstall_count", "day0_uninstall_count_percent", "total_user_count"],
                xLabel: "Sessions",
                yLabel: "Users",
                "distribution": [
                    {
                        "_id": "0",
                        "day0_uninstall_count": 0,
                        "day0_uninstall_count_percent": 0,
                        "total_user_count": 0
                    },
                    {
                        "_id": "1to2",
                        "day0_uninstall_count": 5791,
                        "day0_uninstall_count_percent": 100,
                        "total_user_count": 9898
                    },
                    {
                        "_id": "2to4",
                        "day0_uninstall_count": 4604,
                        "day0_uninstall_count_percent": 90,
                        "total_user_count": 12188
                    },
                    {
                        "_id": "4to6",
                        "day0_uninstall_count": 1520,
                        "day0_uninstall_count_percent": 60,
                        "total_user_count": 7159
                    },
                    {
                        "_id": "6to8",
                        "day0_uninstall_count": 603,
                        "day0_uninstall_count_percent": 40,
                        "total_user_count": 4503
                    },
                    {
                        "_id": "8to10",
                        "day0_uninstall_count": 305,
                        "day0_uninstall_count_percent": 30,
                        "total_user_count": 2993
                    },
                    {
                        "_id": "10to12",
                        "day0_uninstall_count": 196,
                        "day0_uninstall_count_percent": 25,
                        "total_user_count": 2000
                    },
                    {
                        "_id": "12to14",
                        "day0_uninstall_count": 126,
                        "day0_uninstall_count_percent": 12,
                        "total_user_count": 1483
                    },
                    {
                        "_id": ">14",
                        "day0_uninstall_count": 276,
                        "day0_uninstall_count_percent": 23,
                        "total_user_count": 6779
                    }
                ]
            },
            {
                "metric": "Session Length",
                xKey: "_id",
                yKeys: ["day0_uninstall_count", "total_user_count"],
                xLabel: "Session Length",
                yLabel: "Users",
                "distribution": [
                    {
                        "_id": "0",
                        "day0_uninstall_count": 2,
                        "total_user_count": 317
                    },
                    {
                        "_id": "1 to 40",
                        "day0_uninstall_count": 1226,
                        "total_user_count": 2736
                    },
                    {
                        "_id": "40 to 80",
                        "day0_uninstall_count": 1429,
                        "total_user_count": 4735
                    },
                    {
                        "_id": "80 to 120",
                        "day0_uninstall_count": 2161,
                        "total_user_count": 8175
                    },
                    {
                        "_id": "120 to 160",
                        "day0_uninstall_count": 2115,
                        "total_user_count": 8460
                    },
                    {
                        "_id": "160 to 200",
                        "day0_uninstall_count": 1828,
                        "total_user_count": 6673
                    },
                    {
                        "_id": "200 to 240",
                        "day0_uninstall_count": 1293,
                        "total_user_count": 4436
                    },
                    {
                        "_id": "240 to 280",
                        "day0_uninstall_count": 891,
                        "total_user_count": 2888
                    }
                ]
            }
        ]
    },
    "errors": {
        "data": [
            {
                "metric": "Crashes",
                "total": 227,
                "active": 376,
                "activeTotal": 159,
                "inactive": 319,
                "inactiveTotal": 68,
                "distribution": [
                    {
                        "_id": "0",
                        "day0_uninstall_count": 13353,
                        "total_user_count": 46474
                    },
                    {
                        "_id": "1to2",
                        "day0_uninstall_count": 34,
                        "total_user_count": 200
                    },
                    {
                        "_id": "2to4",
                        "day0_uninstall_count": 15,
                        "total_user_count": 70
                    },
                    {
                        "_id": "4to8",
                        "day0_uninstall_count": 7,
                        "total_user_count": 33
                    },
                    {
                        "_id": "8to10",
                        "day0_uninstall_count": 3,
                        "total_user_count": 7
                    },
                    {
                        "_id": ">10",
                        "day0_uninstall_count": 9,
                        "total_user_count": 21
                    }
                ]
            },
            {
                "metric": "Hangs",
                "total": 1398,
                "active": 2481,
                "activeTotal": 1028,
                "inactive": 928,
                "inactiveTotal": 370,
                "distribution": [
                    {
                        "_id": "0",
                        "day0_uninstall_count": 13051,
                        "total_user_count": 43562
                    },
                    {
                        "_id": "1to2",
                        "day0_uninstall_count": 194,
                        "total_user_count": 1288
                    },
                    {
                        "_id": "2to4",
                        "day0_uninstall_count": 113,
                        "total_user_count": 905
                    },
                    {
                        "_id": "4to8",
                        "day0_uninstall_count": 40,
                        "total_user_count": 583
                    },
                    {
                        "_id": "8to10",
                        "day0_uninstall_count": 9,
                        "total_user_count": 111
                    },
                    {
                        "_id": ">10",
                        "day0_uninstall_count": 14,
                        "total_user_count": 356
                    }
                ]
            },
            {
                "metric": "search fails",
                "total": 26271,
                "active": 142414,
                "activeTotal": 20951,
                "inactive": 31742,
                "inactiveTotal": 5320,
                "distribution": [
                    {
                        "_id": "0",
                        "day0_uninstall_count": 8101,
                        "total_user_count": 16756
                    },
                    {
                        "_id": "1to2",
                        "day0_uninstall_count": 1009,
                        "total_user_count": 4012
                    },
                    {
                        "_id": "2to4",
                        "day0_uninstall_count": 1600,
                        "total_user_count": 6729
                    },
                    {
                        "_id": "4to8",
                        "day0_uninstall_count": 1478,
                        "total_user_count": 7590
                    },
                    {
                        "_id": "8to10",
                        "day0_uninstall_count": 343,
                        "total_user_count": 2218
                    },
                    {
                        "_id": ">10",
                        "day0_uninstall_count": 890,
                        "total_user_count": 9500
                    }
                ]
            }
        ]
    },
    "turnarounds": {
        "attention": {
            "data": [
                {
                    "text": "Sessions",
                    "description": "No. of sessions before D0 uninstall",
                    "value": 2.7
                },
                {
                    "text": "Session Length",
                    "description": "Total duration spent in before D0 uninstall",
                    "value": "110.4 sec"
                },
                {
                    "text": "Events",
                    "description": "No. of user interactions before D0 uninstall",
                    "value": 29.7
                }
            ]
        },
        "success": {
            "data": [
                {
                    "text": "After successful search and share",
                    "description": "88% of users are retained",
                    "value": "88%"
                },
                {
                    "text": "Read without search 20 or more times ",
                    "description": "85% of users are retained",
                    "value": "85%"
                }
            ]
        },
        "failure": {
            "data": [
                {
                    "text": "Chat Helper",
                    "description": "Users on D0 uninstalled around ",
                    "value": "50%"
                },
                {
                    "text": "Crash at Login Screen",
                    "description": "Users on D0 uninstalled around ",
                    "value": "40%"
                }
            ]
        }
    },
    "insights": {
        "data": [
            {
                "insight": " 87% of new installs who change language are from en to ta/ml. Out of those users majority (82%) end up uninstalling the app without further  activity (< 2 sessions before uninstall)",
                "data": {
                    "nodes": [
                        {
                            "name": "D0 Uninstalls"
                        },
                        {
                            "name": "en-ta"
                        },
                        {
                            "name": "en-ml"
                        },
                        {
                            "name": "Number of Sessions - 1"
                        },
                        {
                            "name": "Number of Sessions - 2"
                        },
                        {
                            "name": "Number of Sessions - 3+"
                        }
                    ],
                    "links": [
                        {
                            "source": 0,
                            "target": 1,
                            "value": 4985
                        },
                        {
                            "source": 0,
                            "target": 2,
                            "value": 3460
                        },
                        {
                            "source": 1,
                            "target": 3,
                            "value": 2980
                        },
                        {
                            "source": 1,
                            "target": 4,
                            "value": 1210
                        },
                        {
                            "source": 1,
                            "target": 5,
                            "value": 795
                        },
                        {
                            "source": 2,
                            "target": 3,
                            "value": 1905
                        },
                        {
                            "source": 2,
                            "target": 4,
                            "value": 810
                        },
                        {
                            "source": 2,
                            "target": 5,
                            "value": 745
                        }
                    ]
                }
            },
            {
                "insight": " Amongst D0 Uninstalls who are willing to use the app (ones who did one or more card taps), around 20% users spend less than 20 seconds (avg) on SignUpActivity - Not enaged with content",
                "data": {
                    "nodes": [
                        {
                            "name": "D0 Uninstalls"
                        },
                        {
                            "name": "Card Taps (1-2)"
                        },
                        {
                            "name": "Card Taps (3-4)"
                        },
                        {
                            "name": "Card Taps 5+"
                        },
                        {
                            "name": "LoginActivity ( < 15 seconds)"
                        },
                        {
                            "name": "SignUpActivity ( 15 - 60 seconds)"
                        }
                    ],
                    "links": [
                        {
                            "source": 0,
                            "target": 1,
                            "value": 4430
                        },
                        {
                            "source": 0,
                            "target": 2,
                            "value": 1415
                        },
                        {
                            "source": 0,
                            "target": 3,
                            "value": 1155
                        },
                        {
                            "source": 1,
                            "target": 4,
                            "value": 980
                        },
                        {
                            "source": 2,
                            "target": 4,
                            "value": 35
                        },
                        {
                            "source": 2,
                            "target": 5,
                            "value": 210
                        },
                        {
                            "source": 3,
                            "target": 4,
                            "value": 0
                        },
                        {
                            "source": 3,
                            "target": 5,
                            "value": 25
                        }
                    ]
                }
            },
            {
                "insight": "6.5% of new installs spend less than 15 seconds in app on D0, out of which 28.5% users end up uninstalling app"
            },
            {
                "insight": "Activities that are not well discovered by new installs on D0 - SettingsActivity (12.5%), AboutActivity (10%) "
            },
            {
                "insight": "There is no impact of crashes/ hangs on D0 users. Total number of crashes and hangs for D0 uninstalled users - 16 "
            }
        ]
    },
    "behavior": {
        "name": "Event Behvaior",
        "data": [
            {
                "name": "Share",
                "count": 123456,
                "users": 64567,
                "sessions": 100000,
                "attributes": [
                    {
                        "name": "Language",
                        "data": [
                            {
                                "name": "Malayalam",
                                "newinstalls": 25580,
                                "day0uninstalls": 3610
                            },
                            {
                                "name": "Telugu",
                                "newinstalls": 24070,
                                "day0uninstalls": 4970
                            },
                            {
                                "name": "Tamil",
                                "newinstalls": 23860,
                                "day0uninstalls": 5345
                            },
                            {
                                "name": "English",
                                "newinstalls": 3710,
                                "day0uninstalls": 1115
                            },
                            {
                                "name": "Hindi",
                                "newinstalls": 2740,
                                "day0uninstalls": 710
                            },
                            {
                                "name": "Urdu",
                                "newinstalls": 925,
                                "day0uninstalls": 245
                            },
                            {
                                "name": "Kannada",
                                "newinstalls": 575,
                                "day0uninstalls": 140
                            },
                            {
                                "name": "Gujarathi",
                                "newinstalls": 285,
                                "day0uninstalls": 60
                            },
                            {
                                "name": "Bengali",
                                "newinstalls": 465,
                                "day0uninstalls": 100
                            },
                            {
                                "name": "Marati",
                                "newinstalls": 180,
                                "day0uninstalls": 45
                            },
                            {
                                "name": "Punjabi",
                                "newinstalls": 5,
                                "day0uninstalls": 0
                            }
                        ]
                    },
                    {
                        "name": "Location",
                        "data": [
                            {
                                "name": "IN",
                                "newinstalls": 55615,
                                "day0uninstalls": 10800
                            },
                            {
                                "name": "ID",
                                "newinstalls": 24190,
                                "day0uninstalls": 4980
                            },
                            {
                                "name": "US",
                                "newinstalls": 1505,
                                "day0uninstalls": 455
                            },
                            {
                                "name": "UK",
                                "newinstalls": 975,
                                "day0uninstalls": 345
                            },
                            {
                                "name": "WORLD",
                                "newinstalls": 230,
                                "day0uninstalls": 55
                            },
                            {
                                "name": "AU",
                                "newinstalls": 15,
                                "day0uninstalls": 5
                            },
                            {
                                "name": "VN",
                                "newinstalls": 5,
                                "day0uninstalls": 5
                            },
                            {
                                "_id": "MY",
                                "newinstalls": 5,
                                "day0uninstalls": 5
                            },
                            {
                                "_id": "CA",
                                "newinstalls": 5,
                                "day0uninstalls": 5
                            },
                            {
                                "_id": "SG",
                                "newinstalls": 5,
                                "day0uninstalls": 0
                            },
                            {
                                "_id": "PK",
                                "newinstalls": 5,
                                "day0uninstalls": 5
                            },
                            {
                                "_id": "PH",
                                "newinstalls": 5,
                                "day0uninstalls": 5
                            }
                        ]
                    },
                    {
                        "name": "Acquisition",
                        "data": [
                            {
                                "name": "Inorganic",
                                "newinstalls": 80315,
                                "day0uninstalls": 5270
                            },
                            {
                                "name": "Organic",
                                "newinstalls": 3620,
                                "day0uninstalls": 1075
                            }
                        ]
                    },
                    {
                        "name": "OS Version",
                        "data": [
                            {
                                "name": "6.0",
                                "newinstalls": 20090,
                                "day0uninstalls": 6210
                            },
                            {
                                "name": "6.0.1",
                                "newinstalls": 16545,
                                "day0uninstalls": 5790
                            },
                            {
                                "name": "5.5",
                                "newinstalls": 13680,
                                "day0uninstalls": 4540
                            },
                            {
                                "name": "5.1.1",
                                "newinstalls": 12405,
                                "day0uninstalls": 4410
                            },
                            {
                                "name": "4.4.2",
                                "newinstalls": 8950,
                                "day0uninstalls": 2335
                            },
                            {
                                "name": "4.4.4",
                                "newinstalls": 3545,
                                "day0uninstalls": 1240
                            },
                            {
                                "name": "7.0",
                                "newinstalls": 2590,
                                "day0uninstalls": 810
                            },
                            {
                                "name": "5.0.2",
                                "newinstalls": 2415,
                                "day0uninstalls": 795
                            },
                            {
                                "name": "5.0",
                                "newinstalls": 1605,
                                "day0uninstalls": 530
                            },
                            {
                                "name": "4.2.2",
                                "newinstalls": 1050,
                                "day0uninstalls": 255
                            },
                            {
                                "name": "4.3",
                                "newinstalls": 460,
                                "day0uninstalls": 180
                            },
                            {
                                "name": "5.0.1",
                                "newinstalls": 375,
                                "day0uninstalls": 100
                            },
                            {
                                "name": "4.2.1",
                                "newinstalls": 75,
                                "day0uninstalls": 10
                            },
                            {
                                "name": "7.1.1",
                                "newinstalls": 75,
                                "day0uninstalls": 30
                            },
                            {
                                "name": "4.4.3",
                                "newinstalls": 60,
                                "day0uninstalls": 20
                            },
                            {
                                "name": "7.1.2",
                                "newinstalls": 20,
                                "day0uninstalls": 15
                            },
                            {
                                "name": "6.1",
                                "newinstalls": 5,
                                "day0uninstalls": 5
                            }
                        ]
                    },
                    {
                        "name": "Network Type",
                        "data": [
                            {
                                "name": "4G",
                                "newinstalls": 36100,
                                "day0uninstalls": 7460
                            },
                            {
                                "name": "3G",
                                "newinstalls": 27050,
                                "day0uninstalls": 5125
                            },
                            {
                                "name": "WIFI",
                                "newinstalls": 17035,
                                "day0uninstalls": 3085
                            },
                            {
                                "name": "2G",
                                "newinstalls": 10110,
                                "day0uninstalls": 755
                            },
                            {
                                "name": "Unknown",
                                "newinstalls": 37210,
                                "day0uninstalls": 1980
                            }
                        ]
                    }
                ]
            },
            {
                "name": "Like",
                "count": 123456,
                "users": 64567,
                "sessions": 100000,
                "attributes": [
                    {
                        "name": "Language",
                        "data": [
                            {
                                "name": "Malayalam",
                                "newinstalls": 25580,
                                "day0uninstalls": 3610
                            },
                            {
                                "name": "Telugu",
                                "newinstalls": 24070,
                                "day0uninstalls": 4970
                            },
                            {
                                "name": "Tamil",
                                "newinstalls": 23860,
                                "day0uninstalls": 5345
                            },
                            {
                                "name": "English",
                                "newinstalls": 3710,
                                "day0uninstalls": 1115
                            },
                            {
                                "name": "Hindi",
                                "newinstalls": 2740,
                                "day0uninstalls": 710
                            },
                            {
                                "name": "Urdu",
                                "newinstalls": 925,
                                "day0uninstalls": 245
                            },
                            {
                                "name": "Kannada",
                                "newinstalls": 575,
                                "day0uninstalls": 140
                            },
                            {
                                "name": "Gujarathi",
                                "newinstalls": 285,
                                "day0uninstalls": 60
                            },
                            {
                                "name": "Bengali",
                                "newinstalls": 465,
                                "day0uninstalls": 100
                            },
                            {
                                "name": "Marati",
                                "newinstalls": 180,
                                "day0uninstalls": 45
                            },
                            {
                                "name": "Punjabi",
                                "newinstalls": 5,
                                "day0uninstalls": 0
                            }
                        ]
                    },
                    {
                        "name": "Location",
                        "data": [
                            {
                                "name": "IN",
                                "newinstalls": 55615,
                                "day0uninstalls": 10800
                            },
                            {
                                "name": "ID",
                                "newinstalls": 24190,
                                "day0uninstalls": 4980
                            },
                            {
                                "name": "US",
                                "newinstalls": 1505,
                                "day0uninstalls": 455
                            },
                            {
                                "name": "UK",
                                "newinstalls": 975,
                                "day0uninstalls": 345
                            },
                            {
                                "name": "WORLD",
                                "newinstalls": 230,
                                "day0uninstalls": 55
                            },
                            {
                                "name": "AU",
                                "newinstalls": 15,
                                "day0uninstalls": 5
                            },
                            {
                                "name": "VN",
                                "newinstalls": 5,
                                "day0uninstalls": 5
                            },
                            {
                                "_id": "MY",
                                "newinstalls": 5,
                                "day0uninstalls": 5
                            },
                            {
                                "_id": "CA",
                                "newinstalls": 5,
                                "day0uninstalls": 5
                            },
                            {
                                "_id": "SG",
                                "newinstalls": 5,
                                "day0uninstalls": 0
                            },
                            {
                                "_id": "PK",
                                "newinstalls": 5,
                                "day0uninstalls": 5
                            },
                            {
                                "_id": "PH",
                                "newinstalls": 5,
                                "day0uninstalls": 5
                            }
                        ]
                    },
                    {
                        "name": "Acquisition",
                        "data": [
                            {
                                "name": "Inorganic",
                                "newinstalls": 80315,
                                "day0uninstalls": 5270
                            },
                            {
                                "name": "Organic",
                                "newinstalls": 3620,
                                "day0uninstalls": 1075
                            }
                        ]
                    },
                    {
                        "name": "OS Version",
                        "data": [
                            {
                                "name": "6.0",
                                "newinstalls": 20090,
                                "day0uninstalls": 6210
                            },
                            {
                                "name": "6.0.1",
                                "newinstalls": 16545,
                                "day0uninstalls": 5790
                            },
                            {
                                "name": "5.5",
                                "newinstalls": 13680,
                                "day0uninstalls": 4540
                            },
                            {
                                "name": "5.1.1",
                                "newinstalls": 12405,
                                "day0uninstalls": 4410
                            },
                            {
                                "name": "4.4.2",
                                "newinstalls": 8950,
                                "day0uninstalls": 2335
                            },
                            {
                                "name": "4.4.4",
                                "newinstalls": 3545,
                                "day0uninstalls": 1240
                            },
                            {
                                "name": "7.0",
                                "newinstalls": 2590,
                                "day0uninstalls": 810
                            },
                            {
                                "name": "5.0.2",
                                "newinstalls": 2415,
                                "day0uninstalls": 795
                            },
                            {
                                "name": "5.0",
                                "newinstalls": 1605,
                                "day0uninstalls": 530
                            },
                            {
                                "name": "4.2.2",
                                "newinstalls": 1050,
                                "day0uninstalls": 255
                            },
                            {
                                "name": "4.3",
                                "newinstalls": 460,
                                "day0uninstalls": 180
                            },
                            {
                                "name": "5.0.1",
                                "newinstalls": 375,
                                "day0uninstalls": 100
                            },
                            {
                                "name": "4.2.1",
                                "newinstalls": 75,
                                "day0uninstalls": 10
                            },
                            {
                                "name": "7.1.1",
                                "newinstalls": 75,
                                "day0uninstalls": 30
                            },
                            {
                                "name": "4.4.3",
                                "newinstalls": 60,
                                "day0uninstalls": 20
                            },
                            {
                                "name": "7.1.2",
                                "newinstalls": 20,
                                "day0uninstalls": 15
                            },
                            {
                                "name": "6.1",
                                "newinstalls": 5,
                                "day0uninstalls": 5
                            }
                        ]
                    },
                    {
                        "name": "Network Type",
                        "data": [
                            {
                                "name": "4G",
                                "newinstalls": 36100,
                                "day0uninstalls": 7460
                            },
                            {
                                "name": "3G",
                                "newinstalls": 27050,
                                "day0uninstalls": 5125
                            },
                            {
                                "name": "WIFI",
                                "newinstalls": 17035,
                                "day0uninstalls": 3085
                            },
                            {
                                "name": "2G",
                                "newinstalls": 10110,
                                "day0uninstalls": 755
                            },
                            {
                                "name": "Unknown",
                                "newinstalls": 37210,
                                "day0uninstalls": 1980
                            }
                        ]
                    }
                ]
            },
            {
                "name": "Search",
                "count": 123456,
                "users": 64567,
                "sessions": 100000
            }
        ]
    },
    "timeSpent": {
        "data": [
            {
                "Time Spent": "0",
                "Uninstalled": 25,
                "Retained": 56
            },
            {
                "Time Spent": "1-3",
                "Uninstalled": 25,
                "Retained": 16
            },
            {
                "Time Spent": "4-6",
                "Uninstalled": 25,
                "Retained": 66
            },
            {
                "Time Spent": "6-10",
                "Uninstalled": 11,
                "Retained": 26
            },
            {
                "Time Spent": ">10",
                "Uninstalled": 65,
                "Retained": 36
            }
        ]
    },
    "dropOff": {
        "data": [
            {
                "Activity": "Activity1",
                "Users": 4567,
                "Users_percent": 47,
                "timeSpent": 25,
                "topEvents": [
                    {
                        "name": "Event1",
                        "count": 23
                    },
                    {
                        "name": "Event2",
                        "count": 13
                    }
                ]
            },
            {
                "Activity": "Activity2",
                "Users": 3567,
                "Users_percent": 47,
                "timeSpent": 25,
                "topEvents": [
                    {
                        "name": "Event1",
                        "count": 23
                    },
                    {
                        "name": "Event2",
                        "count": 13
                    }
                ]
            },
            {
                "Activity": "Activity3",
                "Users": 2467,
                "Users_percent": 47,
                "timeSpent": 25,
                "topEvents": [
                    {
                        "name": "Event1",
                        "count": 23
                    },
                    {
                        "name": "Event2",
                        "count": 13
                    }
                ]
            },
            {
                "Activity": "Activity4",
                "Users": 1567,
                "Users_percent": 47,
                "timeSpent": 25,
                "topEvents": [
                    {
                        "name": "Event1",
                        "count": 23
                    },
                    {
                        "name": "Event2",
                        "count": 13
                    }
                ]
            },
            {
                "Activity": "Activity5",
                "Users": 1267,
                "Users_percent": 47,
                "timeSpent": 25,
                "topEvents": [
                    {
                        "name": "Event1",
                        "count": 23
                    },
                    {
                        "name": "Event2",
                        "count": 13
                    }
                ]
            }
        ]
    }
};
/**
 * Created by jyothi on 22/8/17.
 */

/**
 * action types for gallery
 * @type {string}
 */
export const APPS_PENDING = "APPS_PENDING";
export const APPS = "APPS";
export const APPS_FAILED = "APPS_FAILED";


export const RESET_APPS = "RESET_APPS";
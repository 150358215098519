/**
 * Created by jyothi on 1/8/17.
 */

import {
    REPORTS_FAILED,
    REPORTS_PENDING,
    REPORTS_SUCCESS,
    ADD_REPORT_PENDING,
    ADD_REPORT_SUCCESS,
    ADD_REPORT_FAILED,
    PREPROCESS_LIST_PENDING,
    PREPROCESS_LIST,
    PREPROCESS_LIST_FAILED,
    PREPROCESS_PENDING,
    PREPROCESS,
    PREPROCESS_FAILED,
    UPDATE_PREPROCESS_CONFIG,
    PREPROCESS_REPORT_DELETE_PENDING,
    PREPROCESS_REPORT_DELETE, PREPROCESS_REPORT_DELETE_FAILED
} from './actionTypes';
import {getReportsAPI, addReportAPI, getPreprocessListAPI, getPreprocessAPI, deletePreprocessReportAPI} from './api';
import EmptyReportData from '../../../../../../../constants/EmptyReportData';

/**
 *
 * @param appId
 * @returns {function(*, *)}
 */
export const getReports = (appId) => {
    return (dispatch, getState) => {
        let filters = {...getState().filters};
        return dispatch({
            types: [
                REPORTS_PENDING,
                REPORTS_SUCCESS,
                REPORTS_FAILED
            ],
            payload: {
                promise: getReportsAPI(getState().auth, appId, filters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //If Any
            }
        });
    };
};

/**
 *
 * @param appId
 * @param startDate
 * @param endDate
 * @param reportType
 * @returns {function(*, *)}
 */
export const addReport = (appId, startDate, endDate, reportType) => {
    return (dispatch, getState) => {
        let filters = {...getState().filters};
        let newReportData = {
            startDate: startDate,
            endDate: endDate,
            report_type: reportType,
            data: EmptyReportData
        };
        return dispatch({
            types: [
                ADD_REPORT_PENDING,
                ADD_REPORT_SUCCESS,
                ADD_REPORT_FAILED
            ],
            payload: {
                promise: addReportAPI(getState().auth, appId, newReportData)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //If Any
            },
            callbacks: {
                successWillDispatch: (dispatch, result) => { //getting reports after adding new report
                    dispatch(getReports(appId));
                }
            }
        });
    };
};

export const getPreprocessList = (appId) => {
    return (dispatch, getState) => {
        let filters = {...getState().filters};
        return dispatch({
            types: [
                PREPROCESS_LIST_PENDING,
                PREPROCESS_LIST,
                PREPROCESS_LIST_FAILED
            ],
            payload: {
                promise: getPreprocessListAPI(getState().auth, appId, filters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //If Any
            }
        });
    };
};

export const getPreprocessed = (appId, queryParams) => {
    return (dispatch, getState) => {
        const { event, ...others } = queryParams;
        const filters = {...getState().filters, event, ...others};
        return dispatch({
            types: [
                PREPROCESS_PENDING,
                PREPROCESS,
                PREPROCESS_FAILED
            ],
            payload: {
                promise: getPreprocessAPI(getState().auth, appId, filters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //If Any
            }
        });
    };
};

export const deletePreprocessReport = (appId, reportId) => {
    return (dispatch, getState) => {
        return dispatch({
            types: [
                PREPROCESS_REPORT_DELETE_PENDING,
                PREPROCESS_REPORT_DELETE,
                PREPROCESS_REPORT_DELETE_FAILED
            ],
            payload: {
                promise: deletePreprocessReportAPI(getState().auth, appId, reportId)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //If Any
            },
            callbacks: {
                successWillDispatch: (dispatch, data) => {
                    if(data){
                        dispatch(getPreprocessList(appId));
                    }
                }
            }
        });
    };
};


export const updatePreprocessConfig = (appId, config) => {
    return (dispatch, getState) => {
        return dispatch({
            type: UPDATE_PREPROCESS_CONFIG,
            payload: config,
            meta: {
                //If Any
            }
        });
    };
};
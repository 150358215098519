/**
 * Created by jyothi on 1/8/17.
 */
import {
    REPORT_FAILED, REPORT_SUCCESS, REPORT_PENDING,
    EDIT_REPORT_PENDING, EDIT_REPORT_SUCCESS, EDIT_REPORT_FAILED
} from './actionTypes';
import {RESET_ALL} from '../../../../../../../../constants/actionTypes';

const INITIAL_STATE ={
    report: {},
    ...REPORT_INITIAL_FLAG_STATE,
    ...EDIT_REPORT_INITIAL_FLAG_STATE
};

const REPORT_INITIAL_FLAG_STATE = {
    report_pending: false,
    report_failed: false
};

const EDIT_REPORT_INITIAL_FLAG_STATE = {
    edit_report_pending: false,
    edit_report_failed: false,
    edit_report_success: false
};

function reportsReducer(state = INITIAL_STATE, action = {}) {
    switch (action.type) {

        case REPORT_PENDING:
            return{
                ...state,
                ...REPORT_INITIAL_FLAG_STATE,
                report_pending: true
            };

        case REPORT_SUCCESS:
            return{
                ...state,
                ...REPORT_INITIAL_FLAG_STATE,
                report: action.payload
            };

        case REPORT_FAILED:
            return{
                ...state,
                ...REPORT_INITIAL_FLAG_STATE,
                report_failed: true
            };

        case EDIT_REPORT_PENDING:
            return{
                ...state,
                ...EDIT_REPORT_INITIAL_FLAG_STATE,
                edit_report_pending: true
            };

        case EDIT_REPORT_SUCCESS:
            return{
                ...state,
                ...EDIT_REPORT_INITIAL_FLAG_STATE,
                edit_report_success: true
            };

        case EDIT_REPORT_FAILED:
            return{
                ...state,
                ...EDIT_REPORT_INITIAL_FLAG_STATE,
                edit_report_failed: true
            };

        case RESET_ALL:
            return INITIAL_STATE;

        default:
            return state;
    }
}

export default reportsReducer;
/**
 * Created by jyothi on 22/8/17.
 */
import moment from 'moment';
import {
    blue500, orange500, red500, purple500, green500, amber500,
    lightBlue500, lightGreen500, teal500, grey500, blueGrey500
} from 'material-ui/styles/colors';
/**
 * checks whether a value is defined
 * @param value {*}
 * @param strict {Boolean}
 * @returns {Boolean}
 */
export function isDefined(value, strict = true){
    if(!strict && (value === 0 || value === "")) return true; //FIXME: handling 0 values
    return value && value !== null && typeof value !== 'undefined';
}

/**
 *
 * @param timestamp
 * @param format
 * @param defaultValue
 * @returns {*}
 */
export function formatTime(timestamp, format = "MMM Do YYYY, h:mm:ss a", defaultValue = "NA"){
    if(!isDefined(timestamp)) return defaultValue;
    return moment(timestamp).format(format);
}

/**
 * returns URL with appId and userId as QueryStrings
 * @param url
 * @param auth
 * @param appId
 * @returns {string}
 */
export function makeDefaultQueryString(url, auth={user: {email: "master@apxor.com"}}, appId){
    let withQP = `${url}?customerId=${auth.user.email}`;
    if(isDefined(appId)){
        withQP += `&appId=${changeForDemoApp(url, appId)}`;
    }
    return withQP;
}

const MUTATION_URL_REGEX = /messages|art-configs|notifications|additional-customers/;

export const DEMO_APP_ID = window.demoAppId;
export const APP_ID_FOR_DEMO = window.appIdForDemo;

export const changeForDemoApp = (url = "", appId) => {
    if(appId === DEMO_APP_ID && !MUTATION_URL_REGEX.test(url)){
        return APP_ID_FOR_DEMO;
    }else{
        return appId;
    }
};

/**
 *
 * @param variable
 * @returns {boolean}
 */
function isString(variable){
    return isDefined(variable) && typeof variable === 'string';
}
/**
 *
 * @param variable
 * @returns {boolean}
 */
function isArray(variable) {
    return isDefined(variable) && Array.isArray(variable);
}

/**
 *
 * @param variable
 * @returns {*|boolean|boolean}
 */
function isNumber(variable) {
    return isDefined(variable, false) && !isNaN(variable);
}


/**
 * make query Strings for API
 * @param queryObject
 * @returns {string}
 */
export function withQueryStrings(queryObject = {}){
    let queryStrings = "";
    if(isDefined(queryObject, false)) {
        for (let query in queryObject) {
            if (queryObject.hasOwnProperty(query)) {
                if (isArray(queryObject[query])) {
                    queryStrings += queryObject[query].map(item => `&${query}=${item}`).join('');
                } else if (isString(queryObject[query]) || isNumber(queryObject[query])) {
                    queryStrings += `&${query}=${queryObject[query]}`;
                } else { //FIXME: Not sure of this
                    queryStrings += withQueryStrings(queryObject[query]);
                }
            }
        }
    }else{
        //logger.debug("Undefined Keys", queryObject);
    }
    return queryStrings;
}

const getPercent = (total, value) => {
    if(isNaN(value) || isNaN(total)) return 0;
    return Number(parseFloat(value * 100 / total).toFixed(2));
};

export const KEYS = {
    event_count: 'event_count',
    event_done_users: 'event_done_users',
    atleast_event_done_users: 'atleast_event_done_users',
    retained_users: 'retained_users',
    atleast_retained_users: 'atleast_retained_users',
    retained_percent: 'retained_percent',
    atleast_retained_percent: 'atleast_retained_percent'
};

const {
    event_count, event_done_users, retained_users, retained_percent,
    atleast_event_done_users, atleast_retained_users, atleast_retained_percent
} = KEYS;

export const formatData = (data = []) => {
    const withPercent = (d, total) => {
        let agg = {
            event_done_users: 0,
            retained_users: 0
        };
        const rest = d.slice(1).map((o, index) => {
            agg[event_done_users] += o[event_done_users];
            agg[retained_users] += o[retained_users];
            const atleast_event_done_users = o[event_done_users] + total[event_done_users] - agg[event_done_users];
            const atleast_retained_users = o[retained_users] + total[retained_users] - agg[retained_users];
            return {
                ...o,
                retained_percent: getPercent(o[event_done_users], o[retained_users]),
                atleast_event_done_users,
                atleast_retained_users,
                atleast_retained_percent: getPercent(atleast_event_done_users, atleast_retained_users)
            };
        });
        return [
            {
                ...d[0],
                retained_percent: getPercent(d[0][event_done_users], d[0][retained_users])
            },
            ...rest
        ];
    };
    if(Array.isArray(data) && data.length > 0){
        const sum = data.slice(1).reduce((a, b) => {
            a[retained_users] += b[retained_users];
            a[event_done_users] += b[event_done_users];
            return a;
        }, {event_done_users: 0, retained_users: 0});
        return withPercent(data, sum);
    }else {
        return [];
    }
};

export const formattedProcessedData = (data = {}) => {
    return Object.keys(data).reduce((a, b) => {
        a[b] = formatData(data[b]);
        return a;
    }, {});
};

export const formatDataWithLimit = (data = [], limit = 50, isAtleast = false) => {
    if(Array.isArray(data) && data.length > 0){
        if(isAtleast){
            return data.slice(0, limit);
        }
        const sum = data.slice(1).reduce((a, b) => {
            a[retained_users] += b[retained_users];
            a[event_done_users] += b[event_done_users];
            return a;
        }, {event_done_users: 0, retained_users: 0});
        const roundedSum = data.slice(1, limit + 1).reduce((a, b) => {
            a[retained_users] += b[retained_users];
            a[event_done_users] += b[event_done_users];
            return a;
        }, {event_done_users: 0, retained_users: 0});
        const aggregatedTerm = {
            event_count: `${limit}+`,
            event_done_users:  sum[event_done_users] - roundedSum[event_done_users],
            retained_users: sum[retained_users] - roundedSum[retained_users]
        };
        return [
            ...data.slice(0, limit),
            {
                ...aggregatedTerm,
                retained_percent: getPercent(aggregatedTerm.event_done_users, aggregatedTerm.retained_users)
            }
        ]
    }else {
        return [];
    }
};

export const formattedProcessedDataWithLimit = (data = {}, limit = 50) => {
    return Object.keys(data).reduce((a, b) => {
        a[b] = formatData(data[b]);
        return a;
    }, {});
};

const COLORS = [
    blue500, orange500, red500, purple500, green500, amber500,
    lightBlue500, lightGreen500, teal500, grey500, blueGrey500
];

export function randomColorWithIndex(index){
    return COLORS[index % COLORS.length];
}
/**
 * Created by jyothi on 1/8/17.
 */
import {
    blue200, blue300, lightWhite, white, blue900, blue600,
    cyan800, blue700
} from 'material-ui/styles/colors';
import React from 'react';
export const subHeaderStyles = {
    backgroundColor: blue700,
    color: white,
    fontSize: '18px'
};

export const headerStyles = {
    backgroundColor: blue900,
    //fontSize: '18px'
};

export const toolbarTitleStyles = {
    color: white,
    fontSize: '14px'
};

export const REPORT_PENDING = "REPORT_PENDING";
export const REPORT_SUCCESS = "REPORT_SUCCESS";
export const REPORT_FAILED = "REPORT_FAILED";

export const EDIT_REPORT_PENDING = "EDIT_REPORT_PENDING";
export const EDIT_REPORT_SUCCESS = "EDIT_REPORT_SUCCESS";
export const EDIT_REPORT_FAILED = "EDIT_REPORT_FAILED";
/**
 * Created by jyothi on 22/8/17.
 */
import { callApi } from '../../../../../api';
import { makeDefaultQueryString } from '../../../../../utils';

const APPS_API = "apps";

export function getApps(auth) {
    const url = makeDefaultQueryString(APPS_API, auth);
    const config = {
        method: "GET",
        auth: auth
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };

    return callApi(url, config, onSuccessCallback, onFailureCallback);
}
/**
 * Created by jyothi on 1/8/17.
 */
import React from 'react';
import ReactGA from 'react-ga';
import {Toolbar, ToolbarGroup, ToolbarSeparator, ToolbarTitle} from 'material-ui/Toolbar';
import Paper from 'material-ui/Paper';
import {
    LineChart, Line, XAxis, YAxis, ZAxis,
    PieChart, Pie, Sector, Cell, ResponsiveContainer,
    Legend, Tooltip, ScatterChart, Scatter, CartesianGrid,
    BarChart, Bar, ReferenceLine, Brush
} from 'recharts';
import {Card, CardActions, CardHeader, CardMedia, CardTitle, CardText} from 'material-ui/Card';
import Avatar from 'material-ui/Avatar';
import { Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn } from 'material-ui/Table';
import ArrowDropDown from 'material-ui/svg-icons/navigation/arrow-drop-down';
import ArrowDropUp from 'material-ui/svg-icons/navigation/arrow-drop-up';
import LightBulb from 'material-ui/svg-icons/action/lightbulb-outline';
import Widgets from 'material-ui/svg-icons/device/widgets';
import {
    white, lightWhite, darkWhite,
    greenA200, red500, blue300,
    blue200, blue500, orange500,
    blue900, blueA100, darkBlack,
    cyan500, deepOrange500, purple500,
    red900, red800, red700,
    green500, green900, green700
} from 'material-ui/styles/colors';
import {BottomNavigation, BottomNavigationItem} from 'material-ui/BottomNavigation';
import ApxorSankeyGraph from '../../../../../../../../../components/plugins/ApxorSankeyGraph/index';
import {List, ListItem} from 'material-ui/List';
import Group from 'material-ui/svg-icons/social/group';
import GroupAdd from 'material-ui/svg-icons/social/group-add';
import AccessTime from 'material-ui/svg-icons/device/access-time';
import SentimentVeryDissatisfied from 'material-ui/svg-icons/social/sentiment-very-dissatisfied';
import SentimentDissatisfied from 'material-ui/svg-icons/social/sentiment-dissatisfied';
import SentimentSatisfied from 'material-ui/svg-icons/social/sentiment-satisfied';
import BusinessIcon from 'material-ui/svg-icons/communication/business';
import MultilineChart from 'material-ui/svg-icons/editor/multiline-chart';
import Subheader from 'material-ui/Subheader';
import { headerStyles, subHeaderStyles, toolbarTitleStyles } from '../actionTypes';
import DatePicker from 'material-ui/DatePicker';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import Checkbox from 'material-ui/Checkbox';
import {PathGraph} from "../PathGraph";

const COLORS = [ red500, green500, blue500, orange500, purple500, cyan500, greenA200, deepOrange500 ];

const getColorWithIndex = (index) => COLORS[ index % COLORS.length ];

export const Header = (props) => {
    const {
        app, title, dates,
        enableDateFilter = false, enableGroupFilter = false, enableVersionFilter = false,
        isSingleDate = false,
        validDates, groupFilters, versionFilters,
        handleGroupFilter, handleDateFilter, handleVersionFilter,
        currentGroup
    } = props;
    return (
        <Toolbar style={headerStyles}>
            <ToolbarGroup>
                <ToolbarTitle style={{...toolbarTitleStyles}} text={app}/>
            </ToolbarGroup>
            <ToolbarGroup>
                <ToolbarTitle style={{...toolbarTitleStyles, textTransform: 'uppercase', fontSize: '16px'}}
                              text={title}/>
            </ToolbarGroup>
            <ToolbarGroup>
                {
                    enableGroupFilter && groupFilters && groupFilters.length > 0 &&
                    <SelectField
                        floatingLabelText="User Group"
                        value={currentGroup}
                        onChange={(e, index, value) => {handleGroupFilter(value)}}
                        style={{maxWidth: 150}}
                        labelStyle={{color: darkWhite}}
                        floatingLabelStyle={{color: darkWhite}}
                    >
                        {
                            groupFilters.map((group, i) => <MenuItem key={group.key} value={group.value} primaryText={group.key} />)
                        }
                    </SelectField>
                }
                {
                    enableVersionFilter && versionFilters && versionFilters.length > 0 &&
                    <SelectField
                        floatingLabelText="Version"
                        value={versionFilters[0]}
                        onChange={() => {}}
                        style={{maxWidth: 150}}
                        labelStyle={{color: darkWhite}}
                        floatingLabelStyle={{color: darkWhite}}
                    >
                        {
                            versionFilters.map((version, i) => <MenuItem key={version} value={version} primaryText={version} />)
                        }
                    </SelectField>
                }
                { dates && !enableDateFilter && <ToolbarTitle style={{...toolbarTitleStyles}} text={dates}/>}
                {
                    enableDateFilter && <DatePicker
                        hintText={<span style={{color: lightWhite}}>Start Date</span>}
                        container="inline"
                        inputStyle={{color: darkWhite}}
                        underlineShow={true}
                        style={{maxWidth: 120, color: darkWhite}}
                        textFieldStyle={{maxWidth: 120}}
                    />
                }
                {
                    !isSingleDate && enableDateFilter && <DatePicker
                        hintText={<span style={{color: lightWhite}}>End Date</span>}
                        container="inline"
                        inputStyle={{color: darkWhite}}
                        underlineShow={true}
                        style={{maxWidth: 120, color: darkWhite}}
                        textFieldStyle={{maxWidth: 120}}
                    />
                }
            </ToolbarGroup>
        </Toolbar>
    );
};

const labelForStats = (label, count) => (
    <div>
        <span style={{fontSize: 20, color: darkBlack}}>{count}</span> <br/>
        <span>{label}</span>
    </div>
);

const iconStyles = {
    height: '40px'
};

const bottomNavigationStyles = {
    WebkitJustifyContent: 'space-around',
    justifyContent: 'space-around'
};

const colorsForStats = [green500, blue500, orange500, red500, purple500, cyan500];

export class Stats extends React.Component {

    render(){
        const data = this.props.data || [];
        return(
            <Paper style={paperStyles} zDepth={1}>
                <BottomNavigation style={{minHeight: '120px', ...bottomNavigationStyles}}>
                {
                    data.map((item, i) =>
                        <BottomNavigationItem
                            key={item.key}
                            label={labelForStats(item.name, item.value)}
                            icon={<GroupAdd style={iconStyles} color={colorsForStats[i]} />}
                        />
                    )
                }
                </BottomNavigation>
            </Paper>
        )
    }

}

const paperStyles = {
    width: '100%',
    minHeight: '100px'
};

const labelColor = "#000000";

const donutLabelStyles = {
    fontSize: '12px'
};

const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
        fill, payload, percent, value, isForUninstall, totalUsers } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
        <g style={donutLabelStyles}>
            <text style={{fontWeight: 700, fontSize: 16}} x={cx} y={cy} dy={-30} textAnchor="middle" fill={labelColor}>{payload.name}</text>
            <text style={{fontWeight: 700, fontSize: 16}} x={cx} y={cy} dy={0} textAnchor="middle" fill={fill}>{payload.newinstalls + ` (${(percent * 100).toFixed(2)}%)`}</text>
            { payload.day0uninstalls && <text
                style={{fontWeight: 600, fontSize: 12}}
                x={cx}
                y={cy}
                dy={30}
                textAnchor="middle"
                fill={isForUninstall ? red500 : green500}>
                {`${payload.day0uninstalls} (${(payload.day0uninstalls / (isForUninstall ? payload.newinstalls : totalUsers) * 100).toFixed(2)}%)`}
                </text>
            }
            {/*<text x={cx} y={cy} dy={30} textAnchor="middle" fill={labelColor}>{`(${(percent * 100).toFixed(2)}%)`}</text>*/}
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
            />
        </g>
    );
};

export class Donut extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            activeIndex: 0
        }
    }

    onPieEnter = (data, index) => {
        this.setState({activeIndex: index});
        ReactGA.event({
            category: 'Pie Chart View',
            action: 'Hovered on ',
            label: data.name
        });
    };

    render () {
        const { height, data, subData, title, nameKey, isForUninstall, totalUsers } = this.props;
        return (
            <ResponsiveContainer height={height}>
                <PieChart>
                    <Pie nameKey={nameKey || "name"}
                         activeIndex={this.state.activeIndex}
                         activeShape={(payload) => renderActiveShape({...payload, isForUninstall, totalUsers})}
                         data={ data }
                         onMouseEnter={this.onPieEnter}
                         dataKey={"newinstalls"}
                         innerRadius={60}
                         outerRadius={75}
                         valueKey={"newinstalls"}
                         fill="#8884d8">
                        {
                            data.map((entry, index) => <Cell key={entry + index} fill={COLORS[index % COLORS.length]}/>)
                        }
                    </Pie>
                </PieChart>
            </ResponsiveContainer>
        );
    }
}

export class Distributions extends React.Component {

    render(){
        const { data = [], isForUninstall =  false, stats = {}} = this.props || {};
        return(
            <Paper style={paperStyles} zDepth={1}>
                <div style={{minHeight: '230px', padding: 10, display: 'flex', flexWrap: "wrap", textAlign: 'center', alignContent: "space-between", alignItems: "center",justifyContent: "space-between"}}>
                    {
                        data.map(item =>
                            <div key={"distributions" + item.name} style={{minHeight:'200px', minWidth: '200px'}}>
                                <Donut data={item.data} height={200} isForUninstall={isForUninstall} totalUsers={stats.users}/>
                                <p style={{fontSize: 14, margin: 0}}>{item.name}</p>
                            </div>
                        )
                    }
                </div>
            </Paper>
        )
    }

}

const chipStyles = {
    marginTop: '15px',
    fontSize: '18px',
    marginRight: '20px'
};

export class Turnarounds extends React.Component { //FIXME: name not fixed

    render(){
        const { attention = {data: []}, success = {data: []}, failure = {data: []} } = this.props || {};
        return(
            <Paper style={paperStyles} zDepth={1}>
                <BottomNavigation style={{height: 'auto', ...bottomNavigationStyles}}>
                    <BottomNavigationItem
                        label={
                            <List>
                                <Subheader style={{color: purple500}}>{attention.name || "Attention Span"}</Subheader>
                                {
                                    attention.data.map((item, i) =>
                                        <ListItem
                                            key={"threshold" + i}
                                            primaryText={item.text}
                                            secondaryText={item.description || null}
                                            /*leftAvatar={<Avatar backgroundColor={blue200}>{i + 1}</Avatar>}*/
                                            rightIconButton={<span style={chipStyles}>{item.value}</span>}
                                            disabled={true}
                                            style={{color: blue900, borderBottom: (i + 1 < attention.length) ? '1px solid #CCC' : null, textAlign: 'left'}}
                                        />
                                    )
                                }
                            </List>
                        }
                        icon={<span/>}
                        style={{maxWidth: '40%'}}
                    />
                    <BottomNavigationItem
                        label={
                            <List>
                                <Subheader style={{color: green700}}>{success.name || "Success"}</Subheader>
                                {
                                    success.data.map((item, i) =>
                                        <ListItem
                                            key={"success" + i}
                                            primaryText={item.text}
                                            secondaryText={item.description || null}
                                            rightIconButton={<span style={chipStyles}>{item.value}</span>}
                                            disabled={true}
                                            style={{color: blue900, borderBottom: (i + 1 < success.length) ? '1px solid #CCC' : null, textAlign: 'left'}}
                                        />
                                    )
                                }
                            </List>
                        }
                        icon={<span/>}
                        style={{maxWidth: '30%', borderLeft: '1px solid #CCC'}}
                    />
                    <BottomNavigationItem
                        label={
                            <List>
                                <Subheader style={{color: red700}}>{failure.name || "Failure"}</Subheader>
                                {
                                    failure.data.map((item, i) =>
                                        <ListItem
                                            key={"failure" + i}
                                            primaryText={item.text}
                                            secondaryText={item.description || null}
                                            rightIconButton={<span style={chipStyles}>{item.value}</span>}
                                            disabled={true}
                                            style={{color: blue900, borderBottom: (i + 1 < failure.length) ? '1px solid #CCC' : null, textAlign: 'left'}}
                                        />
                                    )
                                }
                            </List>
                        }
                        icon={<span/>}
                        style={{maxWidth: '30%', borderLeft: '1px solid #CCC'}}
                    />
                </BottomNavigation>
            </Paper>
        )
    }

}

const MetricColumn = (props) => (
    <BottomNavigationItem
        style={props.style || {}}
        label={<span style={{color: blue900, fontSize: 14, fontWeight: 500, ...props.style}}>{props.data || "NA"}</span>}
        icon={props.icon || <span/>}
    />
);

const getPercent = (total, value) => {
    if(isNaN(value) || isNaN(total)) return "NA";
    return parseFloat(value * 100 / total).toFixed(2);
};

const MetricRow = (data) => {
    const { total, metric, active, inactive, activeTotal, inactiveTotal, totalPercent } = data;
    const d0UninstallPercent = getPercent(total, inactiveTotal);
    return (
        <BottomNavigation style={{maxHeight: '40px', ...bottomNavigationStyles}}>
            <MetricColumn data={`${metric} (${total})`} style={{minWidth: 250}}/>
            <MetricColumn data={<span style={d0UninstallPercent > totalPercent ? {color: red500 } : {color: green500}}>{`${d0UninstallPercent}%`}</span>}/>
            <MetricColumn data={`${active} (${activeTotal})`}/>
            <MetricColumn data={`${inactive} (${inactiveTotal})`}/>
        </BottomNavigation>
    );
};

const defaultHeaders = [
    "Metrics",
    "Compared to D0",
    "Retained",
    "Uninstalled"
];

export class UsageMetrics extends React.Component { //FIXME: name not fixed

    render(){
        const {data = [ ], stats = {}, headers = defaultHeaders } = this.props;
        const totalUninstallPercent = getPercent(stats.users, stats.day0uninstalls || stats.uninstalls);
        return(
            <Paper style={paperStyles} zDepth={1}>
                <Table selectable={true}>
                    <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
                        <TableRow>
                            <TableHeaderColumn>
                                <BottomNavigation style={{minHeight: '60px', ...bottomNavigationStyles}}>
                                    <BottomNavigationItem
                                        label={headers[0]}
                                        icon={<BusinessIcon color={cyan500} />}
                                        style={{minWidth: 250}}
                                    />
                                    <BottomNavigationItem
                                        label={`${headers[1]} (${totalUninstallPercent})`}
                                        icon={<MultilineChart color={purple500}/>}
                                    />
                                    <BottomNavigationItem
                                        label={headers[2]}
                                        icon={<SentimentSatisfied color={green700}/>}
                                    />
                                    <BottomNavigationItem
                                        label={headers[3]}
                                        icon={<SentimentVeryDissatisfied color={red500}/>}
                                    />
                                </BottomNavigation>
                            </TableHeaderColumn>
                        </TableRow>
                    </TableHeader>
                    <TableBody displayRowCheckbox={false}>
                        {
                            data.map((item, i) =>
                                <TableRow key={"row" + i}>
                                    <Card expandable={true} onExpandChange={(state) => {
                                        ReactGA.event({
                                            category: 'Usage Metrics Dropdown',
                                            action: state ? 'Opened' : 'Closed',
                                            label: item.metric
                                        });
                                    }}>
                                        <CardHeader
                                            title={<MetricRow {...item} totalPercent={Number(totalUninstallPercent)}/>}
                                            actAsExpander={true}
                                            textStyle={{width: '100%'}}
                                            showExpandableButton={true}
                                        />
                                        <CardText expandable={true}>
                                            <UsageMetricScatterTrends data={item.distribution} height={250} />
                                        </CardText>
                                    </Card>
                                </TableRow>
                            )
                        }
                    </TableBody>
                </Table>
            </Paper>
        )
    }

}

export class KPIs extends React.Component { //FIXME: name not fixed

    render(){
        const {
            data = [ ], stats = {},
        } = this.props;
        const totalD0UninstallPercent = getPercent(stats.users, stats.day0uninstalls);
        return(
            <Paper style={paperStyles} zDepth={1}>
                <Table selectable={true}>
                    <TableBody displayRowCheckbox={false}>
                        {
                            data.map((item, i) => {
                                const { metric, xKey, yKeys = [], xLabel, yLabel, distribution } = item;
                                return <TableRow key={"row" + i}>
                                    <Card expandable={true} onExpandChange={(state) => {
                                        ReactGA.event({
                                            category: 'KPI Dropdown',
                                            action: state ? 'Opened' : 'Closed',
                                            label: metric
                                        });
                                    }}>
                                        <CardHeader
                                            title={
                                                <BottomNavigation
                                                    style={{maxHeight: '40px', ...bottomNavigationStyles}}>
                                                    <MetricColumn data={metric} style={{fontSize: 18}}/>
                                                </BottomNavigation>
                                            }
                                            actAsExpander={true}
                                            textStyle={{width: '100%'}}
                                            showExpandableButton={true}
                                        />
                                        <CardText expandable={true}>
                                            <ResponsiveContainer height={250}>
                                                <LineChart data={distribution}
                                                           margin={{top: 5, right: 30, left: 20, bottom: 5}}>
                                                    <XAxis dataKey={xKey} name={xLabel} label={xLabel ? {
                                                        value: xLabel,
                                                        position: "insideBottomRight",
                                                        dy: -8
                                                    } : undefined}/>
                                                    <YAxis name={yLabel} label={yLabel ? {
                                                        value: yLabel,
                                                        position: "insideLeft",
                                                        angle: -90,
                                                        dy: 40
                                                    } : undefined}/>
                                                    <CartesianGrid strokeDasharray="3 3"/>
                                                    <Tooltip content={<SuperCustomToolTip />}/>
                                                    <Legend/>
                                                    {yKeys.map((key, i) => key.includes("percent") && <Line key={key} type="monotone" dataKey={key}
                                                                                 stroke={getColorWithIndex(i)}/>)}
                                                </LineChart>
                                            </ResponsiveContainer>
                                        </CardText>
                                    </Card>
                                </TableRow>
                            })
                        }
                    </TableBody>
                </Table>
            </Paper>
        )
    }

}

const CustomizedDot = () => {
    const {cx, cy, stroke, payload, value} = this.props;
    if (value > 2500) {
        return (
            <svg x={cx - 10} y={cy - 10} width={20} height={20} fill="red" viewBox="0 0 1024 1024">
                <path d="M512 1009.984c-274.912 0-497.76-222.848-497.76-497.76s222.848-497.76 497.76-497.76c274.912 0 497.76 222.848 497.76 497.76s-222.848 497.76-497.76 497.76zM340.768 295.936c-39.488 0-71.52 32.8-71.52 73.248s32.032 73.248 71.52 73.248c39.488 0 71.52-32.8 71.52-73.248s-32.032-73.248-71.52-73.248zM686.176 296.704c-39.488 0-71.52 32.8-71.52 73.248s32.032 73.248 71.52 73.248c39.488 0 71.52-32.8 71.52-73.248s-32.032-73.248-71.52-73.248zM772.928 555.392c-18.752-8.864-40.928-0.576-49.632 18.528-40.224 88.576-120.256 143.552-208.832 143.552-85.952 0-164.864-52.64-205.952-137.376-9.184-18.912-31.648-26.592-50.08-17.28-18.464 9.408-21.216 21.472-15.936 32.64 52.8 111.424 155.232 186.784 269.76 186.784 117.984 0 217.12-70.944 269.76-186.784 8.672-19.136 9.568-31.2-9.12-40.096z"/>
            </svg>
        );
    }
    return (
        <svg x={cx - 10} y={cy - 10} width={20} height={20} fill="green" viewBox="0 0 1024 1024">
            <path d="M517.12 53.248q95.232 0 179.2 36.352t145.92 98.304 98.304 145.92 36.352 179.2-36.352 179.2-98.304 145.92-145.92 98.304-179.2 36.352-179.2-36.352-145.92-98.304-98.304-145.92-36.352-179.2 36.352-179.2 98.304-145.92 145.92-98.304 179.2-36.352zM663.552 261.12q-15.36 0-28.16 6.656t-23.04 18.432-15.872 27.648-5.632 33.28q0 35.84 21.504 61.44t51.2 25.6 51.2-25.6 21.504-61.44q0-17.408-5.632-33.28t-15.872-27.648-23.04-18.432-28.16-6.656zM373.76 261.12q-29.696 0-50.688 25.088t-20.992 60.928 20.992 61.44 50.688 25.6 50.176-25.6 20.48-61.44-20.48-60.928-50.176-25.088zM520.192 602.112q-51.2 0-97.28 9.728t-82.944 27.648-62.464 41.472-35.84 51.2q-1.024 1.024-1.024 2.048-1.024 3.072-1.024 8.704t2.56 11.776 7.168 11.264 12.8 6.144q25.6-27.648 62.464-50.176 31.744-19.456 79.36-35.328t114.176-15.872q67.584 0 116.736 15.872t81.92 35.328q37.888 22.528 63.488 50.176 17.408-5.12 19.968-18.944t0.512-18.944-3.072-7.168-1.024-3.072q-26.624-55.296-100.352-88.576t-176.128-33.28z"/>
        </svg>
    );
};

class CustomToolTip extends React.Component{
    render(){
        const {active, units, names, payload, label, valueLabel} = this.props;
        return(
            <div className="recharts-default-tooltip" style={{margin: "0px", padding: "10px", backgroundColor: 'rgba(0, 0, 0, 0.1)', border: "1px solid rgb(204, 204, 204)", whiteSpace: "nowrap"}}>
                <p className="recharts-tooltip-label" style={{margin: "0px"}}>{ label }</p>
                <p className="recharts-tooltip-label" style={{margin: "0px"}}>{ `Users : ${payload.length > 0 && payload[0].payload.total_user_count}` }</p>
                <ul className="recharts-tooltip-item-list" style={{padding: "0px", margin: "0px"}}>
                    {
                        payload.map((item, index) => {
                            return(
                                <li className="recharts-tooltip-item" key={`li${index}`} style={{display: "block", paddingTop: "4px", paddingBottom: "4px", color: red500}}>
                                    <span className="recharts-tooltip-item-name">Uninstalls</span>
                                    <span className="recharts-tooltip-item-separator"> : </span>
                                    <span className="recharts-tooltip-item-value">{`${Math.ceil(item.value * payload[0].payload.total_user_count / 100)} (${item.value})`}</span>
                                    <span className="recharts-tooltip-item-unit"> %</span>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
        )
    }
}

const toTitleCase = (word = "") => word.trim().replace(/\b[a-z]/g, l => l.toUpperCase());

const labelFormatter = (label = "") => label.split("_").map(word => toTitleCase(word)).join(" ");

const renderPercent = (item = {}) => {
    const percentKey = item.name + "_percent";
    if(Object.keys(item.payload).some(o => o.includes(percentKey))){
        return <span>({item.payload[percentKey]} %)</span>
    }else{
        return <span/>
    }
};

const renderValueAfterPercent = (item = {}) => {
    const valueKey = item.name.replace("_percent", "");
    if(Object.keys(item.payload).some(o => o.includes(valueKey))){
        return <span>% ({item.payload[valueKey]})</span>
    }else{
        return <span/>
    }
};

export class SuperCustomToolTip extends React.Component{
    render(){
        const {
            active, units, names, payload, label, valueLabel
        } = this.props;
        if(active && payload.length > 0) {
            return (
                <div className="recharts-default-tooltip" style={{margin: "0px", padding: "10px", backgroundColor: 'rgba(255, 255, 255, 0.5)', border: "1px solid rgb(204, 204, 204)", whiteSpace: "nowrap"}}>
                    <p className="recharts-tooltip-label" style={{margin: "0px"}}>{ label }</p>
                    {/*<p className="recharts-tooltip-label" style={{margin: "0px"}}>{ payload.value }</p>*/}
                    <ul className="recharts-tooltip-item-list" style={{padding: "0px", margin: "0px"}}>
                        {
                            payload.sort((a, b) => b.value - a.value).map((item, index) =>
                                <li className="recharts-tooltip-item" key={`li${index}`} style={{display: "block", paddingTop: "4px", paddingBottom: "4px", color: item.color}}>
                                    <span className="recharts-tooltip-item-name">{labelFormatter(item.name)}</span>
                                    <span className="recharts-tooltip-item-separator"> : </span>
                                    <span className="recharts-tooltip-item-value">{item.value} {renderValueAfterPercent(item)}</span>
                                    {/*<span className="recharts-tooltip-item-unit"> {item.unit}</span>*/}
                                </li>
                            )
                        }
                    </ul>
                </div>
            );
        }else{
            return <span/>
        }
    }
}

class UsageMetricTrends extends React.Component { //FIXME: name not fixed

    render(){
        const {data = [], height } = this.props;
        return(
            <ResponsiveContainer height={height}>
                <LineChart data={data}
                           margin={{top: 5, right: 30, left: 20, bottom: 5}}>
                    <XAxis dataKey="_id"/>
                    <YAxis/>
                    <CartesianGrid strokeDasharray="3 3"/>
                    <Tooltip content={<CustomToolTip {...this.props}/>} />
                    <Legend />
                    <Line type="monotone" dataKey="day0_uninstall_count" stroke={red500} />
                    {/*<Line type="monotone" dataKey="day0_retain_percent" stroke={green500}/>*/}
                </LineChart>
            </ResponsiveContainer>
        )
    }

}

function tooltipLabelRender(props){
    const {name, payload} = props;
    if(name === "percent") return 'Uninstalls';
    else if(name === "uninstalls") return "Total Users";
    else return name;
}

function tooltipValueRender(props){
    const {name, payload, value} = props;
    if(name === "percent") return `${value} % (${payload.day0_uninstall_count})`;
    else if(name === "uninstalls") return payload.total_user_count;
    else return value;
}

export class CustomScatterToolTip extends React.Component{
    render(){
        const {active, units, names, payload, label, valueLabel} = this.props;
        return(
            <div className="recharts-default-tooltip" style={{margin: "0px", padding: "10px", backgroundColor: 'rgba(255, 255, 255, 0.5)', border: "1px solid rgb(204, 204, 204)", whiteSpace: "nowrap"}}>
                <p className="recharts-tooltip-label" style={{margin: "0px"}}>{ payload.name }</p>
                <p className="recharts-tooltip-label" style={{margin: "0px"}}>{ payload.value }</p>
                <ul className="recharts-tooltip-item-list" style={{padding: "0px", margin: "0px"}}>
                    {
                        payload.sort((a, b) => b.value - a.value).map((item, index) => {
                            return(
                                <li className="recharts-tooltip-item" key={`li${index}`} style={{display: "block", paddingTop: "4px", paddingBottom: "4px", color: (item.name === "percent" ? red500 : blue900)}}>
                                    <span className="recharts-tooltip-item-name">{tooltipLabelRender(item)}</span>
                                    <span className="recharts-tooltip-item-separator"> : </span>
                                    <span className="recharts-tooltip-item-value">{tooltipValueRender(item)}</span>
                                    {/*<span className="recharts-tooltip-item-unit"> {item.unit}</span>*/}
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
        )
    }
}

class UsageMetricScatterTrends extends React.Component { //FIXME: name not fixed

    render(){
        const {data = [], height } = this.props;
        const dataWithPercentages = data.map(el => ({...el, day0_uninstall_percent: parseFloat(getPercent(el.total_user_count, el.day0_uninstall_count))}));
        return <ScatterTrends
            height={250}
            data={dataWithPercentages}
            xKey="_id"
            yKey="day0_uninstall_percent"
            zKey="day0_uninstall_count"
            scatterLabel="D0 Uninstalls"
            xLabel="Range"
            yLabel="percent"
            zLabel="Uninstalls"
        />
    }

}

export class ScatterTrends extends React.Component { //FIXME: name not fixed

    render(){
        const {
            data = [], height = 250,
            xKey, yKey, zKey, scatterLabel,
            xLabel, yLabel, zLabel
        } = this.props;
        return(
            <ResponsiveContainer height={height}>
                <ScatterChart margin={{top: 20, right: 20, bottom: 20, left: 20}} data={data}>
                    <XAxis dataKey={xKey} name={xLabel} label={xLabel ? { value: xLabel, position: "insideBottomRight", dy: -28} : undefined}/>
                    <YAxis dataKey={yKey} name={yLabel} label={yLabel ? { value: yLabel, position: "insideLeft", angle: -90,   dy: 40} : undefined}/>
                    <ZAxis dataKey={zKey} range={[10, 3000]} name={zLabel}/>
                    <CartesianGrid />
                    <ReferenceLine y={0} stroke='#000'/>
                    {
                        data.length > 9 &&
                        <Brush
                            dataKey={zKey}
                            height={30}
                            stroke="#429ef4"
                            endIndex={9}
                        />
                    }
                    <Tooltip label={""} cursor={{strokeDasharray: '3 3'}} content={<SuperCustomToolTip {...this.props}/>} />
                    <Legend/>
                    <Scatter name={scatterLabel} fill='#8884d8' shape="circle"/>
                </ScatterChart>
            </ResponsiveContainer>
        )
    }

}

export class MultipleScatterTrends extends React.Component { //FIXME: name not fixed

    render(){
        const {
            data = [], height = 400,
            xKey, yKey, zKey, scatterLabels = [],
            xLabel, yLabel, zLabel
        } = this.props;
        return(
            <ResponsiveContainer height={height}>
                <ScatterChart margin={{top: 20, right: 20, bottom: 20, left: 20}}>
                    <XAxis dataKey={xKey} name={xLabel} label={xLabel ? { value: xLabel, position: "insideBottomRight", dy: -28} : undefined}/>
                    <YAxis dataKey={yKey} name={yLabel} label={yLabel ? { value: yLabel, position: "insideLeft", angle: -90,   dy: 40} : undefined}/>
                    <ZAxis dataKey={zKey} range={[60, 3000]} name={zLabel}/>
                    <CartesianGrid />
                    <ReferenceLine y={0} stroke='#000'/>
                    {
                        data.length > 9 &&
                        <Brush
                            dataKey={zKey}
                            height={30}
                            stroke="#429ef4"
                            endIndex={9}
                        />
                    }
                    <Tooltip label={""} cursor={{strokeDasharray: '3 3'}} />
                    <Legend/>
                    {
                        data.map((dataItem, i) => <Scatter key={"scatter" + i} data={dataItem || []} name={scatterLabels[i]} fill={getColorWithIndex(i)} shape={ i === 1 ? "circle" : "triangle" }/>)
                    }
                </ScatterChart>
            </ResponsiveContainer>
        )
    }

}

export class KeyInsights extends React.Component { //FIXME: name not fixed

    render(){
        const data = this.props.data || [];
        return(
            <Paper style={paperStyles} zDepth={1}>
                {
                    data.map((item, i) =>
                        <Card key={"key-insight" + i} expandable={true} onExpandChange={(state) => {
                            ReactGA.event({
                                category: 'Key Insights Dropdown',
                                action: state ? 'Opened' : 'Closed',
                                label: item.insight
                            });
                        }}>
                            <CardHeader
                                title={item.insight}
                                avatar={<LightBulb color={greenA200}/>}
                                titleStyle={{fontSize: 18}}
                                actAsExpander={!!item.data}
                                showExpandableButton={!!item.data}
                            />
                            {  item.data &&
                            <CardText expandable={true}>
                                <ApxorSankeyGraph data={item.data}/>
                            </CardText>
                            }
                        </Card>
                    )
                }
            </Paper>
        )
    }

}

const EventMetricsRow = (props) => {
    const { name, count, sessions, users, stats } = props;
    const totalSessions = stats.sessions;
    const totalUsers = stats.users;
    return (
        <BottomNavigation style={bottomNavigationStyles}>
            <MetricColumn data={`${count}`} style={{minWidth: 250}} icon={<span style={{color: purple500}}>{`${name}`}</span>}/>
            <MetricColumn data={`${users} (${getPercent(totalUsers, users)})`} icon={<Group color={green500}/>} />
            <MetricColumn data={`${sessions} (${getPercent(totalSessions, sessions)})`} icon={<AccessTime color={blue500}/>} />
        </BottomNavigation>
    );
};

export class Behavior extends React.Component {

    render(){
        const { data = [], stats } = this.props;
        return(
            <Paper style={paperStyles} zDepth={1}>
                {
                    data.map((item, i) =>
                        <Card key={"behavior-events" + i} expandable={true} onExpandChange={(state) => {
                            ReactGA.event({
                                category: 'Event Behavior Dropdown',
                                action: state ? 'Opened' : 'Closed',
                                label: item.insight
                            });
                        }}>
                            <CardHeader
                                title={<EventMetricsRow {...item} stats={stats}/>}
                                avatar={<Widgets color={ purple500 }/>}
                                titleStyle={{fontSize: 18}}
                                textStyle={{width: '100%'}}
                                actAsExpander={!!item.attributes}
                                showExpandableButton={!!item.attributes}
                            />
                            {  item.attributes &&
                            <CardText expandable={true}>
                                <Distributions data={item.attributes} stats={stats}/>
                            </CardText>
                            }
                        </Card>
                    )
                }
            </Paper>
        )
    }

}

export class Paths extends React.Component {

    render(){
        const { data = [] } = this.props;
        return(
            <Paper style={paperStyles} zDepth={1}>
                {
                    data.map((item, i) =>
                        <Card key={"paths-" + i} expandable={true} onExpandChange={(state) => {
                            ReactGA.event({
                                category: 'Path Dropdown',
                                action: state ? 'Opened' : 'Closed',
                                label: item.start + "-" + item.end
                            });
                        }}>
                            <CardHeader
                                title={item.title}
                                titleStyle={{fontSize: 18}}
                                textStyle={{width: '100%'}}
                                actAsExpander
                                showExpandableButton
                            />
                            {  item.data &&
                                <CardText expandable={true}>
                                    <PathGraph data={{children: item.data}}/>
                                </CardText>
                            }
                        </Card>
                    )
                }
            </Paper>
        )
    }

}

export class RetentionTrends extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            filteredData: [],
            selectedKeys: []
        }
    }

    componentWillReceiveProps(nextProps) {
        const { selectedKeys } = this.state;
        if(nextProps.data.length > 0 && selectedKeys.length === 0){
            this.setState({filteredData: nextProps.data, selectedKeys: Object.keys(nextProps.data[0])});
        }
    }

    handleCheck = (val) => {
        let selected = [...this.state.selectedKeys];
        const selectedIndex = selected.indexOf(val);
        if(selectedIndex > -1){
            selected = [...selected.slice(0, selectedIndex), ...selected.slice(selectedIndex + 1, selected.length)];
        }else{
            selected.push(val);
        }
        let filteredData = [...this.props.data].map(item => {
            const obj = {};
            selected.forEach(key => {
                obj[key] = item[key]
            });
            return obj;
        });
        this.setState({selectedKeys: selected,filteredData: filteredData});
    };

    render(){
        const { data, stats } = this.props;
        const { selectedKeys, filteredData } = this.state;
        return(
            <Paper style={paperStyles} zDepth={1}>
                <div style={{display: 'flex', padding: 10}}>
                    {
                        data && data.length > 0 && Object.keys(data[0]).filter(key => key !== "name").map(key =>
                             <Checkbox
                                key={key}
                                label={key}
                                checked={selectedKeys.indexOf(key) > -1}
                                onCheck={() =>  this.handleCheck(key)}
                                style={{maxWidth: 250}}
                            />
                        )
                    }
                </div>
                <RetentionCurvesGraph data={filteredData}/>
            </Paper>
        )
    }

}

export class RetentionCurvesGraph extends React.Component {

    render(){
        const { height = 360, data = [] } = this.props;
        return(
            <ResponsiveContainer height={height}>
                <LineChart width={600} height={300} data={data}
                           margin={{top: 20, right: 30, left: 20, bottom: 20}}>
                    <XAxis dataKey="name" />
                    <YAxis/>
                    <CartesianGrid strokeDasharray="3 3"/>
                    <Tooltip/>
                    <Legend />
                    {
                        data && data.length > 0 && Object.keys(data[0]).filter(key => key !== "name").map(key =>
                            <Line key={key} type="monotone" dataKey={key} stroke="#8884d8" activeDot={{r: 8}}/>
                        )
                    }
                </LineChart>
            </ResponsiveContainer>
        )
    }

}

export class TimeSpent extends React.Component{

    render(){
        const {
            height = 300, data = []
        } = this.props;
        return(
            <ResponsiveContainer height={300}>
                <BarChart data={data}
                          margin={{top: 10, right: 30, left: 0, bottom: 0}}>
                    <XAxis dataKey="Time Spent" label={{value: "Time Spent", position: "insideBottomRight", dy: -28}}/>
                    <YAxis name="Users" label={{ value: "Users", position: "insideLeft", angle: -90,   dy: 40}}/>
                    <CartesianGrid strokeDasharray="3 3"/>
                    <Tooltip/>
                    <ReferenceLine y={0} stroke='#000'/>
                    {
                        data.length > 6 &&
                        <Brush
                            dataKey="Time Spent"
                            height={30}
                            stroke="#429ef4"
                            endIndex={6}
                        />
                    }
                    <defs>
                        <linearGradient id="Uninstalled" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor={red500} stopOpacity={0.8}/>
                            <stop offset="95%" stopColor={red500} stopOpacity={0}/>
                        </linearGradient>
                        <linearGradient id="Retained" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor={green500} stopOpacity={0.8}/>
                            <stop offset="95%" stopColor={green500} stopOpacity={0}/>
                        </linearGradient>
                    </defs>
                    <Bar
                        type='monotone' dataKey="Uninstalled"
                        fillOpacity={1} fill="url(#Uninstalled)"
                    />
                    <Bar
                        type='monotone' dataKey="Retained"
                        fillOpacity={1} fill="url(#Retained)"
                    />
                </BarChart>
            </ResponsiveContainer>
        )
    }

}

export class EventVsRetention extends React.Component{

    state = {
        currentEvent: null
    };

    componentWillReceiveProps(nextProps){
        const { data = {} } = nextProps;
        const keys = Object.keys(data);
        if(!this.state.currentEvent && keys.length > 0){
            this.setState({currentEvent: keys[0]}); //attaching first activity as default selected
        }
    }

    render(){
        const {
            height = 300, data = {},
            xKey, yKey, xLabel, yLabel, multiple = false
        } = this.props;
        const keys = Object.keys(data);
        const { currentEvent } = this.state;
        return(
            <div>
                <div style={{margin: 10}}>
                    <SelectField
                        floatingLabelText="Event"
                        value={currentEvent}
                        onChange={(event, index, currentEvent) => this.setState({currentEvent})}
                    >
                        { keys.map(event => <MenuItem key={event} value={event} primaryText={event} />) }
                    </SelectField>
                </div>
                { currentEvent && (multiple ? <MultipleScatterTrends {...this.props} data={data[currentEvent]}/> : <ScatterTrends {...this.props} data={data[currentEvent]}/>) }
            </div>
        )
    }

}

function sortByDataNumeric(a, b, _order) {

    // Replace internal parameters if not used
    if (_order == null) _order = "asc";

    // If values are null, place them at the end
    var dflt = (_order == "asc" ? Number.MAX_VALUE : -Number.MAX_VALUE);

    // Numeric values
    var aVal = (a == null ? dflt : a);
    var bVal = (b == null ? dflt : b);
    return _order == "asc" ? (aVal - bVal) : (bVal - aVal);
}

export class TimeSpentInActivity extends React.Component{

    state = {
        currentActivity: null
    };

    componentWillReceiveProps(nextProps){
        const { data = {} } = nextProps;
        const keys = Object.keys(data);
        if(!this.state.currentActivity && keys.length > 0){
            this.setState({currentActivity: keys[0]}); //attaching first activity as default selected
        }
    }

    render(){
        const {
            height = 300, data: activityData = {},
            xKey, yKey, xLabel, yLabel
        } = this.props;
        const keys = Object.keys(activityData).sort((a, b) => sortByDataNumeric(activityData[a].count, activityData[b].count, "dsc"));
        const { currentActivity } = this.state;
        const { data = [], count } = activityData[currentActivity] || {};
        return(
            <div>
                <div style={{margin: 10, display: 'flex'}}>
                    <SelectField
                        floatingLabelText="Activity"
                        value={currentActivity}
                        fullWidth
                        onChange={(event, index, currentActivity) => this.setState({currentActivity})}
                        style={{maxWidth: "80%"}}
                    >
                        { keys.map(activity => <MenuItem key={activity} value={activity} primaryText={activity.replace(/-/g, '.')} />) }
                    </SelectField>
                    <p style={{marginTop: '2.7em'}}>Total Users: <strong style={{fontSize: 16}}>{count}</strong></p>
                </div>
                {
                    currentActivity && <ResponsiveContainer height={300}>
                        <BarChart data={data} margin={{top: 10, right: 30, left: 0, bottom: 0}}>
                            <XAxis dataKey={xKey} label={xLabel ? { value: xLabel, position: "insideBottomRight", dy: -28} : undefined}/>
                            <YAxis label={yLabel ? { value: yLabel, position: "insideLeft", angle: -90, dy: 40} : undefined}/>
                            <CartesianGrid strokeDasharray="3 3"/>
                            <Tooltip />
                            <Legend />
                            <ReferenceLine y={0} stroke='#000'/>
                            {
                                data.length > 9 &&
                                <Brush
                                    dataKey={xKey}
                                    height={30}
                                    stroke="#429ef4"
                                    /*endIndex={6}*/
                                />
                            }
                            <defs>
                                <linearGradient id="time-spent" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor={green500} stopOpacity={0.8}/>
                                    <stop offset="95%" stopColor={green500} stopOpacity={0}/>
                                </linearGradient>
                            </defs>
                            <Bar
                                name={yLabel}
                                type='monotone' dataKey={yKey}
                                fillOpacity={1} fill="url(#time-spent)"
                            />
                        </BarChart>
                    </ResponsiveContainer>
                }
            </div>
        )
    }

}

class CustomTooltip extends React.Component {
    render() {
        const { active } = this.props;
        const { payload = [], label, value } = this.props;
        if (active && Array.isArray(payload) && payload.length > 0) {
            const currentPayload = payload[0].payload;
            const hasTopEvents = currentPayload && currentPayload.hasOwnProperty("topEvents");
            return (
                <div className="recharts-default-tooltip" style={{margin: "0px", padding: "10px", backgroundColor: 'rgba(255, 255, 255, 0.5)', border: "1px solid rgb(204, 204, 204)", whiteSpace: "nowrap"}}>
                    <p className="recharts-tooltip-label" style={{margin: "0px"}}>{ label }</p>
                    {/*<p className="recharts-tooltip-label" style={{margin: "0px"}}>{ payload.value }</p>*/}
                    <ul className="recharts-tooltip-item-list" style={{padding: "0px", margin: "0px"}}>
                        {
                            payload.map((item, index) =>
                                <li className="recharts-tooltip-item" key={`li${index}`} style={{display: "block", paddingTop: "4px", paddingBottom: "4px", color: item.color}}>
                                    <span className="recharts-tooltip-item-name">{labelFormatter(item.name)}</span>
                                    <span className="recharts-tooltip-item-separator"> : </span>
                                    <span className="recharts-tooltip-item-value">{item.value} {renderPercent(item)}</span>
                                    {/*<span className="recharts-tooltip-item-unit"> {item.unit}</span>*/}
                                </li>
                            )
                        }
                    </ul>
                </div>
            )
        }
        return <span/>;
    }
}

export class DropOff extends React.Component{

    render(){
        const {
            height = 300, data = []
        } = this.props;
        return(
            <ResponsiveContainer height={300}>
                <BarChart data={data}
                          margin={{top: 10, right: 30, left: 0, bottom: 0}}>
                    <XAxis dataKey="Activity" label={{ value: "Activity", position: "insideBottomRight", dy: -28}}/>
                    <YAxis/>
                    <CartesianGrid strokeDasharray="3 3"/>
                    <Tooltip content={<CustomTooltip/>}/>
                    <ReferenceLine y={0} stroke='#000'/>
                    {
                        data.length > 10 &&
                        <Brush
                            dataKey="Activity"
                            height={30}
                            stroke="#429ef4"
                            endIndex={10}
                        />
                    }
                    <defs>
                        <linearGradient id="Users" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor={blue500} stopOpacity={0.8}/>
                            <stop offset="95%" stopColor={blue500} stopOpacity={0}/>
                        </linearGradient>
                    </defs>
                    <Bar
                        type='monotone' dataKey="Users"
                        fillOpacity={1} fill="url(#Users)"
                    />
                </BarChart>
            </ResponsiveContainer>
        )
    }

}
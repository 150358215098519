/**
 * Created by jyothi on 22/8/17.
 */
import {createStore, applyMiddleware, compose} from 'redux';
import { browserHistory } from 'react-router';
import {routerMiddleware} from 'react-router-redux';
import thunk from 'redux-thunk';
import promiseMiddleware from '../middleware';
import logger from './reduxLogger';
import rootReducer from '../reducers';
import getSessionState from "./Session";
import authMiddleware from '../middleware/authMiddelware';
import refreshMiddleware from '../middleware/refreshMiddleware';
import persistState from 'redux-localstorage';

const SESSION_KEY = "_apx_2";

const __DEV__ = !(process.env.NODE_ENV === "production");

const reduxRouterMiddleware = routerMiddleware(browserHistory);

const authStorageConfig = {
    key: 'auth',
    serialize: (store) => {
        return store && store.auth ?
            JSON.stringify(store.auth) : store;
    },
    deserialize: (state) => ({
        auth: state ? JSON.parse(state) : {},
    }),
};

const expiryStorageConfig = {
    key: 'accessExpiry',
    serialize: (store) => {
        return store && store.auth && store.auth.accessExpiry !== undefined ?
            JSON.stringify(store.auth.accessExpiry) : JSON.stringify(null);
    },
    deserialize: (state) => ({"accessExpiry": JSON.parse(state)})
};

function configureStore(initialState) {

    const store = createStore(
        rootReducer,
        {...initialState, ...getSessionState(SESSION_KEY)},
        compose(
            __DEV__
                ? applyMiddleware(authMiddleware(SESSION_KEY), reduxRouterMiddleware, promiseMiddleware, refreshMiddleware, thunk, logger)
                : applyMiddleware(authMiddleware(SESSION_KEY), reduxRouterMiddleware, promiseMiddleware, refreshMiddleware, thunk),
            //persistState('auth', authStorageConfig)
            persistState('auth', expiryStorageConfig)
        )
    );

    if (module.hot) {
        module.hot.accept('../reducers', () => {
            const nextRootReducer = require('../reducers');
            store.replaceReducer(nextRootReducer);
        });
    }

    return store;
}


export default configureStore;
/**
 * Created by jyothi on 1/8/17.
 */
import React, { Component }  from 'react';
import { connect } from 'react-redux';
import {getPreprocessed, getPreprocessList} from '../actions';
import {RetentionReport} from "./components";
import PropTypes from 'prop-types';

function mapStateToProps(state) {
    return {
        session: state.auth,
        router: state.routing,
        reports: state.reports
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getPreprocessed: (appId, config) => {
            dispatch(getPreprocessed(appId, config));
        },
        getPreprocessList: (appId) => dispatch(getPreprocessList(appId))
    };
}

class Preprocessed extends React.Component {

    constructor(props){

        super(props);
        this.state = {
            config: {
                events: [],
                attributes: []
            },
        };
        this.reportId = props.params.reportId;

    }

    updateConfig = (preprocess_list = []) => {
        const index = preprocess_list.findIndex(o => o.report_id === this.reportId);
        if(this.reportId && index > -1){
            this.setState({config: preprocess_list[index]});
            return true;
        }
        return false;
    };

    componentWillMount(){
        const { params: { appId }, reports: { preprocess_list = [] }, getPreprocessList } = this.props;
        if(!this.updateConfig(preprocess_list)){
            getPreprocessList(appId);
        }
    }

    componentWillReceiveProps(nextProps){
        const oldList = this.props.reports.preprocess_list;
        const newList = nextProps.reports.preprocess_list;
        if(oldList.length !== newList.length){
            this.updateConfig(newList);
        }
    }

    render(){
        return(
            <div>
                <RetentionReport {...this.props} {...this.state}/>
            </div>
        );
    }

}

Preprocessed.propTypes = {

};

Preprocessed.contextTypes = {
    router: PropTypes.object.isRequired,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Preprocessed);
/**
 * Created by jyothi on 24/8/17.
 */
import React, { Component }  from 'react';
import {  } from './components';
import PropTypes from 'prop-types';
import Divider from 'material-ui/Divider';
import {
    Header, Distributions, Turnarounds,
    UsageMetrics, KeyInsights, Stats,
    KPIs, Behavior, RetentionTrends
} from './components';
import { headerStyles, subHeaderStyles } from '../actionTypes';
import SelectField from 'material-ui/SelectField';
import {Card, CardActions, CardHeader, CardMedia, CardTitle, CardText} from 'material-ui/Card';
import { lightWhite } from 'material-ui/styles/colors';
import {TimeSpent} from "./components";
import {DropOff} from "./components";
import {ScatterTrends} from "./components";
import {TimeSpentInActivity} from "./components";
import {EventVsRetention} from "./components";
import {Paths} from "./components";

const ReportStyles = {
    width: '100%',
    maxWidth: '1200px',
    margin: '0 auto',
    textAlign: 'left'
};

const LineDivider = (props) => <Divider style={{color: lightWhite, height: 2}}/>;

export const Panel = (props) => (
    <Card expandable={true} initiallyExpanded>
        <CardHeader
            title={props.title}
            actAsExpander={true}
            titleStyle={subHeaderStyles}
            showExpandableButton={true}
            style={subHeaderStyles}
        />
        <CardText expandable={true}>
            {props.children}
        </CardText>
    </Card>
);

export default class Report extends React.Component {

    constructor(props){

        super(props);

        this.id = props.params.reportId;

        this.state = {
            data: {},
            currentGroup: this.id
        };

    }

    componentWillMount(){
        const { appId, reportId } = this.props.params;
        this.props.loadData(appId, reportId);
    }

    handleMetricsData = (data, key = "metrics") => {
        const { stats = {} } = data;
        const metrics  = data[key] || [];
        const d0UninstallPercent = stats.d0_uninstalls / stats.new_installs * 100;
        const sortedMetrics = metrics.sort((a, b) => {
            const aD0UninstallPercent = a.inactiveTotal / a.total * 100;
            const bD0UninstallPercent = b.inactiveTotal / b.total * 100;
            return aD0UninstallPercent - bD0UninstallPercent;
        });

        return sortedMetrics;

    };

    handleGroupFilter = (value) => { //FIXME: taking report id a filter as of now
        const { appId } = this.props.params;
        if(value !== undefined && value !== null && value !== "") {
            this.setState({currentGroup: value});
            this.props.router.replace(`/apps/${appId}/reports/${value}`);
            this.props.loadData(appId, value);
        }
    };

    statsAsObject = (stats = {data: []}) => {
        let statsObj = {};
        stats.data.forEach(item => {
            statsObj[item.key] = item.value;
        });
        return statsObj;
    };

    render(){
        const { appId, reportId } = this.props.params;
        let { report_pending, report_failed, report={data: {}} } = this.props.report || {};
        if(Object.keys(report).length === 0){
            report = {data: {}};
        }
        const {
            data: {
                header, metrics, errors,
                day0metrics, stats, distributions,
                turnarounds, insights, kpis,
                behavior, retentionSegments, timeSpent,
                dropOff, eventsVsRetention, timeSpentInActivity,
                eventsVsUninstalls, attributesVsUninstalls, timeSpentVsUninstalls,
                paths
            }
        } = report;

        /*const metrics = this.handleMetricsData(this.state.data);
         const errors = this.handleMetricsData(this.state.data, "errors");
         const day0metrics = this.handleMetricsData(this.state.data, "day0metrics");*/

        const { currentGroup } = this.state;
        const statsObj = this.statsAsObject(stats);
        return(
            <section className="content">
                <Header
                    currentGroup={currentGroup}
                    handleGroupFilter={this.handleGroupFilter}
                    {...header}
                />
                <Stats {...stats}/>
                {
                    distributions &&
                    <Panel title={distributions.name || "Distributions"}>
                        <Distributions {...distributions} stats={statsObj}/>
                    </Panel>
                }
                { distributions && <LineDivider /> }

                {
                    metrics &&
                    <Panel title={metrics.name || "Usage Metrics"}>
                        <UsageMetrics stats={statsObj} {...metrics}/>
                    </Panel>
                }
                { metrics && <LineDivider /> }
                {
                    paths &&
                    <Panel title={paths.name || "Path Analysis"}>
                        <Paths {...paths}/>
                    </Panel>
                }
                {paths && <LineDivider />}
                {
                    behavior &&
                    <Panel title={behavior.name || "Usage Metrics"}>
                        <Behavior stats={statsObj} {...behavior}/>
                    </Panel>
                }
                { behavior && <LineDivider /> }

                {
                    day0metrics &&
                    <Panel title={day0metrics.name || "Day 0 Usage Metrics"}>
                        <UsageMetrics stats={statsObj} {...day0metrics}/>
                    </Panel>
                }
                {day0metrics && <LineDivider /> }

                {
                    kpis &&
                    <Panel title={kpis.name || "Day 0 Engagement Vs Uninstalls"}>
                        <KPIs stats={statsObj} {...kpis}/>
                    </Panel>
                }
                { kpis && <LineDivider /> }

                {
                    errors &&
                    <Panel title={errors.name || "Day 0 Errors"}>
                        <UsageMetrics stats={statsObj} {...errors}/>
                    </Panel>
                }
                { errors && <LineDivider /> }

                {
                    timeSpent &&
                    <Panel title={timeSpent.name || "FTU Time Spent"}>
                        <TimeSpent {...timeSpent}/>
                    </Panel>
                }
                {timeSpent && <LineDivider/> }

                {
                    timeSpentInActivity &&
                    <Panel title={timeSpentInActivity.name || "Time spent in Activity"}>
                        <TimeSpentInActivity {...timeSpentInActivity}/>
                    </Panel>
                }
                {timeSpentInActivity && <LineDivider/> }

                {
                    eventsVsRetention &&
                    <Panel title={eventsVsRetention.name || "Day 0 Events Vs Day 3 Range Retention"}>
                        <EventVsRetention {...eventsVsRetention}/>
                    </Panel>
                }
                {eventsVsRetention && <LineDivider/> }

                {
                    eventsVsUninstalls &&
                    <Panel title={eventsVsUninstalls.name || "Day 0 Events Vs Uninstalls"}>
                        <EventVsRetention {...eventsVsUninstalls}/>
                    </Panel>
                }
                {eventsVsUninstalls && <LineDivider/> }

                {
                    attributesVsUninstalls &&
                    <Panel title={attributesVsUninstalls.name || "Attributes Vs Uninstalls"}>
                        <EventVsRetention {...attributesVsUninstalls}/>
                    </Panel>
                }
                {attributesVsUninstalls && <LineDivider/> }

                {
                    timeSpentVsUninstalls &&
                    <Panel title={timeSpentVsUninstalls.name || "Attributes Vs Uninstalls"}>
                        <EventVsRetention {...timeSpentVsUninstalls}/>
                    </Panel>
                }
                {timeSpentVsUninstalls && <LineDivider/> }

                {
                    turnarounds &&
                    <Panel title={turnarounds.name || "Turnaround"}>
                        <Turnarounds {...turnarounds}/>
                    </Panel>
                }
                { turnarounds && <LineDivider /> }

                {
                    dropOff &&
                    <Panel title={dropOff.name || "Drop Off"}>
                        <DropOff {...dropOff}/>
                    </Panel>
                }
                { dropOff && <LineDivider/> }
                {
                    insights &&
                    <Panel title={insights.name || "Key Insights"}>
                        <KeyInsights {...insights}/>
                    </Panel>
                }
                { insights && <LineDivider /> }
                {
                    retentionSegments &&
                        <Panel title={"Day 7 Retention Analysis"}>
                            <RetentionTrends {...retentionSegments}/>
                        </Panel>
                }
            </section>
        )

    }

}
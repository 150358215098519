/**
 * Created by jyothi on 1/8/17.
 */

import { callApi } from '../../../../../../../api';
import { makeDefaultQueryString, withQueryStrings } from '../../../../../../../utils';

const REPORTS_API = "reports";
const ADD_REPORT_API = "reports";

/**
 *
 * @param auth
 * @param appId
 * @param filters
 * @returns {Promise}
 */
export function getReportsAPI(auth, appId, filters) {
    const url = makeDefaultQueryString(REPORTS_API, auth, appId);
    const config = {
        method: "GET",
        auth: auth
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

/**
 *
 * @param auth
 * @param appId
 * @param report
 * @returns {Promise}
 */
export function addReportAPI(auth, appId, report) {
    const url = makeDefaultQueryString(REPORTS_API, auth, appId);
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(report)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}


const PREPROCESS_LIST_API = "preprocess/retention/calculated";

export function getPreprocessListAPI(auth, appId, filters) {
    const url = makeDefaultQueryString(PREPROCESS_LIST_API, auth, appId);
    const config = {
        method: "GET",
        auth: auth
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

const PREPROCESS_API = "preprocess/retention";

export function getPreprocessAPI(auth, appId, filters) {
    const url = makeDefaultQueryString(PREPROCESS_API, auth, appId) + withQueryStrings(filters);
    const config = {
        method: "GET",
        auth: auth
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function deletePreprocessReportAPI(auth, appId, reportId) {
    const url = makeDefaultQueryString(PREPROCESS_API + '/' + reportId, auth, appId);
    const config = {
        method: "DELETE",
        auth: auth
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}
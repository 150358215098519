/**
 * Created by jyothi on 2/8/17.
 */
import React from 'react';
import ReactDOM from 'react-dom';
import * as d3 from 'd3';
import {event as currentEvent} from 'd3';
import { sankey, sankeyJustify, sankeyLinkHorizontal } from 'd3-sankey';

const defaultOptions = {
    animDuration: 800
};

export default class ApxorSankeyGraph extends React.Component {

    constructor(props){

        super(props);
        this.state = {
            data: props.data || {}
        };
        this.instanceId = `apxor-sankey-graph-${Math.ceil(Math.random() * 100000)}`;
        this.options = {...defaultOptions, ...props.options || {}};
        this.sankey = null;
        this.width = 0;
        this.height = 0;
        this.graph = null;
        this.group = null;
        this.links = null;
        this.nodes = null;
        this.path = null;
    }

    getElement = () => ReactDOM.findDOMNode(this.refs[this.instanceId]);

    //formatNumber = () => d3.format(",.0f");

    format = (d) => d + " Users";

    color = d3.scaleOrdinal(d3.schemeCategory10);

    componentDidMount() {

        this.graph = d3.select(this.getElement()); //graph root
        const {width, height} = this.graph.node().getBoundingClientRect();
        this.group = this.graph.append("svg:g");
        this.width = width;
        this.height = height;
        this.drawGraph();
    }

    drawGraph = () => {

        const {data} = this.state;

        this.links = this.group.append("svg:g")
            .attr("class", "links")
            .attr("fill", "none")
            .attr("stroke", "#000")
            .attr("stroke-opacity", 0.2)
            .selectAll("path");
        this.nodes = this.group.append("svg:g")
            .attr("class", "nodes")
            .attr("font-family", "sans-serif")
            .attr("font-size", 10)
            .selectAll("g");

        this.sankey = sankey()
            .nodeWidth(15)
            .nodePadding(10)
            .extent([[1, 1], [this.width - 1, this.height - 6]]);
        //.size([this.width, this.height]);
        //.nodeAlign(sankeyJustify());

        this.sankey(data);

        const link = this.links
            .data(data.links)
            .enter().append("path")
            .attr("d", sankeyLinkHorizontal())
            .attr("stroke-width", (d) => Math.max(1, d.width));

        link.append("title")
            .text((d) => d.source.name + " → " + d.target.name + "\n" + this.format(d.value));

        link.on("mouseover", (e) => {
            currentEvent.target.setAttribute("stroke-opacity", 0.3);
        }).on("mouseleave", (e) => {
            currentEvent.target.setAttribute("stroke-opacity", 0.2);
        });

        const node = this.nodes
            .data(data.nodes)
            .enter().append("g");

        node.append("rect")
            .attr("x", (d) => d.x0)
            .attr("y", (d) => d.y0)
            .attr("height", (d) => d.y1 - d.y0)
            .attr("width", (d) => d.x1 - d.x0)
            .attr("fill", (d) => this.color(d.name.replace(/ .*/, "")))
            .attr("stroke", "#000");

        node.append("text")
            .attr("x", (d) => d.x0 - 6)
            .attr("y", (d) => (d.y1 + d.y0) / 2)
            .attr("dy", "0.35em")
            .attr("text-anchor", "end")
            .text((d) => d.name + " (" + d.value + ")")
            .filter((d) => d.x0 < this.width / 2)
            .attr("x", (d) => d.x1 + 6)
            .attr("text-anchor", "start");

        node.append("title")
            .text((d) => d.name + "\n" + this.format(d.value));

    };

    render() {

        return <svg width="100%" height="300" ref={this.instanceId} />;

    }

}
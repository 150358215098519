/**
 * Created by jyothi on 2/8/17.
 */
import React, { Component } from 'react';
import {connect } from 'react-redux';
import {Link} from 'react-router';
import AceEditor from 'react-ace';
import PropTypes from 'prop-types';
import FloatingActionButton from 'material-ui/FloatingActionButton';
import { getReport, updateReport } from '../../Report/actions';
import 'brace/mode/json';
import 'brace/theme/github';
import CloudUpload from 'material-ui/svg-icons/file/cloud-upload';
import Snackbar from 'material-ui/Snackbar';
import RefreshIndicator from 'material-ui/RefreshIndicator';
import { green500, red500 } from 'material-ui/styles/colors';

const editorStyles = {
    width: '100%',
    height: '100%',
    textAlign: 'left'
};

function mapStateToProps(state) {
    return {
        session: state.auth,
        router: state.routing,
        report: state.report
    };
}

function mapDispatchToProps(dispatch) {
    return {
        loadReport: (appId, reportId) => {
            dispatch(getReport(appId, reportId));
        },
        updateEdit: (appId, reportId, data) => dispatch(updateReport(appId, reportId, data)),
        //resetData: () => dispatch(resetEditor())
    };
}

class Editor extends Component {

    constructor(props){
        super(props);
        this.state = {
            data: '/*Remove this before start writing*/',
            open: false
        };
    };

    componentWillMount(){
        const { appId, reportId } = this.props.params;
        this.props.loadReport(appId, reportId);
    }

    componentWillReceiveProps(nextProps) {
        const { report } = nextProps.report;
        if(JSON.stringify(report) !== JSON.stringify(this.state.data)){
            this.setState({open: true, data: JSON.stringify(report, null, 4)});
        }
    }

    updateData = (newData) => {
        this.setState({data: newData});
    };

    saveData = () => {
        const {appId, reportId} = this.props.params;
        this.props.updateEdit(appId, reportId, this.state.data);
    };

    componentWillUnmount() {
        //this.props.updateEdit(this.state.data);
    }

    render() {
        const { edit_report_pending, edit_report_success, edit_report_failed, report_pending } = this.props.report;
        const { data, open } = this.state;
        const { appId, reportId } = this.props.params;
        return (
            <section className="content">
                {
                    edit_report_pending && <RefreshIndicator
                        size={200}
                        left={600}
                        top={200}
                        status="loading"
                    />
                }
                <div style={editorStyles}>
                    <AceEditor
                        mode="json"
                        theme="github"
                        name="editor"
                        onLoad={() => {}}
                        onChange={this.updateData}
                        fontSize={14}
                        height="80vh"
                        width="100%"
                        showPrintMargin={true}
                        showGutter={true}
                        highlightActiveLine={true}
                        value={data}
                        editorProps={{$blockScrolling: true}}
                        wrapEnabled={true}
                        setOptions={{
                            showLineNumbers: true,
                            tabSize: 4,
                        }}/>
                </div>
                <div style={{
                    right: 10,
                    bottom: 10,
                    position: 'fixed',
                    zIndex: 200
                }}>
                    <FloatingActionButton onClick={this.saveData}>
                        {
                            <CloudUpload tooltip="Save" />
                        }
                    </FloatingActionButton>
                </div>
                <Snackbar
                    open={edit_report_success && open}
                    message="Report Saved..!"
                    autoHideDuration={4000}
                    onRequestClose={() => this.setState({open: false})}
                    action={<Link to={`/apps/${appId}/reports/${reportId}`}>Open</Link>}
                />
                <Snackbar
                    open={edit_report_failed && open}
                    message="Failed to save Report..!"
                    autoHideDuration={4000}
                    onRequestClose={() => this.setState({open: false})}
                />
            </section>
        );
    }
}

Editor.propTypes = {

};

Editor.contextTypes = {
    router: PropTypes.object.isRequired,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Editor);
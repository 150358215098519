/**
 * Created by jyothi on 22/8/17.
 */
import { routerReducer as routing } from 'react-router-redux';
import { combineReducers } from 'redux';
import authReducer from '../containers/Root/App/Auth/reducer';
import appsReducer from '../containers/Root/App/RequireAuth/Apps/reducer';
import reportsReducer from '../containers/Root/App/RequireAuth/Apps/App/Reports/reducer';
import reportReducer from '../containers/Root/App/RequireAuth/Apps/App/Reports/Report/reducer';

const rootReducer = combineReducers({
    routing,
    auth: authReducer,
    apps: appsReducer,
    reports: reportsReducer,
    report: reportReducer
});

export default rootReducer

import React from 'react';
import {
    Table,
    TableBody,
    TableHeader,
    TableHeaderColumn,
    TableRow,
    TableRowColumn,
} from 'material-ui/Table';
import Divider from 'material-ui/Divider';
import LineGraph from "../../../../../../../../components/LineGraph";
import {Panel} from "../Report/FTUEReport";

const lineGraphFormattedData = (data = [], isAtleast) => {
    const dataWithLines = data.filter(d => d.data.length > 0);
    const events = dataWithLines.map(d => d.event);
    const size = dataWithLines.length > 0 ? dataWithLines[0].data.length : 0;
    let datum = [];
    if(size > 0){
        datum = dataWithLines[0].data.map(o => ({event_count: '' + o.event_count}));
        dataWithLines.map(d => {
            const { event, data } = d;
            data.forEach(({atleast_retained_percent, retained_percent, event_done_users, atleast_event_done_users}, i) => {
                if(datum[i]){
                    const percent = isAtleast ? atleast_retained_percent : retained_percent;
                    datum[i][event] = isNaN(percent) ? 0 : percent;
                    const users = isAtleast ? atleast_event_done_users : event_done_users;
                    datum[i][event + '_users'] = isNaN(users) ? 0 : users;
                }
            });
        });
    }
    return {events, datum};
};

export default class SortedTable extends React.Component {

    state = {
        selected: []
    };

    isSelected = (index) => {
        return this.state.selected.includes(index);
    };

    handleRowSelection = (selectedRows = []) => {
        if(selectedRows.length > 0){
            this.setState({
                selected: selectedRows,
            });
            const { updateEvent, data = [] } = this.props;
            updateEvent(data[selectedRows[0]].event);
        }
    };

    render(){
        const { data = [], postEvent, isAtleast } = this.props;
        const { selected } = this.state;
        const hasExtraData = data.some(d => d.data.length > 0);
        const { events = [], datum = [] } = lineGraphFormattedData(data, isAtleast);
        return(
            <Panel title={<span>Event Frequency Vs <strong>{postEvent}</strong> - Sorted List by insight percentage</span>}>
                <Table onRowSelection={this.handleRowSelection} style={{maxHeight: '70vh'}}>
                    <TableHeader>
                        <TableRow>
                            <TableHeaderColumn style={{width: '20%'}}>Event</TableHeaderColumn>
                            { (!hasExtraData || !isAtleast) && <TableHeaderColumn>Atleast One %</TableHeaderColumn>}
                            {
                                isAtleast && hasExtraData && Array.from(Array(6).keys()).map(i => <TableHeaderColumn key={"head-" + i}>Atleast {i + 1} %</TableHeaderColumn>)
                            }
                        </TableRow>
                    </TableHeader>
                    <TableBody deselectOnClickaway={false}>
                        {
                            data.map((row, index) =>
                                <TableRow key={row.event} selected={this.isSelected(index)}>
                                    <TableRowColumn style={{width: '20%'}} title={row.event}>{row.event}</TableRowColumn>
                                    {(row.data.length === 0 || !isAtleast) && <TableRowColumn>{Number(row.percent).toFixed(2)} ({row.users || "NA"})</TableRowColumn>}
                                    {isAtleast && row.data.slice(0, 6).map((d, i) => <TableRowColumn key={"col-" + i}>{Number(d.atleast_retained_percent).toFixed(2)} ({d.atleast_event_done_users || "NA"})</TableRowColumn>)}
                                </TableRow>
                            )
                        }
                    </TableBody>
                </Table>
                <br />
                {events && events.length > 0 &&
                <div style={{width: '50%', margin: '0 auto'}}>
                    <Panel title={<span>Event Frequency Vs <strong>{postEvent}</strong> - Comparison Graph </span>}>
                        <LineGraph lines={events} data={datum} dataKey="event_count"/>
                    </Panel>
                </div>
                }
                <Divider />
                {data.length > 0 && selected.length > 0 &&
                <Panel title={<span>Impact of Event with Properties - Sorted list </span>}>
                    <SortedAttributes {...this.props} {...data[selected[0]] || {}}/>
                </Panel>
                }
            </Panel>
        )
    }

}

class SortedAttributes extends React.Component {

    state = {
        selected: []
    };

    isSelected = (index) => {
        return this.state.selected.includes(index);
    };

    handleRowSelection = (selectedRows) => {
        if(selectedRows.length > 0){
            this.setState({
                selected: selectedRows,
            });
            const { updateAttribute, attributes = [] } = this.props;
            updateAttribute(attributes[selectedRows[0]].attribute);
        }
    };

    render(){
        const { attributes = [], event } = this.props;
        return(
            <Table onRowSelection={this.handleRowSelection} style={{maxHeight: '70vh'}}>
                <TableHeader>
                    <TableRow>
                        <TableHeaderColumn>Selected Event = {event}</TableHeaderColumn>
                        <TableHeaderColumn>Atleast One %</TableHeaderColumn>
                    </TableRow>
                </TableHeader>
                <TableBody deselectOnClickaway={false}>
                    {
                        attributes.map((row, index) =>
                            <TableRow key={row.attribute + row.value} selected={this.isSelected(index)}>
                                <TableRowColumn>{row.attribute} = {row.value}</TableRowColumn>
                                <TableRowColumn>{Number(row.percent).toFixed(2)} ({row.users || "NA"})</TableRowColumn>
                            </TableRow>
                        )
                    }
                </TableBody>
            </Table>
        )
    }
}
import { refreshToken } from "../containers/Root/App/Auth/api";
import { push } from "react-router-redux"
import logger from '../utils/Logger';
import { setAccessExpiry, logoutUser } from "../containers/Root/App/Auth/actions";
import { TOKEN_REFRESH_FAILED, TOKEN_REFRESH_SUCCESS, TOKEN_REFRESH_PENDING } from "../containers/Root/App/Auth/actionTypes"

let buffer = [];
let refreshRequestPending = false

export default function refreshMiddleware({ dispatch, getState }) {
    return (next) => (action) => {
        const accessExpiry = getState().auth.accessExpiry
        const isLogoutAction = "isLogout" in action
        // Skip non-thunk actions
        if (typeof action !== 'function') {
            return next(action);
        }
        // Skip if we haven't even acquired a access token yet (in non-logged in state)
        if (accessExpiry === null) {
            return next(action);
        }
        // Token not expired
        if (accessExpiry - 3 > Date.now()) {
            return next(action);
        }
        // Since, refresh middleware dispatches logout action, we want to ignore it to avoid loops
        if (isLogoutAction){
            return next(action);
        }

        buffer.push(action)
        if (refreshRequestPending) {
            return
        }

        dispatch({ type: TOKEN_REFRESH_PENDING });
        refreshRequestPending = true
        refreshToken(getState().auth)
            .then(response => {
                if (!response.ok) {
                    throw Error(`TOKEN_REFRESH_FAILED: Got ${response.status} status code while refreshing token`)
                }
                return response.json()
            })
            .then(json => {
                return dispatch(setAccessExpiry(json.expiry))
            })
            .then(() => {
                buffer.forEach(action => next(action))
                buffer = []
                refreshRequestPending = false
                dispatch({ type: TOKEN_REFRESH_SUCCESS })
            })
            .catch(error => {
                logger.error(error, error.message);
                dispatch({ type: TOKEN_REFRESH_FAILED })
                dispatch(push('/logout'))
                buffer = []
                refreshRequestPending = false
            })
    };
}
/**
 * Created by jyothi on 1/8/17.
 */
import React from 'react';
import { Link } from 'react-router';
import {Card, CardActions, CardHeader, CardMedia, CardTitle, CardText} from 'material-ui/Card';
import RefreshIndicator from 'material-ui/RefreshIndicator';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import DatePicker from 'material-ui/DatePicker';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import { REPORT_TYPES } from './actionTypes';
import { formatTime } from '../../../../../../../utils';
import InsertChart from 'material-ui/svg-icons/editor/insert-chart';
import FloatingActionButton from 'material-ui/FloatingActionButton';
import ContentAdd from 'material-ui/svg-icons/content/add';
import {List, ListItem} from 'material-ui/List';
import {blue300, indigo900} from 'material-ui/styles/colors';
import Chip from 'material-ui/Chip';
import IconButton from 'material-ui/IconButton';
import DeleteIcon from 'material-ui/svg-icons/action/delete';

export class ReportList extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            reportType: null,
            preprocessed: [],
            deleteTriggered: false,
            currentReport: null
        }
    }

    componentWillMount(){

    }

    componentWillReceiveProps(nextProps){

    }

    handleOpen = () => {
        this.setState({deleteTriggered: true});
    };

    handleClose = () => {
        this.setState({deleteTriggered: false});
    };

    actions = [
        <FlatButton
            label="Cancel"
            primary={false}
            onClick={this.handleClose}
            keyboardFocused={true}
        />,
        <FlatButton
            label="Confirm"
            primary={true}
            onClick={() => {
                this.props.deletePreprocessReport(this.props.params.appId, this.state.currentReport);
                this.handleClose();
            }}
        />
    ];

    render(){
        const {
            params: {appId},
            reports: {
                reports_pending, reports = [], reports_failed,
                preprocess_list_pending, preprocess_list = [], preprocess_list_failed
            } = {}
        } = this.props;
        const hasPreprocessed = Array.isArray(preprocess_list) && preprocess_list.length > 0;
        return(
            <div>
                <Card>
                    <CardHeader
                        title="Reports"
                        subtitle=""
                        avatar={<InsertChart/>}
                        showExpandableButton={true}
                    />
                    <CardText>
                        {
                            reports_pending && <RefreshIndicator
                                size={200}
                                left={600}
                                top={200}
                                status="loading"
                            />
                        }
                        <List>
                            {
                                reports.map((item, index) =>
                                    <ListItem key={item._id + index}
                                              onClick={() => {
                                                  this.props.router.replace(`/apps/${this.props.params.appId}/reports/${item._id}`);
                                              }}
                                              primaryText={ `${formatTime(item.startDate, "Do MMM YYYY")} - ${formatTime(item.endDate, "Do MMM YYYY")}` }
                                              rightAvatar={ <Chip backgroundColor={blue300}>{ item.report_type }</Chip> }
                                    />
                                )
                            }
                        </List>
                        {
                            reports_failed && <h1>Reports failed to load..!</h1>
                        }
                    </CardText>
                </Card>
                <br />
                {
                    hasPreprocessed && <Card>
                        <CardHeader
                            title="Retention Reports"
                            subtitle=""
                            avatar={<InsertChart/>}
                            showExpandableButton={true}
                        />
                        <CardText>
                            <Dialog
                                title="Sure want to delete?"
                                actions={this.actions}
                                modal={true}
                                open={this.state.deleteTriggered}
                                onRequestClose={this.handleClose}
                            />
                            <List>
                                {
                                    preprocess_list.map((item, index) =>
                                        <ListItem
                                            key={"retention-" + index}
                                            onClick={() => {
                                                this.props.router.push(`/apps/${appId}/preprocessed/${item.report_id}`);
                                            }}
                                            primaryText={
                                                <span>
                                                    <strong>{item.name}</strong>
                                                    <em style={{fontSize: 14, marginLeft: 8}}>{`${formatTime(item.startTime, "Do MMM YYYY")} - ${formatTime(item.endTime, "Do MMM YYYY")}`}</em>
                                                </span>
                                            }
                                            secondaryText={`Pre: ${item.preStart} - ${item.preEnd} Days |
                                         Post: ${item.postStart} - ${item.postEnd} Days |
                                          ${item.events.length} Events | ${item.attributes.length} Attributes`}
                                            rightIconButton={
                                                <IconButton
                                                    onClick={e => {
                                                        this.setState({currentReport: item.report_id});
                                                        this.handleOpen();
                                                    }}
                                                >
                                                    <DeleteIcon color="#FF0000" />
                                                </IconButton>
                                            }
                                        />
                                    )
                                }
                            </List>
                            {
                                reports_failed && <h1>Reports failed to load..!</h1>
                            }
                        </CardText>
                    </Card>
                }
            </div>
        )

    }

}

export class NewReport extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            open: false,
            startDate: null,
            endDate: null,
            reportType: REPORT_TYPES.FTUE
        }
    }

    handleOpen = () => {
        this.setState({open: true});
    };

    handleClose = () => {
        this.setState({open: false});
    };

    handleSubmit = () => {
        this.setState({open: false});
        const { startDate, endDate, reportType } = this.state;
        this.props.handleCreate(startDate, endDate, reportType);
    };

    componentWillReceiveProps(nextProps){

    }

    render(){
        const {add_report_pending, add_report, add_report_failed} = this.props;
        const actions = [
            <FlatButton
                label="Cancel"
                primary={true}
                onClick={this.handleClose}
            />,
            <FlatButton
                label="Ok"
                primary={true}
                keyboardFocused={true}
                onClick={this.handleSubmit}
            />
        ];
        const { open, endDate, startDate, reportType } = this.state;
        return(
            <div style={{
                right: 10,
                bottom: 10,
                position: 'fixed',
                zIndex: 200
            }}>
                <FloatingActionButton onClick={this.handleOpen} tooltip="New Report">
                  <ContentAdd />
                </FloatingActionButton>
                <Dialog
                    title="New Report"
                    actions={actions}
                    modal={false}
                    open={open}
                    onRequestClose={this.handleClose}
                >
                    <SelectField
                        floatingLabelText="Report Type"
                        value={reportType}
                        onChange={(event, index, reportType) => this.setState({reportType})}
                    >
                        <MenuItem value={REPORT_TYPES.FTUE} primaryText="First Time User Experience" />
                        <MenuItem value={REPORT_TYPES.CA} primaryText="Cohort Analysis" />
                    </SelectField>
                    <DatePicker
                        hintText="Start Date"
                        value={startDate}
                        onChange={(e, startDate) =>  this.setState({startDate})}
                    />
                    <DatePicker
                        hintText="End Date"
                        value={endDate}
                        onChange={(e, endDate) =>  this.setState({endDate})}
                    />
                </Dialog>
            </div>
        )

    }

}
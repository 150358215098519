import React from 'react';
import {CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {randomColorWithIndex} from "../utils";

class CustomToolTip extends React.Component{
    render(){
        const {
            active, units, names, payload, label, valueLabel
        } = this.props;
        if(active && payload && payload.length > 0) {
            return (
                <div className="recharts-default-tooltip" style={{fontWeight: 600, margin: "0px", padding: "10px", backgroundColor: 'rgba(255, 255, 255, 0.8)', border: "1px solid rgb(204, 204, 204)", whiteSpace: "nowrap"}}>
                    <p className="recharts-tooltip-label" style={{margin: "0px"}}>{ label }</p>
                    {/*<p className="recharts-tooltip-label" style={{margin: "0px"}}>{ payload.value }</p>*/}
                    <ul className="recharts-tooltip-item-list" style={{padding: "0px", margin: "0px"}}>
                        {
                            payload.sort((a, b) => b.value - a.value).map((item, index) =>
                                <li className="recharts-tooltip-item" key={`li${index}`} style={{display: "block", paddingTop: "4px", paddingBottom: "4px", color: item.color}}>
                                    <span className="recharts-tooltip-item-name">{item.name}</span>
                                    <span className="recharts-tooltip-item-separator"> : </span>
                                    <span className="recharts-tooltip-item-value">{item.value} ({item.payload[`${item.name}_users`]})</span>
                                    {/*<span className="recharts-tooltip-item-unit"> {item.unit}</span>*/}
                                </li>
                            )
                        }
                    </ul>
                </div>
            );
        }else{
            return <span/>
        }
    }
}

export default class LineGraph extends React.Component {

    render(){
        const { lines = [], data = [], dataKey, height = 350 } = this.props;
        return(
            <ResponsiveContainer height={height}>
                <LineChart data={data}
                           margin={{top: 20, right: 30, left: 20, bottom: 20}}>
                    <XAxis dataKey={dataKey} label={{ value: "Event Count", position: "insideBottomRight", dy: -28}} />
                    <YAxis label={{ value: "Percent", position: "insideLeft", angle: -90,   dy: 40}}/>
                    <CartesianGrid strokeDasharray="3 3"/>
                    <Tooltip content={<CustomToolTip {...this.props}/>}/>
                    {lines.map((line, i) =>
                        <Line strokeWidth={2} key={line} type="monotone" dataKey={line} stroke={randomColorWithIndex(i)} activeDot={{r: 8}}/>
                    )}
                </LineChart>
            </ResponsiveContainer>
        )
    }

}
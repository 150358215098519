/**
 * Created by jyothi on 22/8/17.
 */

export const login = 'login';
export const apps = 'apps';
export const reports = (appId) => `/${apps}/${appId}/reports`;
export const editor = (appId, reportId) => `/${apps}/${appId}/reports/${reportId}/edit`;

export const AUTH_ERROR_ENUM = { //re login require messages
    UNAUTHORISED: {
        query: 0,
        message: "Please login to continue..!"
    },
    MISSING_TOKEN: {
        query: 1,
        message: "Session expired. Please login to continue..!"
    },
    MISSING_HOST: {
        query: 2,
        message: "Something went wrong. Please login again..!"
    }
};
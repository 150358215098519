/**
 * Created by jyothi on 1/8/17.
 */
import React from 'react';
import ReactGA from 'react-ga';
import Paper from 'material-ui/Paper';
import {
    LineChart, Line, XAxis, YAxis, ZAxis,
    PieChart, Pie, Sector, Cell, ResponsiveContainer,
    Legend, Tooltip, ScatterChart, Scatter, CartesianGrid,
    BarChart, Bar, ReferenceLine, Brush
} from 'recharts';
import {
    white, lightWhite, darkWhite,
    greenA200, red500, blue300,
    blue200, blue500, orange500,
    blue900, blueA100, darkBlack,
    cyan500, deepOrange500, purple500,
    red900, red800, red700,
    green500, green900, green700
} from 'material-ui/styles/colors';
import {BottomNavigation, BottomNavigationItem} from 'material-ui/BottomNavigation';
import GroupAdd from 'material-ui/svg-icons/social/group-add';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import TextField from 'material-ui/TextField';
import moment from 'moment';
import {formatDataWithLimit, KEYS} from "../../../../../../../../utils";
import SortedTable from "./SortedTables";
import LineGraph from "../../../../../../../../components/LineGraph";
import {Panel} from "../Report/FTUEReport";

const COLORS = [ red500, green500, blue500, orange500, purple500, cyan500, greenA200, deepOrange500 ];

const getColorWithIndex = (index) => COLORS[ index % COLORS.length ];

const labelForStats = (label, count) => (
    <div>
        <span style={{fontSize: 20, color: darkBlack}}>{count}</span> <br/>
        <span>{label}</span>
    </div>
);

const iconStyles = {
    height: '40px'
};

const bottomNavigationStyles = {
    WebkitJustifyContent: 'space-around',
    justifyContent: 'space-around'
};

const colorsForStats = [green500, blue500, orange500, red500, purple500, cyan500];

export class Stats extends React.Component {

    render(){
        const data = this.props.data || [];
        return(
            <Paper style={paperStyles} zDepth={1}>
                <BottomNavigation style={{minHeight: '120px', ...bottomNavigationStyles}}>
                    {
                        data.map((item, i) =>
                            <BottomNavigationItem
                                key={item.key}
                                label={labelForStats(item.name, item.value)}
                                icon={<GroupAdd style={iconStyles} color={colorsForStats[i]} />}
                            />
                        )
                    }
                </BottomNavigation>
            </Paper>
        )
    }

}

const paperStyles = {
    width: '100%',
    minHeight: '100px'
};

const labelColor = "#000000";

const donutLabelStyles = {
    fontSize: '12px'
};

const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
        fill, payload, percent, value, isForUninstall, totalUsers } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
        <g style={donutLabelStyles}>
            <text style={{fontWeight: 700, fontSize: 16}} x={cx} y={cy} dy={-30} textAnchor="middle" fill={labelColor}>{payload.name}</text>
            <text style={{fontWeight: 700, fontSize: 16}} x={cx} y={cy} dy={0} textAnchor="middle" fill={fill}>{payload.newinstalls + ` (${(percent * 100).toFixed(2)}%)`}</text>
            { payload.day0uninstalls && <text
                style={{fontWeight: 600, fontSize: 12}}
                x={cx}
                y={cy}
                dy={30}
                textAnchor="middle"
                fill={isForUninstall ? red500 : green500}>
                {`${payload.day0uninstalls} (${(payload.day0uninstalls / (isForUninstall ? payload.newinstalls : totalUsers) * 100).toFixed(2)}%)`}
            </text>
            }
            {/*<text x={cx} y={cy} dy={30} textAnchor="middle" fill={labelColor}>{`(${(percent * 100).toFixed(2)}%)`}</text>*/}
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
            />
        </g>
    );
};

const getPercent = (total, value) => {
    if(isNaN(value) || isNaN(total)) return "NA";
    return parseFloat(value * 100 / total).toFixed(2);
};


class CustomToolTip extends React.Component{
    render(){
        const {active, units, names, payload, label, valueLabel} = this.props;
        return(
            <div className="recharts-default-tooltip" style={{margin: "0px", padding: "10px", backgroundColor: 'rgba(0, 0, 0, 0.1)', border: "1px solid rgb(204, 204, 204)", whiteSpace: "nowrap"}}>
                <p className="recharts-tooltip-label" style={{margin: "0px"}}>{ label }</p>
                <p className="recharts-tooltip-label" style={{margin: "0px"}}>{ `Users : ${payload.length > 0 && payload[0].payload.total_user_count}` }</p>
                <ul className="recharts-tooltip-item-list" style={{padding: "0px", margin: "0px"}}>
                    {
                        payload.map((item, index) => {
                            return(
                                <li className="recharts-tooltip-item" key={`li${index}`} style={{display: "block", paddingTop: "4px", paddingBottom: "4px", color: red500}}>
                                    <span className="recharts-tooltip-item-name">Uninstalls</span>
                                    <span className="recharts-tooltip-item-separator"> : </span>
                                    <span className="recharts-tooltip-item-value">{`${Math.ceil(item.value * payload[0].payload.total_user_count / 100)} (${item.value})`}</span>
                                    <span className="recharts-tooltip-item-unit"> %</span>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
        )
    }
}

const toTitleCase = (word = "") => word.trim().replace(/\b[a-z]/g, l => l.toUpperCase());

const labelFormatter = (label = "") => label.split("_").map(word => toTitleCase(word)).join(" ");

const renderPercent = (item = {}) => {
    const percentKey = item.name + "_percent";
    if(Object.keys(item.payload).some(o => o.includes(percentKey))){
        return <span>({item.payload[percentKey]} %)</span>
    }else{
        return <span/>
    }
};

const renderValueAfterPercent = (item = {}) => {
    const valueKey = item.name.replace("_percent", "");
    if(Object.keys(item.payload).some(o => o.includes(valueKey))){
        return <span>% ({item.payload[valueKey]})</span>
    }else{
        return <span/>
    }
};

class SuperCustomToolTip extends React.Component{
    render(){
        const {
            active, units, names, payload, label, valueLabel
        } = this.props;
        if(active && payload.length > 0) {
            return (
                <div className="recharts-default-tooltip" style={{margin: "0px", padding: "10px", backgroundColor: 'rgba(255, 255, 255, 0.5)', border: "1px solid rgb(204, 204, 204)", whiteSpace: "nowrap"}}>
                    <p className="recharts-tooltip-label" style={{margin: "0px"}}>{ label }</p>
                    {/*<p className="recharts-tooltip-label" style={{margin: "0px"}}>{ payload.value }</p>*/}
                    <ul className="recharts-tooltip-item-list" style={{padding: "0px", margin: "0px"}}>
                        {
                            payload.map((item, index) =>
                                <li className="recharts-tooltip-item" key={`li${index}`} style={{display: "block", paddingTop: "4px", paddingBottom: "4px", color: item.color}}>
                                    <span className="recharts-tooltip-item-name">{labelFormatter(item.name)}</span>
                                    <span className="recharts-tooltip-item-separator"> : </span>
                                    <span className="recharts-tooltip-item-value">{item.value} {renderValueAfterPercent(item)}</span>
                                    {/*<span className="recharts-tooltip-item-unit"> {item.unit}</span>*/}
                                </li>
                            )
                        }
                    </ul>
                </div>
            );
        }else{
            return <span/>
        }
    }
}

export class ScatterTrends extends React.Component { //FIXME: name not fixed

    render(){
        const {
            data = [], height = 250,
            xKey, yKey, zKey, scatterLabel,
            xLabel, yLabel, zLabel, withoutBrush = false,
            syncId = undefined, range = [50, 3000]
        } = this.props;
        return(
            <ResponsiveContainer height={height}>
                <ScatterChart syncId={syncId} margin={{top: 50, right: 20, bottom: 20, left: 20}} data={data}>
                    <XAxis dataKey={xKey} name={xLabel} label={xLabel ? { value: xLabel, position: "insideBottomRight", dy: -28} : undefined}/>
                    <YAxis dataKey={yKey} name={yLabel} label={yLabel ? { value: yLabel, position: "insideLeft", angle: -90,   dy: 40} : undefined}/>
                    <ZAxis dataKey={zKey} range={range} name={zLabel}/>
                    <CartesianGrid />
                    <ReferenceLine y={0} stroke='#000'/>
                    {
                        !withoutBrush && data.length > 9 &&
                        <Brush
                            dataKey={zKey}
                            height={30}
                            stroke="#429ef4"
                            endIndex={9}
                        />
                    }
                    <Tooltip label={""} cursor={{strokeDasharray: '3 3'}} content={<SuperCustomToolTip {...this.props}/>} />
                    <Legend/>
                    <Scatter name={scatterLabel} fill='#8884d8' shape="circle"/>
                </ScatterChart>
            </ResponsiveContainer>
        )
    }

}

export class MultipleScatterTrends extends React.Component { //FIXME: name not fixed

    render(){
        const {
            data = [], height = 400,
            xKey, yKey, zKey, scatterLabels = [],
            xLabel, yLabel, zLabel
        } = this.props;
        return(
            <ResponsiveContainer height={height}>
                <ScatterChart margin={{top: 20, right: 20, bottom: 20, left: 20}}>
                    <XAxis dataKey={xKey} name={xLabel} label={xLabel ? { value: xLabel, position: "insideBottomRight", dy: -28} : undefined}/>
                    <YAxis dataKey={yKey} name={yLabel} label={yLabel ? { value: yLabel, position: "insideLeft", angle: -90,   dy: 40} : undefined}/>
                    <ZAxis dataKey={zKey} range={[60, 3000]} name={zLabel}/>
                    <CartesianGrid />
                    <ReferenceLine y={0} stroke='#000'/>
                    {
                        data.length > 9 &&
                        <Brush
                            dataKey={zKey}
                            height={30}
                            stroke="#429ef4"
                            endIndex={9}
                        />
                    }
                    <Tooltip label={""} cursor={{strokeDasharray: '3 3'}} />
                    <Legend/>
                    {
                        data.map((dataItem, i) => <Scatter key={"scatter" + i} data={dataItem || []} name={scatterLabels[i]} fill={getColorWithIndex(i)} shape={ i === 1 ? "circle" : "triangle" }/>)
                    }
                </ScatterChart>
            </ResponsiveContainer>
        )
    }

}

const {
    event_count, event_done_users, retained_users, retained_percent,
    atleast_event_done_users, atleast_retained_users, atleast_retained_percent
} = KEYS;

const DATA_TYPES = {
    AT_LEAST: "AT_LEAST",
    DISCRETE: "DISCRETE"
};

export class RetentionReport extends React.Component{

    state = {
        event: "",
        attribute: "",
        dataType: DATA_TYPES.AT_LEAST,
        limit: 10,
        sortedList: [],
        scatterData: []
    };

    componentWillMount(){
        this.doStuff(this.props);
    }

    doStuff = (nextProps = this.props, limitChange = false) => {
        if(this.state.sortedList.length === 0 && nextProps.config.sorted && nextProps.config.sorted.length > 0){
            const sortedList = nextProps.config.sorted.map(o => ({...o, data: []}));
            this.setState({sortedList});
        }
        const gotPreprocess = nextProps.reports.preprocess_success || false;
        if(gotPreprocess){
            this.updateData(nextProps, false);
        }
    };

    updateData = (props = this.props, fromConfig = true) => {
        const { limit, dataType } = this.state;
        const nextList = props.reports.preprocess.all_users || [];
        const scatterData = formatDataWithLimit(dataType === DATA_TYPES.AT_LEAST ?
            nextList.slice(1) : nextList, limit, dataType === DATA_TYPES.AT_LEAST);
        this.setState({scatterData});
        const sortedList = fromConfig ? this.state.sortedList.map(o => ({...o, data: []})) : [...this.state.sortedList];
        const currentEventIndex = sortedList.findIndex(o => o.event === this.state.event);
        sortedList[currentEventIndex].data = scatterData;
        this.setState({sortedList});
    };

    componentWillReceiveProps(nextProps){
        this.doStuff(nextProps);
    }
    
    handleUpdate = () => {
        const { params: {appId}, config: { report_id, ...others }, getPreprocessed } = this.props;
        const { event, attribute} = this.state;
        if(event && event.length > 0){
            getPreprocessed(
                appId,
                {
                    ...others,
                    reportId: report_id,
                    event,
                    attribute: attribute && attribute.length > 0 ? attribute : undefined,
                }
            );
        }
    };

    render(){
        const {
            config: { name, startTime, endTime, preStart, preEnd, postStart, postEnd, postEvent, events, attributes, sorted = [] },
            reports: { preprocess: { all_users = [], ...others} }
        } = this.props;
        const { event, attribute, dataType, limit, sortedList = [], scatterData = [] } = this.state;
        const hasSorted = Array.isArray(sorted) && sorted.length > 0;
        return(
            <div>
                <Panel title="Insights Report">
                    <div style={{padding: '16px 8px', color: '#238', display: 'flex', justifyContent: 'space-around', borderBottom: '1px solid #CCC'}}>
                        <span><strong>{name}</strong></span>
                        <span>Users Installed Between: <strong>{moment(startTime).format("Do MMM YYYY")} - {moment(endTime).format("Do MMM YYYY")}</strong></span>
                        <span>Pre: <strong>{preStart} - {preEnd}</strong> days</span>
                        <span>Post: <strong>{postStart} - {postEnd}</strong> days</span>
                        <span>Post Event: <strong>{postEvent}</strong></span>
                    </div>
                    <div style={{margin: 8, display: 'flex', justifyContent: 'space-around'}}>
                        <SelectField
                            floatingLabelText="Event"
                            value={event}
                            fullWidth
                            onChange={(e, index, event) => this.setState({event}, this.handleUpdate)}
                            style={{maxWidth: "20%"}}
                        >
                            { hasSorted && sorted.map(({event}) => <MenuItem key={event} value={event} primaryText={event} />) }
                            { !hasSorted && events.map(event => <MenuItem key={event} value={event} primaryText={event} />) }
                        </SelectField>
                        {
                            !hasSorted && <SelectField
                                floatingLabelText="Attribute"
                                value={attribute}
                                fullWidth
                                onChange={(e, index, attribute) => this.setState({attribute}, this.handleUpdate)}
                                style={{maxWidth: "20%"}}
                            >
                                { attributes.map(attribute => <MenuItem key={attribute} value={attribute} primaryText={attribute} />) }
                            </SelectField>
                        }
                        {
                            hasSorted && event && sorted.findIndex(r => r.event === event) > -1 && <SelectField
                                floatingLabelText="Attribute"
                                value={attribute}
                                fullWidth
                                onChange={(e, index, attribute) => this.setState({attribute}, this.handleUpdate)}
                                style={{maxWidth: "20%"}}
                            >
                                { sorted[sorted.findIndex(r => r.event === event)].attributes.map(({attribute}) => <MenuItem key={attribute} value={attribute} primaryText={attribute} />) }
                            </SelectField>
                        }
                        <SelectField
                            floatingLabelText="Select Data Type"
                            value={dataType}
                            fullWidth
                            onChange={(e, index, dataType) => this.setState({dataType}, this.updateData)}
                            style={{maxWidth: "20%"}}
                        >
                            <MenuItem value={DATA_TYPES.AT_LEAST} primaryText="At Least" />
                            <MenuItem value={DATA_TYPES.DISCRETE} primaryText="Exact" />
                        </SelectField>
                        <TextField
                            value={limit}
                            onChange={e => {
                                this.setState({limit: Number(e.target.value)}, this.updateData);
                            }}
                            fullWidth
                            style={{maxWidth: "20%"}}
                            floatingLabelText="Limit to"
                        />
                    </div>
                    {
                        hasSorted && <SortedTable
                            postEvent={postEvent}
                            data={sortedList}
                            updateEvent={event => this.setState({event}, this.handleUpdate)}
                            updateAttribute={attribute => this.setState({attribute}, this.handleUpdate)}
                            isAtleast={dataType === DATA_TYPES.AT_LEAST}
                        />
                    }
                    {
                        all_users.length > 0 &&
                        <AttributesTrend
                            data={others}
                            dataType={dataType}
                            limit={limit}
                            postEvent={postEvent}
                            attribute={attribute}
                        />
                    }
                    {/*{
                        all_users.length > 0 &&
                        <Panel title={<span>Event with properties</span> }>
                            <ScatterTrends
                            data={scatterData}
                            xKey={event_count}
                            yKey={dataType === DATA_TYPES.AT_LEAST ? atleast_retained_percent: retained_percent }
                            zKey={dataType === DATA_TYPES.AT_LEAST ? atleast_event_done_users : event_done_users }
                            xLabel="Event Count"
                            yLabel={postEvent}
                            zLabel="Users"
                            scatterLabel="All Users"
                            syncId="preprocess"
                        />
                        </Panel>
                    }*/}
                </Panel>
            </div>
        )
    }

}

class AttributesTrend extends React.Component {
    render(){
        const { data = {}, dataType, limit, postEvent, attribute } = this.props;
        const datum = Object.keys(data).map(attr => ({
            attr,
            data: formatDataWithLimit(dataType === DATA_TYPES.AT_LEAST ?
                data[attr].slice(1) : data[attr], limit, dataType === DATA_TYPES.AT_LEAST)
        }));
        if(datum.length > 0){
            return(
                <div style={{width: '50%', margin: '0 auto'}}>
                    {/*<Panel title={<span>Retention of <strong>{postEvent}</strong> by Property with Users</span>}>
                        <div style={{height: 350, overflowY: 'auto'}}>
                            {
                                datum.map(({attr, data}) =>
                                    <ScatterTrends
                                        key={attr}
                                        data={data}
                                        xKey={event_count}
                                        yKey={dataType === DATA_TYPES.AT_LEAST ? atleast_retained_percent: retained_percent }
                                        zKey={dataType === DATA_TYPES.AT_LEAST ? atleast_event_done_users : event_done_users }
                                        xLabel="Event Count"
                                        yLabel={postEvent}
                                        zLabel="Users"
                                        scatterLabel={`${attribute} (${attr}) Users`}
                                        syncId="preprocess"
                                        withoutBrush
                                    />
                                )
                            }
                        </div>
                    </Panel>*/}
                    <Panel title={<span>Impact of Event with Properties - Comparison Graph</span>}>
                        <LineGraph height={450} {...formatAttributeTrends(datum, dataType === DATA_TYPES.AT_LEAST)} dataKey="event_count" />
                    </Panel>
                </div>
            )
        }else {
            return <span/>
        }
    }
}

function formatAttributeTrends(srcData = [], isAtleast = false) {
    const size = Array.isArray(srcData) && srcData.length > 0 ? srcData[0].data.length : 0;
    const attributes = srcData.map(o => o.attr);
    let datum = [];
    if(size > 0){
        datum = srcData[0].data.map(o => ({event_count: '' + o.event_count}));
        srcData.forEach(d => {
            const { attr, data = [] } = d;
            data.forEach(({atleast_retained_percent, retained_percent, event_done_users, atleast_event_done_users}, i) => {
                const percent = isAtleast ? atleast_retained_percent : retained_percent;
                datum[i][attr] = isNaN(percent) ? 0 : percent;
                const users = isAtleast ? atleast_event_done_users : event_done_users;
                datum[i][attr + '_users'] = isNaN(users) ? 0 : users;
            });
        })
    }
    return {lines: attributes, data: datum};
}
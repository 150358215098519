/**
 * Created by jyothi on 22/8/17.
 */
import React, {Component} from 'react';
import {Link} from 'react-router';
import TextField from 'material-ui/TextField';
import Paper from 'material-ui/Paper';
import RaisedButton from 'material-ui/RaisedButton';


export class LoginForm  extends Component{

    constructor(props){
        super(props);
        this.state = {
            collapse: "collapse",
            disable: false,
            userName: ""
        }
    }

    handleClick(){
        this.setState({collapse: 'collapse in'});
    }

    componentDidMount(){
        if (this.props.location.query.user){
            const data = {
                username: this.props.location.query.user,
                password: ''
            };
            this.props.loadData(data);
            //this.setState({disable: true}); disable facility
        }
    }

    render(){

        const {login, session, checkUser } = this.props;
        const { userName, password} = this.state;
        const { login_pending, login_failed, host, host_pending, host_failed } = session;
        const isHostRecognised = !!host;
        return (
            <Paper zDepth={1} style={{padding: 40}}>
                <h4>Login</h4>
                <form name="login" onSubmit={(e) => {
                    e.preventDefault();
                    //document.activeElement.blur();
                    //isHostRecognised ? login(userName, password) : checkUser(userName); //form submit based on host recognition
                }}>
                    <TextField
                        id="email"
                        value={userName}
                        fullWidth
                        disabled={isHostRecognised}
                        onChange={(e, userName) => this.setState({userName})}
                    />
                    { isHostRecognised &&
                    <TextField
                        id="password"
                        value={password}
                        fullWidth
                        type="password"
                        onChange={(e, password) => this.setState({password})}
                    />
                    }
                    <div style={{textAlign: 'right'}}>
                    {
                        !isHostRecognised && <RaisedButton label="Next" primary={true} onClick={(e) => {
                            e.preventDefault();
                            document.activeElement.blur();
                            checkUser(userName);
                        }} />
                    }
                    {
                        isHostRecognised && <RaisedButton label="Submit" primary={true} onClick={(e) => {
                            e.preventDefault();
                            document.activeElement.blur();
                            login(userName, password);
                        }}/>
                    }
                    </div>
                </form>
            </Paper>
        )
    }
}
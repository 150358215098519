/**
 * Created by jyothi on 1/8/17.
 */
import { callApi } from '../../../../../../../../api';
import { makeDefaultQueryString} from '../../../../../../../../utils';

const REPORTS_API = "reports";

const makeReportAPI = (reportId) => `${REPORTS_API}/${reportId}`;

/**
 *
 * @param auth
 * @param appId
 * @param reportId
 * @returns {Promise}
 */
export function getReportAPI(auth, appId, reportId) {
    const url = makeDefaultQueryString(makeReportAPI(reportId), auth, appId);
    const config = {
        method: "GET",
        auth: auth
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

/**
 *
 * @param auth
 * @param appId
 * @param reportId
 * @param reportData
 * @returns {Promise}
 */
export function updateReportAPI(auth, appId, reportId, reportData) {
    const url = makeDefaultQueryString(makeReportAPI(reportId), auth, appId);
    const config = {
        method: "PUT",
        auth: auth,
        body: reportData
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}
/**
 * Created by jyothi on 1/8/17.
 */
import React, { Component }  from 'react';
import { connect } from 'react-redux';
import { ReportList, NewReport } from './components';
import { addReport, getReports, getPreprocessList, deletePreprocessReport } from './actions';
import PropTypes from 'prop-types';

function mapStateToProps(state) {
    return {
        session: state.auth,
        router: state.routing,
        reports: state.reports
    };
}

function mapDispatchToProps(dispatch) {
    return {
        loadData: (appId) => {
            dispatch(getReports(appId));
        },
        createReport: (appId, startDate, endDate, reportType) => dispatch(addReport(appId, startDate, endDate, reportType)),
        getPreprocessList: (appId) => dispatch(getPreprocessList(appId)),
        deletePreprocessReport: (appId, reportId) => dispatch(deletePreprocessReport(appId, reportId))
    };
}

class Reports extends Component {

    constructor (props) {
        super(props);
        this.appId = this.props.params.appId;
        this.state = {

        }
    }

    componentWillMount(){
        this.props.loadData(this.appId);
        this.props.getPreprocessList(this.appId);
    }

    componentWillUnmount(){
        //this.props.resetData();
    }

    handleCreate = (startDate, endDate, reportType) => {
        this.props.createReport(this.appId, startDate, endDate, reportType);
    };

    render() {
        const { appState, params, reports, filters} = this.props;
        const { user: { email = "" } } = this.props.session;
        const hasWriteAccess = email.split("@")[1].indexOf("apxor") > -1;
        return (
            <section className="content">
                <ReportList {...this.props} {...this.context}/>
                {
                    hasWriteAccess && <NewReport handleCreate={this.handleCreate}/>
                }
            </section>
        );
    }
}

Reports.propTypes = {

};

Reports.contextTypes = {
    router: PropTypes.object.isRequired,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Reports);
/**
 * Created by jyothi on 22/8/17.
 */
import {
    APPS_PENDING, APPS, APPS_FAILED,
    RESET_APPS
} from './actionTypes';
import { RESET_ALL } from '../../../../../constants/actionTypes';

const INITIAL_STATE = {
    apps: [],
    ...APPS_INITIAL_FLAG_STATE
};

const APPS_INITIAL_FLAG_STATE = {
    apps_pending: false,
    apps_failed: false
};

const appsReducer = (state = INITIAL_STATE, action) => {

    switch(action.type) {

        case APPS_PENDING:
            return {
                ...state,
                ...APPS_INITIAL_FLAG_STATE,
                apps_pending: true
            };

        case APPS:
            return {
                ...state,
                ...APPS_INITIAL_FLAG_STATE,
                apps: action.payload
            };

        case APPS_FAILED:
            return {
                ...state,
                ...APPS_INITIAL_FLAG_STATE,
                apps_failed: true
            };

        case RESET_APPS:
        case RESET_ALL:
            return INITIAL_STATE;

        default:
            return state;

    }

};

export default appsReducer;
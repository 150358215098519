/**
 * Created by jyothi on 24/8/17.
 */
import React from 'react';
import ReactGA from 'react-ga';
import ReactCohortGraph from 'react-cohort-graph';
import {Toolbar, ToolbarGroup, ToolbarSeparator, ToolbarTitle} from 'material-ui/Toolbar';
import Paper from 'material-ui/Paper';
import {
    LineChart, Line, XAxis, YAxis, ZAxis,
    PieChart, Pie, Sector, Cell, ResponsiveContainer,
    Legend, Tooltip, ScatterChart, Scatter, CartesianGrid
} from 'recharts';
import {Card, CardActions, CardHeader, CardMedia, CardTitle, CardText} from 'material-ui/Card';
import {
    white, lightWhite, darkWhite,
    greenA200, red500, blue300,
    blue200, blue500, orange500,
    blue900, blueA100, darkBlack,
    cyan500, deepOrange500, purple500,
    red900, red800, red700,
    green500, green900, green700
} from 'material-ui/styles/colors';
import {BottomNavigation, BottomNavigationItem} from 'material-ui/BottomNavigation';
import Group from 'material-ui/svg-icons/social/group';
import GroupAdd from 'material-ui/svg-icons/social/group-add';
import AccessTime from 'material-ui/svg-icons/device/access-time';
import SentimentSatisfied from 'material-ui/svg-icons/social/sentiment-satisfied';
import { headerStyles, subHeaderStyles, toolbarTitleStyles } from '../actionTypes';

export const Header = (props) => (
    <Toolbar style={headerStyles} >
        <ToolbarGroup>
            <ToolbarTitle style={{...toolbarTitleStyles}} text={props.app} />
        </ToolbarGroup>
        <ToolbarGroup>
            <ToolbarTitle style={{...toolbarTitleStyles, textTransform: 'uppercase', fontSize: '16px'}} text={props.title} />
        </ToolbarGroup>
        <ToolbarGroup>
            <ToolbarTitle style={{...toolbarTitleStyles}} text={props.dates} />
        </ToolbarGroup>
    </Toolbar>
);

const labelForStats = (label, count) => (
    <div>
        <span style={{fontSize: 20, color: darkBlack}}>{count}</span> <br/>
        <span>{label}</span>
    </div>
);

const iconStyles = {
    height: '40px'
};

const bottomNavigationStyles = {
    WebkitJustifyContent: 'space-around',
    justifyContent: 'space-around'
};

export class Stats extends React.Component {

    render(){
        const { total_users, new_installs = 0, uninstalls = 0, re_installs, sessions = 0, total_sessions } = this.props.data || {};
        return(
            <Paper style={paperStyles} zDepth={1}>
                <BottomNavigation style={{minHeight: '120px', ...bottomNavigationStyles}}>
                    <BottomNavigationItem
                        label={labelForStats("Total Users", total_users)}
                        icon={<Group style={iconStyles} color={blue500} />}
                    />
                    <BottomNavigationItem
                        label={labelForStats("New Installs", new_installs)}
                        icon={<GroupAdd style={iconStyles} color={green500} />}
                    />
                    <BottomNavigationItem
                        label={labelForStats("Upgraded Users", uninstalls)}
                        icon={<SentimentSatisfied style={iconStyles} color={green700} />}
                    />
                    <BottomNavigationItem
                        label={labelForStats("Reinstalls", re_installs)}
                        icon={<SentimentSatisfied style={iconStyles} color={green900}/>}
                    />
                    <BottomNavigationItem
                        label={labelForStats("Total Sessions", total_sessions)}
                        icon={<AccessTime style={iconStyles} color={blue500} />}
                    />
                </BottomNavigation>
            </Paper>
        )
    }

}

const paperStyles = {
    width: '100%',
    minHeight: '100px'
};

const COLORS = [orange500, greenA200, blue500, purple500, cyan500];

const labelColor = "#000000";

const donutLabelStyles = {
    fontSize: '12px'
};

const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
        fill, payload, percent, value } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
        <g style={donutLabelStyles}>
            <text style={{fontWeight: 700, fontSize: 16}} x={cx} y={cy} dy={-10} textAnchor="middle" fill={labelColor}>{payload.name}</text>
            <text style={{fontWeight: 700, fontSize: 16}} x={cx} y={cy} dy={10} textAnchor="middle" fill={fill}>{payload.newinstalls + ` (${(percent * 100).toFixed(2)}%)`}</text>
            {/*<text style={{fontWeight: 600, fontSize: 12}} x={cx} y={cy} dy={30} textAnchor="middle" fill={red500}>{`${payload.day0uninstalls} (${(payload.day0uninstalls / payload.newinstalls * 100).toFixed(2)}%)`}</text>*/}
            {/*<text x={cx} y={cy} dy={30} textAnchor="middle" fill={labelColor}>{`(${(percent * 100).toFixed(2)}%)`}</text>*/}
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
            />
        </g>
    );
};

export default class Donut extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            activeIndex: 0
        }
    }

    onPieEnter = (data, index) => {
        console.log(data, index);
        this.setState({activeIndex: index});
        ReactGA.event({
            category: 'Pie Chart View',
            action: 'Hovered on ',
            label: data.name
        });
    };

    render () {
        const { height, data, subData, title, nameKey } = this.props;
        return (
            <ResponsiveContainer height={height}>
                <PieChart>
                    <Pie
                        nameKey={nameKey || "name"}
                        activeIndex={this.state.activeIndex}
                        activeShape={renderActiveShape}
                        data={ data }
                        dataKey={"newinstalls"}
                        innerRadius={60}
                        outerRadius={75}
                        onMouseMove={this.onPieEnter}
                        fill="#8884d8">
                        {
                            data.map((entry, index) => <Cell fill={COLORS[index % COLORS.length]}/>)
                        }
                    </Pie>
               </PieChart>
            </ResponsiveContainer>
        );
    }
}

export class Distributions extends React.Component {

    render(){
        const data = this.props.data || [];
        return(
            <Paper style={paperStyles} zDepth={1}>
                <BottomNavigation style={{minHeight: '300px', ...bottomNavigationStyles}}>
                    {
                        data.map(item =>
                            <BottomNavigationItem
                                label={<span style={{fontSize: 14}}>{item.name}</span>}
                                icon={<Donut data={item.data} height={200} />}
                                style={{minHeight:'200px', minWidth: '200px'}}
                            />
                        )
                    }
                </BottomNavigation>
            </Paper>
        )
    }

}

export class Cohorts extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            data: props.data
        };
    }

    render(){
        const data = this.props.data || [];
        return(
            <Paper style={paperStyles} zDepth={1}>
                {
                    data.map((item, i) =>
                        <Card key={"cohort" + i} expandable={true}>
                            <CardHeader
                                title={
                                    <BottomNavigation style={{maxHeight: '40px', ...bottomNavigationStyles}}>
                                        <BottomNavigationItem
                                            label={<span style={{color: blue900, fontSize: 14, fontWeight: 500}}>{ item.cohort }</span>}
                                            icon={<span/>}
                                        />
                                        <BottomNavigationItem
                                            label={<span style={{color: blue900, fontSize: 14, fontWeight: 500}}>{item.event || "Any"}</span>}
                                            icon={<span/>}
                                        />
                                        <BottomNavigationItem
                                            label={<span style={{color: blue900, fontSize: 14, fontWeight: 500}}>{item.attribute || "Any"}</span>}
                                            icon={<span/>}
                                        />
                                    </BottomNavigation>
                                }
                                /*avatar={<LightBulb color={greenA200}/>}*/
                                titleStyle={{fontSize: 18}}
                                textStyle={{width: '100%'}}
                                actAsExpander={true}
                                showExpandableButton={true}
                            />
                            <CardText expandable={true}>
                                <ReactCohortGraph data={item.data} />
                            </CardText>
                        </Card>
                    )
                }
            </Paper>
        )
    }

}

const attributionStyles = {
    height: '40px',
    marginTop: '-40px',
    backgroundColor: blue200,
    color: white,
    display: 'block',
    lineHeight: '40px',
    textAlign: 'right'
};

export class ApxorAttribution extends React.Component {
    render(){
        return(
            <Paper zDepth={1}>
                <BottomNavigation style={attributionStyles}>
                    <BottomNavigationItem
                        label={<span style={{color: white}}>Cohort Analysis Report powered by <a href="https://www.apxor.com">Apxor</a></span>}
                        icon={<span />}
                        style={{minWidth: 400}}
                    />
                </BottomNavigation>
            </Paper>
        );
    }
}
/**
 * Created by jyothi on 1/8/17.
 */
import React, { Component }  from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FTUEReport from './FTUEReport';
import CohortReport from './CohortReport';
import { getReport } from './actions';
import { Link } from 'react-router';
import { REPORT_TYPES } from '../../Reports/actionTypes';
import FloatingActionButton from 'material-ui/FloatingActionButton';
import EditIcon from 'material-ui/svg-icons/editor/mode-edit';

function mapStateToProps(state) {
    return {
        session: state.auth,
        router: state.routing,
        report: state.report
    };
}

function mapDispatchToProps(dispatch) {
    return {
        loadData: (appId, reportId) => {
            dispatch(getReport(appId, reportId));
        }
    };
}

class Report extends React.Component {

    constructor(props){

        super(props);
        this.state = {
            data: {},
        };

        this.id = props.params.reportId;

    }

    componentWillMount(){
        const { appId, reportId } = this.props.params;
        this.props.loadData(appId, reportId);
    }

    handleMetricsData = (data, key = "metrics") => {
        const { stats = {} } = data;
        const metrics  = data[key] || [];
        const d0UninstallPercent = stats.d0_uninstalls / stats.new_installs * 100;
        const sortedMetrics = metrics.sort((a, b) => {
            const aD0UninstallPercent = a.inactiveTotal / a.total * 100;
            const bD0UninstallPercent = b.inactiveTotal / b.total * 100;
            return aD0UninstallPercent - bD0UninstallPercent;
        });

        return sortedMetrics;

    };

    render(){
        const { appId, reportId } = this.props.params;
        let { report_pending, report_failed, report={data: {}} } = this.props.report || {};
        const { user: { email = "" } } = this.props.session;
        const hasWriteAccess = email.split("@")[1].indexOf("apxor") > -1;
        return(
            <div>
                {
                    report && report.report_type === REPORT_TYPES.CA && <CohortReport {...this.props}/>
                }
                {
                    report && report.report_type !== REPORT_TYPES.CA && <FTUEReport {...this.props} {...this.context}/>
                }
                {
                    hasWriteAccess &&
                    <div style={{
                        right: 10,
                        bottom: 10,
                        position: 'fixed',
                        zIndex: 200
                    }}>
                        <Link to={`/apps/${appId}/reports/${reportId}/edit`}>
                            <FloatingActionButton onClick={this.saveData}>
                                <EditIcon/>
                            </FloatingActionButton>
                        </Link>
                    </div>
                }
            </div>
        );
    }

}

Report.propTypes = {

};

Report.contextTypes = {
    router: PropTypes.object.isRequired,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Report);
/**
 * Created by jyothi on 22/8/17.
 */
import React from 'react'
import {Route, IndexRedirect} from 'react-router';
import App from '../containers/Root/App';
import Auth from '../containers/Root/App/Auth';
import RequireAuth from '../containers/Root/App/RequireAuth';
import Apps from '../containers/Root/App/RequireAuth/Apps';
import Reports from '../containers/Root/App/RequireAuth/Apps/App/Reports';
import Report from '../containers/Root/App/RequireAuth/Apps/App/Reports/Report';
import Editor from '../containers/Root/App/RequireAuth/Apps/App/Reports/Report/Editor';
import Preprocessed from '../containers/Root/App/RequireAuth/Apps/App/Reports/Preprocessed';
import Logout from '../containers/Root/App/Auth/logout'

import {
    login, apps
} from '../constants/endPoints';

export default <Route path="/" component={App}>

    <IndexRedirect to="login" />

    <Route path={login} component={Auth} />
    <Route path="logout" component={Logout}/>

    <Route component={RequireAuth}>
        <Route path={apps} component={Apps} />

        <Route path={'apps/:appId/reports'} component={Reports} />

        <Route path={'apps/:appId/reports/:reportId'} component={Report} />

        <Route path={'apps/:appId/reports/:reportId/edit'} component={Editor} />

        <Route path={'apps/:appId/preprocessed/:reportId'} component={Preprocessed} />

    </Route>

    <Route path='*' component={App} />

</Route>;
/**
 * Created by jyothi on 24/8/17.
 */
import React from 'react';
import Divider from 'material-ui/Divider';
import {Header, Distributions, Stats, Cohorts, ApxorAttribution} from './components';
import { headerStyles, subHeaderStyles } from '../actionTypes';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import RaisedButton from 'material-ui/RaisedButton';
import TextField from 'material-ui/TextField';
import {Card, CardActions, CardHeader, CardMedia, CardTitle, CardText} from 'material-ui/Card';
import { lightWhite } from 'material-ui/styles/colors';

const ReportStyles = {
    width: '100%',
    maxWidth: '1200px',
    margin: '0 auto',
    textAlign: 'left',
    height: '100%'
};

const LineDivider = (props) => <Divider style={{color: lightWhite, height: 2}}/>;

const Panel = (props) => (
    <Card expandable={true} initiallyExpanded>
        <CardHeader
            title={props.title}
            actAsExpander={true}
            titleStyle={subHeaderStyles}
            showExpandableButton={true}
            style={subHeaderStyles}
        />
        <CardText expandable={true}>
            {props.children}
        </CardText>
    </Card>
);

export default class Report extends React.Component {

    constructor(props){

        super(props);
        this.state = {
            data: {},
        };

        this.id = props.params.reportId;

    }

    componentWillMount(){
        const { appId, reportId } = this.props.params;
        this.props.loadData(appId, reportId);
    }

    handleMetricsData = (data, key = "metrics") => {
        const { stats = {} } = data;
        const metrics  = data[key] || [];
        const d0UninstallPercent = stats.d0_uninstalls / stats.new_installs * 100;
        const sortedMetrics = metrics.sort((a, b) => {
            const aD0UninstallPercent = a.inactiveTotal / a.total * 100;
            const bD0UninstallPercent = b.inactiveTotal / b.total * 100;
            return aD0UninstallPercent - bD0UninstallPercent;
        });

        return sortedMetrics;

    };


    render(){

        const { data: {header, stats, distributions, cohorts}, pinError } = this.state;

        return(
            <section className="content" style={ReportStyles}>
                <div style={{paddingBottom: '40px', minHeight:'calc(100vh - 40px)'}}>
                    <Header {...header}/>
                    <Stats data={stats}/>
                    {
                        distributions &&
                        <Panel title={distributions.name || "Distributions"}>
                            <Distributions {...distributions}/>
                        </Panel>
                    }
                    { distributions && <LineDivider /> }
                    {
                        cohorts &&
                        <Panel title={cohorts.name || "Cohort Analysis"}>
                            <Cohorts {...cohorts}/>
                        </Panel>
                    }
                </div>
                <ApxorAttribution />
            </section>
        )

    }

}
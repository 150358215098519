/**
 * Created by jyothi on 1/8/17.
 */

export const REPORTS_PENDING = "REPORTS_PENDING";
export const REPORTS_SUCCESS = "REPORTS_SUCCESS";
export const REPORTS_FAILED = "REPORTS_FAILED";

export const ADD_REPORT_PENDING = "ADD_REPORT_PENDING";
export const ADD_REPORT_SUCCESS = "ADD_REPORT_SUCCESS";
export const ADD_REPORT_FAILED = "ADD_REPORT_FAILED";

export const REPORT_TYPES = {
    FTUE: "FTUE",
    CA: "CA"
};

export const PREPROCESS_LIST_PENDING = "PREPROCESS_LIST_PENDING";
export const PREPROCESS_LIST = "PREPROCESS_LIST";
export const PREPROCESS_LIST_FAILED = "PREPROCESS_LIST_FAILED";

export const PREPROCESS_PENDING = "PREPROCESS_PENDING";
export const PREPROCESS = "PREPROCESS";
export const PREPROCESS_FAILED = "PREPROCESS_FAILED";

export const PREPROCESS_REPORT_DELETE_PENDING = "PREPROCESS_REPORT_DELETE_PENDING";
export const PREPROCESS_REPORT_DELETE = "PREPROCESS_REPORT_DELETE";
export const PREPROCESS_REPORT_DELETE_FAILED = "PREPROCESS_REPORT_DELETE_FAILED";

export const UPDATE_PREPROCESS_CONFIG = "UPDATE_PREPROCESS_CONFIG";
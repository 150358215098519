/**
 * Created by jyothi on 1/8/17.
 */
import {
    getReportAPI,
    updateReportAPI
} from './api';
import {
    REPORT_FAILED, REPORT_SUCCESS, REPORT_PENDING,
    EDIT_REPORT_PENDING, EDIT_REPORT_SUCCESS, EDIT_REPORT_FAILED
} from './actionTypes';

export const getReport = (appId, reportId) => {
    return (dispatch, getState) => {
        let filters = {...getState().filters};
        return dispatch({
            types: [
                REPORT_PENDING,
                REPORT_SUCCESS,
                REPORT_FAILED
            ],
            payload: {
                promise: getReportAPI(getState().auth, appId, reportId)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //If Any
            }
        });
    };
};

export const updateReport = (appId, reportId, reportData) => {
    return (dispatch, getState) => {
        let filters = {...getState().filters};
        return dispatch({
            types: [
                EDIT_REPORT_PENDING,
                EDIT_REPORT_SUCCESS,
                EDIT_REPORT_FAILED
            ],
            payload: {
                promise: updateReportAPI(getState().auth, appId, reportId, reportData)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //If Any
            },
            callbacks: {
                successDidDispatch: (dispatch) => {
                    dispatch({
                        type: REPORT_SUCCESS,
                        payload: JSON.parse(reportData)
                    });
                }
            }
        });
    };
};
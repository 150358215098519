/**
 * Created by Araja Jyothi Babu on 13-Oct-16.
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loginUser, forgotPassword, resetAuthReducer, checkUserExistence, logoutUser } from './actions';
import { LoginForm } from './components';

function mapStateToProps(state) {
    return {
        session: state.auth
    };
}

function mapDispatchToProps(dispatch) {
    return {
        login: (user, password) => dispatch(loginUser(user, password)),
        resetAuth: () => dispatch(resetAuthReducer()),
        logout: () => dispatch(logoutUser()),
        checkUser: (user) => dispatch(checkUserExistence(user))
    };
}

const customAlertStyle = {
    backgroundColor: "#EE4422",
    width:"100%",
    border:"1px solid #d6e9c6",
    color:"#FFF",
    padding: "10px"
};

class Auth extends Component {
    static propTypes = {
        children: PropTypes.object,
        location: PropTypes.object,
    };

    componentWillMount(){
        const {session: { token, host } } = this.props;
        if (token) {
            try {
                const redirect = this.props.location.query.redirect;
                this.context.router.replace(redirect);
            } catch (err) {
                this.context.router.replace('/apps');
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        const {token, host} = nextProps.session;
        if (token) {
            try {
                const redirect = this.props.location.query.redirect;
                this.context.router.replace(redirect);
            } catch (err) {
                this.context.router.replace('/apps');
            }
        }
    }

    componentWillUnmount(){
        this.props.resetAuth();
    }

    render(){
        return (
            <section className="content">
                <div style={{maxWidth: 360, margin: '0 auto'}}>
                    <LoginForm {...this.props}/>
                </div>
            </section>
        );
    }
}

Auth.contextTypes = {
    router: PropTypes.object.isRequired,
    store: PropTypes.object.isRequired,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Auth);
/**
 * Created by jyothi on 22/8/17.
 */
import React, {Component} from 'react';
import { Link } from 'react-router';
import { reports } from '../../../../../constants/endPoints';
import {Card, CardActions, CardHeader, CardMedia, CardTitle, CardText} from 'material-ui/Card';
import RefreshIndicator from 'material-ui/RefreshIndicator';
import Paper from 'material-ui/Paper';

const styles = {
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
    },
    gridList: {
        width: '100%',
        height: 'auto'
    },
};

export class AppsList extends Component {

    render() {
        const {
            apps = [], apps_pending, apps_failed
        } = this.props;

        return (
            <div style={{padding: '0 10%'}}>
                {
                    apps_pending && <RefreshIndicator
                        size={200}
                        left={600}
                        top={200}
                        status="loading"
                    />
                }
                <Paper zDepth={1}>
                    {
                        apps.map(app =>
                            <Link key={app.app_id} to={reports(app.app_id)}>
                                <Card>
                                    <CardHeader
                                        title={app.basic_info.app_name}
                                        subtitle={app.basic_info.bundle_id}
                                        avatar={<img src={app.basic_info.icon_url} width={27} height={27} />}
                                    />
                                </Card>
                            </Link>
                        )
                    }
                </Paper>
                {
                    apps_failed &&
                    <h1 align="center">Apps failed to load.</h1>
                }
            </div>
        )
    }
}

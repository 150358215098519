/**
 * Created by jyothi on 22/8/17.
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import { AppsList } from './components';
import { loadApps, resetApps } from './actions';

function mapStateToProps(state) {
    return {
        router: state.routing,
        apps: state.apps
    };
}

function mapDispatchToProps(dispatch) {
    return {
        loadApps: () => dispatch(loadApps()),
        resetApps: () => dispatch(resetApps())
    };
}

class Apps extends Component{

    componentWillMount(){
        /**
         * Loads images before mount
         */
        this.props.loadApps();
    }

    componentWillReceiveProps(nextProps){

    }

    componentDidMount(){

    }

    componentWillUnmount(){
        this.props.resetApps();
    }

    render() {
        const { apps } = this.props;

        return (
            <section className="content">
                <AppsList {...apps}/>
            </section>
        )
    }
}

Apps.contextTypes = {
    router: PropTypes.object.isRequired,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Apps);
/**
 * Created by jyothi on 22/8/17.
 */
import { API_ROOT } from '../config';
import { isDefined } from '../utils';
import logger from '../utils/logger';
import {API_BASE} from '../config';
import axios from 'axios';

export const HEADERS = { 'Accept': 'application/json', 'Content-Type': 'application/json' };

const defaultConfig = {
    headers: HEADERS
    //TODO: if any
};

const makeConfig = (config) => {
    const {host, token} = config.auth || {};
    return {
        headers: {
            ...defaultConfig.headers,
            "X-Apx-Host": host,
            "X-Apx-Host": "google",
            "X-Auth-Token": token, //TODO: make use of this,
            "Access-Control-Allow-Credentials": true,
            ...config.headers,
        },
        data: config.body || {},
        method: config.method,
        withCredentials: true,
    };
};

/**
 *
 * @param resolve
 * @param callback
 * @param result
 * @returns {*}
 */
const handleSuccess = (resolve, callback, result) => {
    if(isDefined(callback) && typeof callback === 'function') {
        return resolve(callback(result));
    }else{
        return resolve(result);
    }
};

/**
 *
 * @param reject
 * @param callback
 * @param error
 * @returns {*}
 */
const handleFailure = (reject, callback, error) => {
    if(isDefined(callback) && typeof callback === 'function') {
        return reject(callback({type: "Error", payload: error}));
    }else{
        return reject(null);
    }
};

const axiosInstance = axios.create({
    baseURL: API_BASE,
    /*headers: {
        ...defaultConfig.headers,
        "X-Apx-Host": host, //|| SERVER_IDENTIFIERS.google, //selects server api
        "X-Auth-Token": token //TODO: make use of this
    }*/
});

axiosInstance.interceptors.response.use(
    response => {
        return response
    },
    error => {
        logger.error("Error:::: ", error, error.response.data);
        return Promise.reject(error.response.data);
    }
);


export function getServerAPI(url, customRoot) {
    const appIdREGEX = /appId=(.*?)($|&)/;
    let matches = appIdREGEX.exec(url);
    let fullURL = customRoot && customRoot !== "" ? customRoot + url : API_ROOT + url;
    return fullURL;
}

export const callApi = (url, config, onSuccess, onFailure, customRoot) => {
    const fullURL = getServerAPI(url, customRoot);
    return new Promise((resolve, reject) => {
        let options = makeConfig(config);
        logger.info("Came to make Ajax request with options for URL: ", fullURL, " are : =>", options);
        axiosInstance.request({
            url: fullURL,
            ...options
        }).then(({data, status, statusText}) => {
            logger.info(statusText, "Response for URL: " + fullURL, data);
            handleSuccess(resolve, onSuccess, data);
        }).catch(error => {
            logger.error("Error Response: ", error, "for URL: => " + fullURL, " with Request Options: => ", options);
            handleFailure(reject, onFailure, error);
        }).then(() => { //Always
            //If Any
        });
    });
};

export const oldCallApi = (url, config, onSuccess, onFailure) => {
    return new Promise((resolve, reject) => {
        const fullUrl = API_ROOT + url;
        const options = makeConfig(config);
        logger.info("Came to make Ajax request with options for URL: ", url, " are : =>", options);
        fetch(fullUrl, options).then(response => {
            logger.info("Response for URL: " + fullUrl, response, response.body);
            if (response.status == 204){
                handleSuccess(resolve, onSuccess, []);
                return;
            }
            response.json().then(json => {
                logger.log("json", json);
                if (!response.ok) {
                    logger.warn("Warning:::: ", json, "for URL: => " + fullUrl, " with Request Options: => ", options);
                    handleFailure(reject, onFailure, response);
                }
                handleSuccess(resolve, onSuccess, json);
            })
        }).catch(err => {
            logger.error("Error:::: ", err, "for URL: => " + fullUrl, " with Request Options: => ", options);
            handleFailure(reject, onFailure, err);
        });
    });
};